/*jshint esversion: 11*/
/* Helpers for the new/contact-service dashboard with BtChart component.
 * HISTORY: no-version
 * V240909.1: Added _mergeEmbeddedFilter() to merge offers filters with a given filter for embedded dashboards and called it from within getDataCount(), getData() and getDropdownData() +
 *    Changed token's parsing method.
 * V240802.1: Modified getImportNames() to accept completedOnly param to query completed imports only.
 * V240719.1: Added missing _mergeJSONObjects() called from getExportData().
 * V240718.1: Added getExportFields(), getLastExport() and getExportData().
 * V240712.1: Modified _addDistinctToFilter() to handle Yearly-Monthly and Monthly-Daily cases (i.e., year/month or month/dayOfMonth).
 * V240424.1: Modified _addDistinctToFilter() to handle event_date cases (i.e., dayOfMonth).
 * V240422.1: Modified _addImportIdToFilter() to inject $limit instead of importId.
 * V240311.1: Added limit param to getImportNames().
 * V240221.2: Modified getFieldValues() by adding 'header' to the filter and removing it from select param in getImports().
 * V240221.1: Modified getFieldValues() to only get the required import fields rather all of them.
 * V240201.1: Added getImportData() and moved getImportHeaders's codes in there to return more data from the last import.
 * V240103.1: Removed assigned values for error to have both true/false values.
 * V231220.2: Modified getFieldValues() to append availableRecords to the import names and filter records with zero records.
 * V231220.1: Modified getFieldValues() to add availableRecords property to importId (to be used in CsDocuments).
 * V230828.1: Added logic in getImportHeaders() to remove system fields (i.e., purl) from import header.
 * V230817.1: Added '_mfi_studio_added_record' as another internal/system + Removed assigned values for _mfi_studio_sync to have both true/false values.
 * V230724.1: In getDropdownData(), removed page and limit params and called getOffers() without them +
 *    Added distinct param to getDropdownData() and getData() and modified filter accordingly.
 * V230628.1: Modified getImportHeaders() to fix a bug that added duplicate indexed fields (V230517) +
 *    Didn't add _id field as it will be added in other components.
 * V230518.1: (On Aref side) Modified getImportHeaders() to fix a bug that added event fields again +
 *    Modified _addImportIdToFilter() to bypass import injection for the predefined filters.
 * V230517.1: Modified getImportHeaders() to add the indexed extraFields to the import headers.
 * V230323.1: Added getSystemFields() and consumed it in getImportHeaders() as well as CsImports.
 * V230202.1: Added '_mfi_studio_sync' as another internal field and set values for it in getFieldValues() +
 *    Also, added it to the import headers (in getImportHeadersWithoutType()).
 * V230130.1: Added getChartsSettings() to be consumed in the chart components.
 * V230105.2: In getImportHeadersWithoutType(), added 'error' to the import headers.
 * V230105.1: In getImportHeaders(), made purlNumber's type to be always number +
 *    Added 'error' as another internal field and set values for it in getFieldValues().
 * V230104.1: Added includeRejected param to the getImportHeaders() and getLastImport() functions to consider rejected imports as well.
 * V230103.1: Added includeRejected param to the getFieldValues() and _addStatusToFilter() functions to add rejected imports to the filter dropdown.
 * V221003.1: Passed getInitialFilter() to getLastOffer().
 * V220923.1: Added getLastImport() method to support add/edit offer featur +
 *    Modified getImportHeaders() to consume getLastImport() and set types only if typeCasting is true.
 * 09/16/22(B0.19): Modified _addImportIdToFilter() to set the importId value with $in.
 * 09/07/22(B0.18): Added getImports() method to be used for dropdowns with isImport true.
 * 08/03/22(B0.17): Added getDataCountForKpi() to get the count for KPIs differently.
 * 06/28/22(B0.16): Added getDashboards() and getDashboard() for my CsDashboard use.
 * 06/22/22(B0.15): Removed _filterHasImportId() and added _addImportIdToFilter() to always inject importId.
 * 06/21/22(B0.14): Added getDropdownData() method.
 * 06/21/22(B0.13): Added _filterHasImportId() and consumed it in the getDataCount() and getData() methods.
 * 06/14/22(B0.12): Added device and platform in adjustFilter().
 * 06/10/22(B0.11): Added getImportNames method.
 * 06/03/22(B0.10): Added predefined Filter methods.
 * 05/24/22(B0.9): In getImportHeaders(), added secondIndexes property to the headers.
 * 04/21/22(B0.8): Consumed result.logout.
 * 04/21/22(B0.7): Added getImport() method.
 * 02/03/22(B0.6): Added setIndexedHeaders param to getImportHeaders().
 * 08/05/21(B0.5): In getImportHeaders(), added internalField: true to the purl fields +
 *    Implemented addStatusToFilter() to add completed status to filter + Added getDataCount() method.
 * 07/26/21(B0.4): In getFieldValues() & getImportHeaders(), accepted filter param and removed this.jwt.aid & passed filter to getImports() +
 *    In getData(), removed this.jwt.aid from getOffers() params.
 * 06/30/21(B0.3): In getFieldValues(), changed import_id to importId.
 * 06/21/21(B0.2): Modified getFieldValues() to include import names.
 * 06/09/21(B0.1): 1st release.
 */

import { APIService } from './cs-api-service.js';
import { formatNumber } from '../mixins/bt-mixin.js';

const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

function _addStatusToFilter(filter, includeRejected) {
   const myFilter = JSON.parse(JSON.stringify(filter));
   if (myFilter && myFilter.standard) {
      const match = myFilter.standard.find(f => f.$match).$match;
      // match.status = 'completed';
      const arrStatus = ['completed'];
      if (includeRejected)
         arrStatus.push('rejected');
      match.status = { '$in': arrStatus };
   }
   return myFilter;
}

function _addImportIdToFilter(importId, filter) {
   const newFilter = JSON.parse(JSON.stringify(filter));

   if (!filter.predefined) {
      // const $match = newFilter.standard.find(f => f.$match).$match;
      // if (!$match.importId)
      //    $match.importId = { '$in': [importId] };  //importId;

      //V240422
      if (newFilter.standard.findIndex(f => f.$limit) != 1)
         newFilter.standard.splice(1, 0, { $limit: 250000 });
   }

   return newFilter;
}

function _addHeaderToFilter(filter) {
   const myFilter = JSON.parse(JSON.stringify(filter));
   if (myFilter && myFilter.standard) {
      const match = myFilter.standard.find(f => f.$match).$match;
      match.header = { $ne: [] };
   }
   return myFilter;
}

// function _addKeyAfter(obj, afterKey, newKey, newValue) {
//    const updatedObject = Object.keys(obj).reduce((newObj, key) => {
//       newObj[key] = obj[key];

//       if (key === afterKey) {
//          // Add the new key-value pair after the afterKey
//          newObj[newKey] = newValue;
//       }

//       return newObj;
//    }, {});

//    return updatedObject;
// }

// function _addKeyAfter2(obj, afterKey, newKey, newValue) {
//    const keys = Object.keys(obj);
//    const index = keys.indexOf(afterKey);
 
//    if (index !== -1) {
//       // Splice the new key-value pair after the identified key
//       const updatedKeys = [...keys.slice(0, index + 1), newKey, ...keys.slice(index + 1)];
//       const newObj = {};
 
//       // Reconstruct the object with the new key-value pair
//       updatedKeys.forEach(key => {
//          newObj[key] = obj[key];
//       });
 
//       // Set the new key's value
//       newObj[newKey] = newValue;
 
//       return newObj;
//    }
 
//    // Return the original object if the afterKey is not found
//    return obj;
// }

function _addObjectAfter(arr, afterObjectKey, newObject) {
   const index = arr.findIndex(obj => obj[afterObjectKey]);

   if (index !== -1) {
      // Splice the new object after the identified object
      arr.splice(index + 1, 0, newObject);
   }

   // No need to return anything as the original array is modified.
}

function _addDistinctToFilter(filter) {
   const myFilter = JSON.parse(JSON.stringify(filter));
   if (myFilter && myFilter.standard) {
      const $project = myFilter.standard.find(f => f.$project);
      const $unwind = myFilter.standard.find(f => f.$unwind);
      const $group = myFilter.standard.find(f => f.$group);
      if ($project && $unwind && $unwind.$unwind === '$events' && $group) {
         const project = $project.$project;
         project.purl = 1;

         const group = $group.$group._id;
         const newGroup = { purl: '$purl' };
         const groupKeys = Object.keys(group);

         /* V240712 - start */
         let val, valParts, actualVal;
         if (groupKeys.includes('month') && (groupKeys.includes('year') || groupKeys.includes('dayOfMonth'))) {
            groupKeys.forEach(key => {
               val = group[key];
               switch (key) {
                  case 'year':
                  case 'month':
                  case 'dayOfMonth':
                     newGroup[key] = val;
                     group[key] = `$_id.${key}`;
                     break;
                  default:
                     valParts = (typeof val === 'string' ? val : val[Object.keys(val)[0]]).split('.');
                     actualVal = valParts[valParts.length - 1].replace('$', '');
                     newGroup[actualVal] = val;
                     group[key] = `$_id.${actualVal}`;
                     break;
               }
            });
         } else {
            groupKeys.forEach(key => {
               val = group[key];
               //V240424: const valParts = val.split('.');
               valParts = (typeof val === 'string' ? val : val[Object.keys(val)[0]]).split('.');
               actualVal = valParts[valParts.length - 1].replace('$', '');
               newGroup[actualVal] = val;
               group[key] = `$_id.${actualVal}`;
            });
         }
         /* V240712 - end */

         // const finalFilter = _addKeyAfter(myFilter, '$unwind', '$group', newGroup);
         // const finalFilter = _addKeyAfter2(myFilter, '$unwind', '$group', newGroup);
         _addObjectAfter(
            myFilter.standard,
            '$unwind',
            { $group: { _id: newGroup } }
         );
               
         return myFilter;               
      }
   }

   return filter;
}

function _mergeJSONObjects(obj1, obj2) {
   if (!Object.keys(obj1).length)
      return obj2;
   else if (!Object.keys(obj2).length)
      return obj1;
   else
      return { ...obj1, ...obj2 };
}

//V240909
function _mergeEmbeddedFilter(filter, embeddingFilter) {
   const newFilter = JSON.parse(JSON.stringify(filter));
   if (embeddingFilter) {
      newFilter.standard[0].$match = { ...newFilter.standard[0].$match, ...embeddingFilter };
   }
   // console.warn(`in _mergeEmbeddedFilter(): filter=${JSON.stringify(filter)}\nembeddingFilter=${JSON.stringify(embeddingFilter)}\nnewFilter=${JSON.stringify(newFilter)}`);
   return newFilter;
}

export class BtHelpers {

   constructor(token, isActualEndpoint, debug) {
      // alert('in BtHelpers: token=' + token + '\ndebug=' + debug + '\nisActualEndpoint=' + isActualEndpoint);
      //V240909: 
      const JWT = JSON.parse(atob(token.split('.')[1])); //depreciated
      //const JWT = JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
      //alert(JSON.stringify(JWT));
      this.jwt = {
         aid: JWT.aid,
         paid: JWT.paid,
         pa: JWT.pa,
         pu: JWT.pu,
         embeddedFilter: JWT.embeddedFilter ? JSON.parse(JWT.embeddedFilter) : null
         // embeddedFilter: {"member_id":{"$in":[12,103,165]}}
      };
      this.apiService = new APIService(this.jwt, token, debug?true:false, isActualEndpoint);
      this.isActualEndpoint = isActualEndpoint;
   }

   async getFieldValues(filter, includeRejected) {
      let fieldValues;
      const filterWithStatus = _addStatusToFilter(filter, includeRejected);
      const filterWithHeader = _addHeaderToFilter(filterWithStatus);
      // const result = await this.apiService.getImports(filterWithStatus, 100, 0, 'header status name processedRecords recordsDeleted');
      const result = await this.apiService.getImports(filterWithHeader, 100, 0, 'status name processedRecords recordsDeleted');
      if (result.logout || result.message || !result.data.length)
         fieldValues = [];
      else {
         // fieldValues = result.data.map(d => d._id);
         // const dataWithHeader = result.data.filter(d => d.header && d.header.length);
         fieldValues = result.data.map(d => {
            let count = d.processedRecords ? d.processedRecords : 0;
            if (d.recordsDeleted)
               count -= d.recordsDeleted;
            return {
               text: d.name + (d.status === 'rejected' ? ` [${d.status}]` : '') + ` (${formatNumber(count)})`,
               value: d._id,
               availableRecords: count //d.processedRecords - d.recordsDeleted
            };
         });
      }

      return {
         importId: fieldValues.filter(v => v.availableRecords),
         //V240103: error: ['true'],
         //V230817: _mfi_studio_sync: ['true']
      };
   }

   // { text:, value:, type:, isIndexed: bool, internalField: bool, eventField: bool}
   // async getImportHeaders(filter, setIndexedHeaders, includeRejected) {
   //    let importHeaders = [];
   //    let indexResult, lastOfferResult;
   //    // const filterWithStatus = _addStatusToFilter(filter);
   //    // const result1 = await this.apiService.getImports(filterWithStatus, 1);
   //    // if ((!result1.logout || !result1.message) && result1.data.length) {
   //    //    const lastImport = result1.data[0];

   //    const lastImport = await this.getLastImport(filter, includeRejected);
   //    if (lastImport) {
   //       // alert(JSON.stringify(lastImport));
   //       //V230828.1: Moved here to eliminate system fields from header (i.e., purl)
   //       const sysFields = this.getSystemFields(lastImport.generatePURL);
   //       if (lastImport.header) {
   //          lastImport.header.forEach(h => {
   //             const hLower = h.toLowerCase();
   //             if (!sysFields.find(f => f.value === hLower))
   //                importHeaders.push({ text: h, value: hLower });
   //          });
   //       }

   //       // importHeaders.push({ text: 'Import', value: 'importId', internalField: true, type: 'string' });
   //       // if (lastImport.generatePURL) {
   //       //    importHeaders.push({ text: 'purl', value: 'purl', internalField: true }); //unshift
   //       //    importHeaders.push({ text: 'basePurl', value: 'basePurl', internalField: true }); //unshift
   //       //    importHeaders.push({ text: 'purlNumber', value: 'purlNumber', internalField: true, type: 'number' }); //unshift
   //       // }
   //       // importHeaders.push({ text: 'error', value: 'error', type: 'boolean', systemField: true });
   //       // importHeaders.push({ text: '_mfi_studio_sync', value: '_mfi_studio_sync', type: 'boolean', systemField: true });

   //       // const sysFields = this.getSystemFields(lastImport.generatePURL);
   //       importHeaders = [
   //          ...importHeaders,
   //          ...sysFields
   //          // { text: '_id', value: '_id', type: 'string', isIndexed: true, internalField: true }
   //       ];

   //       // alert('before')
   //       console.log('importHeaders=' + JSON.stringify(importHeaders));
   //       console.log('set=' + JSON.stringify(Array.from(new Set(importHeaders))));
   //       // alert('after')

   //       if (setIndexedHeaders && importHeaders.length > 0) {
   //          //sample index: {"fieldName1": "transaction_date", "sortOrder1": -1, "fieldName2": "", "sortOrder2": -1, "unique": true, "name": "transaction_date_desc", "accountId": 19375, "_id": 1}
   //          indexResult = await this.apiService.getIndexes();
   //          const indexData = indexResult.data;
   //          if (indexData && indexData.length) {
   //             // Adding indexed extraFields to the import headers.
   //             indexData.forEach(index => {
   //                // const fldName1 = index.fieldName1.toLowerCase();
   //                // if (!importHeaders.find(h => h.value === fldName1 || fldName1.startsWith('events.')))
   //                //    importHeaders.push({ text: fldName1, value: fldName1 });
   //                if (index.fieldName1 != '_id' && !importHeaders.find(h => h.value === index.fieldName1 || index.fieldName1.startsWith('events.'))) {
   //                   importHeaders.push({ text: index.fieldName1, value: index.fieldName1 });
   //                }
   //             });
   
   //             importHeaders.forEach(header => {
   //                const indexedFields = indexData.filter(d => d.fieldName1 === header.value);
   //                if (indexedFields.length) {
   //                   header.isIndexed = true;
   //                   const secondIndexes = indexedFields.filter(h => h.fieldName2).map(h => h.fieldName2);
   //                   if (secondIndexes.length)
   //                      header.secondIndexes = secondIndexes;
   //                } else
   //                   header.isIndexed = false;
   //             });
   //          }
   //       }

   //       //setting types
   //       lastOfferResult = await this.apiService.getLastOffer(this.getInitialFilter());
   //       if (!lastOfferResult.logout || !lastOfferResult.message) {
   //          const lastOffer = lastOfferResult.data[0];
   //          // alert('in getImportHeaders(): lastOffer=' + JSON.stringify(lastOffer));
   //          for (const key in lastOffer) {
   //             const header = importHeaders.find(f => f.value === key);
   //             if (header && !header.type) {
   //                if (lastImport.typeCasting) {
   //                   header.type = typeof lastOffer[key];
   //                   if (header.type === 'string' && DATE_PATTERN.test(lastOffer[key]))
   //                      header.type = 'date';
   //                } else
   //                   header.type = 'string';
   //             }
   //          }
   //       }
   //    }

   //    // alert('in bt-helpers.getImportHeaders(): importHeaders=' + JSON.stringify(importHeaders));
   //    return importHeaders;
   // }

   // { text:, value:, type:, isIndexed: bool, internalField: bool, eventField: bool}
   async getImportHeaders(filter, setIndexedHeaders, includeRejected) {
      // const importData = await this.getImportData(filter, setIndexedHeaders, includeRejected);
      const importData = await this.getImportData(filter, setIndexedHeaders, includeRejected);
      return importData.headers;
   }

   async getImportData(filter, setIndexedHeaders, includeRejected) {
      let importHeaders = [];
      let fieldValidations = [];
      let indexResult, lastOfferResult;
      // const filterWithStatus = _addStatusToFilter(filter);
      // const result1 = await this.apiService.getImports(filterWithStatus, 1);
      // if ((!result1.logout || !result1.message) && result1.data.length) {
      //    const lastImport = result1.data[0];

      const lastImport = await this.getLastImport(filter, includeRejected);
      // console.warn('lastImport=', JSON.stringify(lastImport));
      if (lastImport) {
         const sysFields = this.getSystemFields(lastImport.generatePURL);
         if (lastImport.header) {
            lastImport.header.forEach(h => {
               const hLower = h.toLowerCase();
               if (!sysFields.find(f => f.value === hLower))
                  importHeaders.push({ text: h, value: hLower });
            });
         }

         importHeaders = [
            ...importHeaders,
            ...sysFields
         ];

         console.log('importHeaders=' + JSON.stringify(importHeaders));
         console.log('set=' + JSON.stringify(Array.from(new Set(importHeaders))));

         if (setIndexedHeaders && importHeaders.length > 0) {
            //sample index: {"fieldName1": "transaction_date", "sortOrder1": -1, "fieldName2": "", "sortOrder2": -1, "unique": true, "name": "transaction_date_desc", "accountId": 19375, "_id": 1}
            indexResult = await this.apiService.getIndexes();
            const indexData = indexResult.data;
            if (indexData && indexData.length) {
               // Adding indexed extraFields to the import headers.
               indexData.forEach(index => {
                  if (index.fieldName1 != '_id' && !importHeaders.find(h => h.value === index.fieldName1 || index.fieldName1.startsWith('events.'))) {
                     importHeaders.push({ text: index.fieldName1, value: index.fieldName1 });
                  }
               });
   
               importHeaders.forEach(header => {
                  const indexedFields = indexData.filter(d => d.fieldName1 === header.value);
                  if (indexedFields.length) {
                     header.isIndexed = true;
                     const secondIndexes = indexedFields.filter(h => h.fieldName2).map(h => h.fieldName2);
                     if (secondIndexes.length)
                        header.secondIndexes = secondIndexes;
                  } else
                     header.isIndexed = false;
               });
            }
         }

         //setting types
         lastOfferResult = await this.apiService.getLastOffer(this.getInitialFilter());
         if (!lastOfferResult.logout || !lastOfferResult.message) {
            const lastOffer = lastOfferResult.data[0];
            // alert('in getImportHeaders(): lastOffer=' + JSON.stringify(lastOffer));
            for (const key in lastOffer) {
               const header = importHeaders.find(f => f.value === key);
               if (header && !header.type) {
                  if (lastImport.typeCasting) {
                     header.type = typeof lastOffer[key];
                     if (header.type === 'string' && DATE_PATTERN.test(lastOffer[key]))
                        header.type = 'date';
                  } else
                     header.type = 'string';
               }
            }
         }

         if (lastImport.validations && lastImport.validations.data)
            fieldValidations = [...lastImport.validations.data];
      }

      // alert('in bt-helpers.getImportData(): importHeaders=' + JSON.stringify(importHeaders));
      return {
         headers: importHeaders,
         fieldValidations: fieldValidations
      };
   }
   

   async getImportHeadersWithoutType(filter) {
      const importHeaders = [];
      if (!filter)
         filter = this.getInitialFilter();

      const filterWithStatus = _addStatusToFilter(filter);
      const result = await this.apiService.getImports(filterWithStatus, 1);
      // if (!result.message && result.data.length) {
      if (result.data && result.data.length) {
         const lastImport = result.data[0];
         lastImport.header.forEach(h => {
            importHeaders.push({ text: h, value: h.toLowerCase() });
         });
         importHeaders.push({ text: 'error', value: 'error' });
         importHeaders.push({ text: '_mfi_studio_sync', value: '_mfi_studio_sync' });
         importHeaders.push({ text: '_mfi_studio_added_record', value: '_mfi_studio_added_record' });
      }

      // alert('importHeaders=' + JSON.stringify(importHeaders));
      return importHeaders;
   }

   getSelectedHeaders(importHeaders, numOfFields) {
      // alert('importHeaders=' + JSON.stringify(importHeaders) + '\nnumOfFields=' + numOfFields)
      const selectedHeaders = importHeaders.slice(0, Math.min(numOfFields, importHeaders.length)).map(h => h.value);
      // alert('selectedHeaders=' + JSON.stringify(selectedHeaders));
      return selectedHeaders;
   }

   getInitialFilter(selectedHeaders) {
      const filter = {
         standard: [{ $match: {} }],
         // columns: [...selectedHeaders]
      };
      if (selectedHeaders)
         filter.columns = [...selectedHeaders];

      return filter;
   }

   async getDataCount(importId, filter) {
      if (Object.keys(filter).length) {
         let newFilter = _addImportIdToFilter(importId, filter);
         newFilter = _mergeEmbeddedFilter(newFilter, this.jwt.embeddedFilter);
         const result = await this.apiService.getOffersCount(newFilter);
         if (!result.logout && !result.message)
            return result.data;
      }

      return 0;
   }

   // To obtain count for the KPI, we need to inject $unwindand $count and pass the query as aggregate
   async getDataCountForKpi(importId, filter) {
      if (Object.keys(filter).length) {
         const newFilter = _addImportIdToFilter(importId, filter);
         const result = await this.apiService.getOffersCountForKpi(newFilter);
         if (!result.logout && !result.message)
            return result.data;
      }

      return 0;
   }

   async getData(importId, filter, page, limit, distinct) {
      // alert(`in getData(): importId=${importId}\nfilter=${JSON.stringify(filter)}\npage=${page}, limit=${limit}`);
      if (Object.keys(filter).length) {
         let newFilter = _addImportIdToFilter(importId, filter);
         // console.warn(`filter BEFORE distinct '${distinct}'=${JSON.stringify(newFilter)}`);
         if (distinct)
            newFilter = _addDistinctToFilter(newFilter);
         // console.warn(`filter AFTER  distinct '${distinct}'=${JSON.stringify(newFilter)}`);

         newFilter = _mergeEmbeddedFilter(newFilter, this.jwt.embeddedFilter);
         const result = await this.apiService.getOffers(newFilter, page, limit);
         if (!result.logout && !result.message)
            return result.data;
      }

      return [];
   }

   // async getDropdownData(importId, filter, page, limit) {
   async getDropdownData(importId, filter) {
         // alert(`in getDropdownData(): importId=${importId}\nfilter=${JSON.stringify(filter)}`);
      if (Object.keys(filter).length) {
         let newFilter;
         if (filter.standard.find(f => f.$group).$group._id.Import === '$importId')
            newFilter = filter;
         else
            newFilter = _addImportIdToFilter(importId, filter);

         newFilter = _mergeEmbeddedFilter(newFilter, this.jwt.embeddedFilter);

         // const result = await this.apiService.getOffers(newFilter, page, limit);
         const result = await this.apiService.getOffers(newFilter);
         if (!result.logout && !result.message)
            return result.data;
      }

      return [];
   }

   async getItem(id) {
      const result = await this.apiService.getOffer(id);
      if (result.logout || result.message)
         return null;
      else
         return result.data;
   }

   adjustFilter(originalFilter, partialFilter) {
      const filter = JSON.parse(JSON.stringify(originalFilter));
      const $match = filter.standard.find(f => f.$match).$match;
      const newEvents = {};
      Object.keys(partialFilter).forEach(key => {
         switch (key) {
            case 'app_code':
            case 'event_code':
               newEvents[key] = { '$in': [partialFilter[key]] };
               break;
            case 'browser':
            case 'device':
            case 'event_date':
            case 'is_trigger':
            case 'os':
            case 'platform':
               newEvents[key] = partialFilter[key];
               break;
            default:
               $match[key] = partialFilter[key];
               break;
         }
      });
      if (Object.keys(newEvents).length) {
         const eventsObj = filter.standard.find(f => f.$events);
         let events;
         if (eventsObj)
            events = eventsObj.events.$elemMatch;
         else
            events = { $elemMatch: {} };

         Object.keys(newEvents).forEach(key => {
            events[key] = newEvents[key];
         });

         $match.events = events;
      }

      return filter;
   }

   async getImport(id) {
      // alert('in getImport(): id=' + id);
      const result = await this.apiService.getImport(id);
      if (result.logout || result.message)
         return null;
      else
         return result.data;
   }

   async getPredefinedFilters() {
      const result = await this.apiService.getPredefinedFilters();
      return result.data;
   }

   async getPredefinedFilter(id) {
      const result = await this.apiService.getPredefinedFilter(id);
      return result.data;
   }

   async createPredefinedFilter(postData) {
      const result = await this.apiService.createPredefinedFilter(postData);
      return result.data;
   }

   async updatePredefinedFilter(id, putData) {
      const result = await this.apiService.updatePredefinedFilter(id, putData);
      return result.data;
   }

   async deletePredefinedFilter(id) {
      const result = await this.apiService.deletePredefinedFilter(id);
      return result.data;
   }

   async getImportNames(limit, completedOnly) {
      // alert(`in bt-helpers.getImportNames(): limit=${limit}, completedOnly=${completedOnly}`);
      let filter;
      if (completedOnly) {
         filter = _addStatusToFilter(this.getInitialFilter(), false);
      }
      const result = await this.apiService.getImportNames(limit, filter);
      return result.data;
   }

   // just for my CsDashboard
   async getDashboards() {
      const result = await this.apiService.getDashboards();
      return result.data;
   }

   // just for my CsDashboard
   async getDashboard(id) {
      const result = await this.apiService.getDashboard(id);
      return result.data;
   }

   async getImports(filter, limit) {
      const result = await this.apiService.getImports(filter, limit);
      if (result.message)
         return [];
      else
         return result.data;
   }

   async getLastImport(filter, includeRejected) {
      if (!filter) {
         filter = this.getInitialFilter(['generatePURL', 'purlColumns', 'dedupColumns']);
      }
      const filterWithStatus = _addStatusToFilter(filter, includeRejected);
      const result = await this.apiService.getImports(filterWithStatus, 1);
      if (result.logout || result.message || !result.data.length)
         return null;
      else
         return result.data[0];
   }   

   async getChartsSettings() {
      const result = await this.apiService.getSettings();
      return result.data.charts;
   }

   getSystemFields(generatePurl) {
      const sysFields = [
         { text: 'Import', value: 'importId', internalField: true, type: 'string' }
      ];
      if (generatePurl) {
         sysFields.push({ text: 'purl', value: 'purl', internalField: true }); //unshift
         sysFields.push({ text: 'basePurl', value: 'basePurl', internalField: true }); //unshift
         sysFields.push({ text: 'purlNumber', value: 'purlNumber', internalField: true, type: 'number' }); //unshift
      }
      sysFields.push({ text: 'error', value: 'error', type: 'boolean', systemField: true });
      sysFields.push({ text: '_mfi_studio_sync', value: '_mfi_studio_sync', type: 'boolean', systemField: true });
      sysFields.push({ text: '_mfi_studio_added_record', value: '_mfi_studio_added_record', type: 'boolean', systemField: true });
      return sysFields;
   }

   // Moved from CsExports.vue
   getExportFields() {
      return [
         { text: '_id', value: '_id', type: 'string', isIndexed: true, internalField: true },
         { text: 'app_code', value: 'app_code', type: 'string', isIndexed: true, isEventField: true },
         { text: 'event_code', value: 'event_code', type: 'string', isIndexed: true, isEventField: true },
         { text: 'event_date', value: 'event_date', type: 'date', isIndexed: true, isEventField: true },
         { text: 'event_element', value: 'event_element', type: 'string', isIndexed: true, isEventField: true },
         { text: 'event_path', value: 'event_path', type: 'string', isIndexed: true, isEventField: true },
         { text: 'browser', value: 'browser', type: 'string', isIndexed: true, isEventField: true },  //V230630.1
         { text: 'device', value: 'device', type: 'string', isIndexed: true, isEventField: true }, //V230630.1
         { text: 'ip', value: 'ip', type: 'string', isIndexed: true, isEventField: true },   //V230630.1
         { text: 'os', value: 'os', type: 'string', isIndexed: true, isEventField: true },   //V230630.1
         { text: 'platform', value: 'platform', type: 'string', isIndexed: true, isEventField: true },   //V230630.1
         { text: 'event_data', value: 'event_data', type: 'number', isIndexed: false, isEventField: true }
      ];
   }

   async getLastExport(filter =null) {
      if (!filter) {
         filter = this.getInitialFilter();
      }
      const filterWithStatus = _addStatusToFilter(filter, false);
      const result = await this.apiService.getExports(filterWithStatus, 1, 1);
      if (result.logout || result.message || !result.data.length)
         return null;
      else
         return result.data[0];
   }

   // Moved from CsExports.vue
   getExportData(formData, importValidations, exportFields, actionIds =null, actions =null) {
      const exportData = JSON.parse(JSON.stringify(formData));
      importValidations.forEach(v => {
         // console.warn('importValidation='+JSON.stringify(v));
         const fld = exportData.fieldDefinitions.find(f => f.label === v.column);
         if (fld) {
            fld.dateFormat = v.format;
         }
      });

      // const $project = { _id: 0 };
      // const $project = {};
      const eventFields = {};
      const otherFields = {};
      const vars = [];
      exportData.fieldDefinitions.forEach(fld => {
         if (fld.value)
            vars.push(fld.value);
         else 
            vars.push(...fld.format.slice(1));
      });
      // alert('vars='+JSON.stringify(vars));
      const uniqueVars = [...new Set(vars)];
      // alert('uniqueVars='+JSON.stringify(uniqueVars));
      uniqueVars.forEach(v => {
         const field = exportFields.find(f => f.value === v);

         //V230627.1
         if (field) {
            if (field.isEventField) {
               // $project[field.value] = '$events.' + field.value;
               // hasEventField = true;
               eventFields[field.value] = '$events.' + field.value;
            } else {
               // $project[field.value] = 1;
               otherFields[field.value] = 1;
            }
         } else {
            const edField = exportData.fieldDefinitions.find(f => f.value === v);
            // $project[edField.value] = '$events.event_data.' + edField.value;
            // hasEventField = true;
            if (edField)   //V230814.2
               eventFields[edField.value] = '$events.event_data.' + edField.value;
         }
      });

      //V230627.2
      // if (!uniqueVars.find(v => v === '_id'))
      //    $project['_id'] = 0;

      // console.log('original aggregateQuery=' + exportData.aggregateQuery, true);
      const aggregateQuery = JSON.parse(exportData.aggregateQuery);

      //V230627.2
      const otherFieldsKeys = Object.keys(otherFields);
      if (otherFieldsKeys.length) {
         const projectObj = aggregateQuery.find(q => q.$project);
         if (projectObj) {
            const $project = projectObj.$project;
            const $projectKeys = Object.keys($project);
            otherFieldsKeys.forEach(key => {
               if (!$projectKeys.includes(key))
                  $project[key] = 1;
            });
         }
      }

      if (Object.keys(eventFields).length)
         aggregateQuery.push({ $unwind: '$events' });

      //V230814.1: Extending $project to resolve PURLs case-sensitivity issue
      if (!Object.keys(otherFields).includes('purlNumber'))
         otherFields.purlNumber = 1;

      otherFields.purlNumberStr = {
         "$cond": {
            "if": {"$eq": ["$purlNumber", 1]},
            "then": "",
            "else": {"$toString": {"$subtract": ["$purlNumber", 1]}}
         }
      };
      aggregateQuery.push({ $project: _mergeJSONObjects(otherFields, eventFields) });
      exportData.aggregateQuery = JSON.stringify(aggregateQuery);
      // exportData.exportFields = exportData.exportFields.map(fld => fld.text);
      // console.log('modified aggregateQuery=' + exportData.aggregateQuery, true);

      //V231113
      if (actionIds?.length) {
         exportData.actions = [];
         this.actionIds.forEach(id => {
            const action = actions.find(a => a._id === id);
            // if (action)
               exportData.actions.push({
                  actionId: action._id,
                  appCode: action.appCode,
                  actionName: action.name
                  // consumerCode: action.consumerCode   //V231115
               });
         });
      }

      // alert('in getExportData(): exportData=' + JSON.stringify(exportData));
      return exportData;
   }

   // getAllBehavioralFields() {
      // const behFields = [];
      // BEH_FIELDS.forEach(field => {
      //    const behField = JSON.parse(JSON.stringify(field));
      //    behField.value = 'events.' + behField.value;
      //    behFields.push(behField);
      // });
      // return behFields;
   // }

   // async getIndexedBehavioralFields() {
   //    const behFields = [];
   //    const result = await this.apiService.getIndexes();
   //    if (result.data && result.data.length) {
   //       BEH_FIELDS.forEach(field => {
   //          const indexedFields = result.data.filter(d => d.fieldName1 === 'events.' + field.value);
   //          if (indexedFields.length) {
   //             const behField = JSON.parse(JSON.stringify(field));
   //             const secondIndexes = indexedFields.filter(h => h.fieldName2).map(h => h.fieldName2);
   //             if (secondIndexes.length)
   //                behField.secondIndexes = secondIndexes;
   //             behFields.push(behField);
   //          }
   //       });
   //    }
   //    return behFields;
   // }
}