<template>
   <!-- TODO:
      - For CalculatedTable, force the existance of calculated columns
      - in clickEvents, handle the clickData for dates
      - for click event, support click on the google table (select event, getSelection())
      - Make cgart object properties based on the chart type
      - Validate data prop conditionally
      - Set props for filter2 to remove groupby and column tabs
      - Show colors beside dropdown
   -->
   <!-- HISTORY:
      V240905.3: Fixed a bug that didn't set contact id properly in the WEEKLY_CHART_FILTER and MONTHLY_CHART_FILTER filters.
      V240821.1: Supported icon and sum for the PaginaTable settings + Handled KPIs with no data for the contact profile.
      V240820.3: Added paginaTableSettings to the PaginaTable config and passed it along with kpisData to BtContactProfileForCs +
         Applied paginaTableSettings.events to filter the charts data.
      V240816.1: Consumed BtContactProfileForCs.vue in PaginaTable.
      V240809.1: Handled click event and added distinct switch for the aggregated KPI.
      V240808.2: Copied the full contents from V240719 in order to work on the new KPI. The code with BtContactProfileForCs was saved in export-in-pagina-table branch.
         Supported a new KPI with groupby in filter and single/multi rows output.
      V240719.2: Modified saveExport() to adjust filter same as what is done in CsExport + Removed original $project from filter.
      V240718.2: Added Create Export btn.
      V240711.1: Modified init() to handle Yearly-Monthly and Monthly-Daily data (3 property in _id instead of 2) + Commented export btn and some logic.
      V240701.2: Set maxRange to be always 365 and removed the option from the configuration dialog + Modified datePickerTypeChanged() to set the monthly items same as datepicker's ddMonthlyItems prop [added last 90/180/365 days] +
         Also, added Current and Last Quarter and Year options.
      V240628.1: Reverted changes from V240510.1 as BtDatePicker was rolled back + Made chartFilter2 editable for the power admin.
      V240627.1: Passed filter2 to bt-calculated-table + Moved myData2 down in init() +
         Added sortColumn and sortOrder fields and passed them to CalculatedTable + Removed preselected-fields prop from <bt-filter-wrapper-with-btn> of filter2 +
         Implemented initialFilter for the groupby tab but didn't finish it.
      V240618.1: Added a switch for using the the 2nd filter for CalculatedTable.
      V240617.2: Made Filter text-field editable for the power admin + Fixed a bug that forced the 2nd Filter to have value.
      V240614.1: Added 2nd filter for CalculatedTable to merge/blend its data with the 1st fliter.
      V240510.1: Consumed bt-date-picker's is-ls-chart and dd-monthly-items props to limit the dropdown choices.
      V240425.2: Modified prepareChartData() to replace Gchart labels from settings.
      V240311.1: Consumed getImportNames() instead of getImports().
      V240103.1: Added getDataCount() to calculate calculate offer counts from imports if possible.
      V230727.1: In <bt-autocomplete>, removed auto-select-first-item prop and consumed newly added preselected-items prop to set the last selected items at the start up +
         Commented out autoSelectFirstItem switch of the chart configuration.
      V230724.1: Added distinct prop and passed it to getDropdownData() and getData() for calculated tables and charts. 
      V230602.2: Supported the mix of 1 & 2 dim data for CalculatedTable.
      V230602.1: Fixed a bug in replacing event data codes with IDs (this.myData => data).
      V230601.1: Moved the logic for setting event data names into setEventDataNames() to be also used for the calculated tables +
         Modified calculatedTableCellClicked() to replace event data names with their IDs.
      V230531.1: (on Aref side) Reverted change in B0.29 (converted clickedData numeric values to Number) as it didn't slice event codes click properly.
      V230530.1: Added logic to support click event for known event data (to replace back the field names with their IDs).
      V230526.2: Fixed a bug that didn't replace event data names with IDs.
      V230526.1: Fixed getEventDataNames() multiple calls.
      V230525.1: Added logic in prepareChartData() to convert known event data codes to their names.
      V230523.1: Excluded event_data from the events table (in eventsClicked()) to be added to the expanded rows.
      V230518.1: Modified prepareChartData() to handle cases that not all groupby records have both dimentions.
      V230511.1: Modified some paddings.
      V230130.1: Added chartsSettings prop and consumed it for the calculatedTable + Replaced hasOwnProperty with hasOwn from mixins +
         Removed DEBUG and _alert() and moved it in log().
      09/19/22(B0.42): Modified chart-click's data for importId to be an array.
      09/19/22(B0.41): Modified calculatedTableCellClicked() & select() to fix the bug that couldn't replace import name with id.
      09/07/22(B0.40): Added isImport switch for the 'Dropdown' type to get data from imports collection instead of offers.
      07/19/22(B0.39): Added "Master Date Range" template to KPI.
      07/07/22(B0.38): Consumed calculated table's predefinedFilter prop.
      07/01/22(B0.37): Modified select: to get correct data for the event_date click.
      06/22/22(B0.36): Passed lastImportId to the getData(), getDataCount() and getDropdownData() methods.
      06/22/22(B0.35): Reverted changes from B0.33 (along with Aref) + Disabled multiSelect & removed clearable prop if autoSelect is set +
         Consumed getDropdownData() for dropdowns + Automatically configued autoSelect and multiSelect for imports dropdown.
      06/17/22(B0.34): Changed the logic from B0.33 to avoid injecting importId to the filters with Import in their groupby.
      06/16/22(B0.33): Consumed initialFilter prop for BtFilterDefinerWithBtn and set filter(s) to use the latest import.
      06/16/22(B0.32): Added autoSelectFirstItem option to the dropdowns and passed it to the BtAutocomplete component +
         Added number of events to the expanded items.
      06/14/22(B0.31): Consumed loading prop of BtCalculatedTable + Added _alert function.
      06/03/22(B0.30): Consumed debug, btHelpers and filterResult props in bt-filter +
         Adjusted includeTabls to exclude predefined tab where is not applicable.
      05/20/22(B0.29): In select: converted clickedData numeric values to Number to resolve the chart-click issue +
         Obtained behFields from bt-mixin + In eventsClicked(), changed the logic of getting events texts.
      05/19/22(B0.28): Moved calculated columns under the filter.
      05/18/22(B0.27): Displayed import names instead of IDs + Raised chart-click and chart-reclick events.
      05/16/22(B0.26): Set filter's groupbyId2Required prop for CalculatedTable to be true + Removed col-dim and row-dim props.
      04/21/22(B0.25): In prepareChartData(), saved imports id/name pairs + In select(), replaced import name with its id.
      04/21/22(B0.24): In prepareChartData(), replaced import IDs with their names.
      04/01/22(B0.23): Read import headers with index info to only make indexed headers sortable +
         Made the headers to be in their original case instead of lowercase.
      03/10/22(B0.22): Changed the logic for select() to handle events groupby (was fixed on Aref's side).
      02/08/22(B0.21): Consumed BtChartOptions + Reset chartConfig in cancelChartConfig().
      09/21/21(B0.20): In init(), reset chartData if myData is empty + In chartEvents(), made addedFieldByMfi_ values nummeric +
         In fireDropdownChange(), returned [] instead of [null].
      09/14/21(B0.19): Raised chart-reclick event.
      09/13/21(B0.18): Added chart-click event + Added $dropdownSwitch to the dropdown-change event.
      08/27/21(B0.17): Fixed the issue with DatePicker positioning (wraped it with a v-card) + Set same icon for Dropdown.
      08/26/21(B0.16): Changed positioning of Dropdown and DatePicker charts + Removed title for DatePicker and icon for Dropdown and DatePicker +
         In dropdown-change, returned column name along with values.
      08/23/21(B0.15): Added DatePicker + Separated v-card for Dropdown and datePicker, and located them in the title section +
         Changed some props and classes in v-dialog + Passed formatNumbers to KPI + Added buildOutputChart().
      08/20/21(B0.14): Changed main card class (changed pl-4 to px-3) + Removed prepend-inner-icon prop from bt-autocomplete.
      08/19/21(B0.13): Added Dropdown to the chart types + Removed includedTypes prop + Reset filter on chart type change.
      08/11/21(B0.12): Fixed a bug with KPI colors parsing + Didn't allow charts to use distinct.
      08/10/21(B0.11): Added KPI (gauge) to the chart types and implemented required logic including the 2nd filter +
         Added an optional icon to be displayed at the left side of title.
      08/05/21(B0.10): Added hasPaginaTable prop + Added PaginaTable to the chart types and implemented required logic with backward compatibility.
      07/09/21(B0.9): In init() and for some of date data, added logic to convert numbers to strings to fix a problem with charts.
      07/08/21(B0.8): In init() replaced standard[1] with find() + Added behFieldsForGroupby to display text instead of value.
      06/15/21(B0.7): Added flat prop to the main card + All props default & validations were removed.
      06/14/21(B0.6): Added and consumed activeTab, includedTabs, and stdFieldValues props + Changed icons from more_horiz to more_vert.
      06/10/21(B0.5): Raised filter-change event after input event.
      06/08/21(B0.4): Consumed BtFilterWrapperWithBtn + Fixed a bug in init() + Removed rows and cols.
      05/25/21(B0.3): Fixed DonutChart bugs and chart width.
      05/25/21(B0.2): Fixed bugs with showing chart for date fields + Supported chart colors and table.
      05/20/21(B0.1): 1sr release.
   -->
   <div>
       <v-card
         :flat="flat"
         :loading="loadingChartData"
      >
         <v-card-title v-if="!['Dropdown', 'DatePicker'].includes(chart.type)"
            class="title grey--text darken-4 font-weight-bold pl-3 pr-0 pt-2 pb-2">
            <v-icon v-if="chart.icon">{{chart.icon}}</v-icon>
            <span class="pl-1">{{chart.title}}</span>
            <div class="flex-grow-1"></div>
            <v-btn v-if="chart.type === 'PaginaTable' && canCreateExport"
               x-small
               class="mr-3"
               @click="createExportClicked"
            >Create Export
               <v-icon right dark>file_upload</v-icon>
            </v-btn>
            <v-icon v-if="showChartIcon || isTable"
               class="pr-2"
               @click="switchBetweenChartAndTable"
            >{{isTable ? 'bar_chart' : 'table_chart'}}
            </v-icon>
            <v-tooltip left v-if="canEditChart && !isTable">
               <template v-slot:activator="{ on }">
                  <v-btn text icon
                     v-on="on"
                     @click="configureChart"
                  >
                     <v-icon>more_vert</v-icon>
                  </v-btn>
               </template>
               <span>{{btnTooltip}}</span>
            </v-tooltip>
         </v-card-title>

         <v-card-text v-if="['Dropdown', 'DatePicker'].includes(chart.type)"
            :class="vcardTextClass"
         >
            <v-layout>
               <!-- :counter="`${item.list ? item.list.length : 0}`" -->
               <!-- :error-messages="myDash.filtersSetting[i].message" -->
               <!-- :hint="`${chart.selectedItems && chart.selectedItems.length ? item.name : ''}`" -->
               <!-- :multiple="chart.multiple" -->
               <!-- :prepend-inner-icon="`${chart.icon?chart.icon:chart.multiSelect?'filter_list':'filter'}`" -->
               <!-- item-text="name" -->
               <!-- item-value="id" -->
               <!-- :auto-select-first-item="chart.autoSelectFirstItem" -->
               <bt-autocomplete v-if="chart.type==='Dropdown'"
                  chips persistent-hint small-chips single-line
                  excluded-label="Exclude"
                  included-label="Include"
                  prepend-inner-icon="filter_list"
                  :clearable="!chart.autoSelectFirstItem"
                  :counter="dropdownData.length"
                  :deletable-chips="!chart.autoSelectFirstItem"
                  :is-included="true"
                  :items="dropdownData"
                  :label="chart.title"
                  :multiple="chart.multiSelect"
                  :preselected-items="chart.lastDropdownSelections"
                  :return-object="false"
                  @switch-changed="dropdownSwitchChanged"
                  @change="dropdownSelectionsChanged"
               ></bt-autocomplete>
               <v-card-text v-else
                  flat
                  class="px-0 py-0"
               >
                  <!-- :dd-placeholder="datePickerPlaceholder"  && chart.datePickerType==='month'-->
                  <!--V240628: is-ls-chart -->
                  <!--V240628: :dd-monthly-items="datepickerMonthlyItems" -->
                  <bt-date-picker v-if="chart.datePickerType==='month'"
                     :debug="debug"
                     :dd-icon="`${chart.icon ? chart.icon : 'date_range'}`"
                     :dd-custom-label="true"
                     :dd-label="chart.title"
                     :dd-value="chart.defaultId"
                     :dp-no-title="false"
                     :dp-range="chart.datePickerType"
                     :dp-max-range="parseInt(chart.maxRange)"
                     v-model="datePickerRange"
                  ></bt-date-picker>
                  <!-- :dd-placeholder="datePickerPlaceholder" -->
                  <bt-date-picker v-else-if="chart.datePickerType==='day'"
                     :debug="debug"
                     :dd-icon="`${chart.icon ? chart.icon : 'calendar_today'}`"
                     :dd-custom-label="true"
                     :dd-label="chart.title"
                     :dd-value="chart.defaultId"
                     :dp-no-title="false"
                     :dp-range="chart.datePickerType"
                     v-model="datePickerDate"
                  ></bt-date-picker>
               </v-card-text>
               <div class="flex-grow-1" v-if="chart.type==='DatePicker' && canEditChart"></div>
               <v-tooltip left v-if="canEditChart">
                  <template v-slot:activator="{ on }">
                     <v-btn text icon
                        v-on="on"
                        @click="configureChart"
                     >
                        <v-icon>more_vert</v-icon>
                     </v-btn>
                  </template>
                  <span>{{btnTooltip}}</span>
               </v-tooltip>
            </v-layout>
         </v-card-text>

         <v-card-text v-else-if="chart.type"
            class="px-3 py-0 pb-2"
         >
            <!-- :counter="dropdownData.length" -->
            <!-- :kpi2Title="kpi2Title" -->
            <!-- :title="chart.title" -->
            <!-- :icon="chart.icon" -->
            <!-- template-name="ratio-with-master-date-range" -->
            <!-- :format-numbers="chart.formatNumbers" -->
            <bt-kpi v-if="chart.type==='KPI'"
               :charts-settings="chartsSettings"
               :filter="chart.filter"
               :format-numbers="chart.templateName != 'with-groupby'"
               :gauge-colors="chart.gaugeColors"
               :gauge-max-value="chart.gaugeMaxValue"
               :kpi1="kpi1"
               :kpi2="kpi2"
               :kpi-data="myData"
               :template-name="chart.templateName"
               :type="chart.kpiType"
               @click="kpiClicked"
            ></bt-kpi>
            <v-data-table v-else-if="chart.type==='PaginaTable'"
               dense show-expand single-expand
               class="elevation-1 mb-2"
               :footer-props="{
                  itemsPerPageOptions:[5, 10, 20],
                  showFirstLastPage: true
               }"
               :headers="tableHeaders"
               :hide-default-footer="!tableItemsCount"
               item-key="_id"
               :items="myData"
               :items-per-page="tableItemsPerPage"
               :loading="loadingTableData"
               :loading-text="$t('loading-text')"
               :no-data-text="$t('no-data-text', { value: 'data' })"
               :no-results-text="$t('no-results-text', { value: 'data' })"
               :options.sync="tableOptions"
               :server-items-length="tableItemsCount"
               @update:expanded="tableItemExpanded"
            >
               <template v-slot:[`item.actions`]="{ item }">
                  <div @click="contactProfileClicked(item)">
                     <bt-contact-profile-for-cs
                        :contact-data="item.contactData"
                        :event-data-fields="eventDataFields"
                        :labels="chartsSettings?.labels"
                        :kpis-data="cpKpisData"
                        :monthly-chart-data="monthlyChartData"
                        :weekly-chart-data="weeklyChartData"
                        :settings="chart.paginaTableSettings || {}"
                        :debug="debug"
                     ></bt-contact-profile-for-cs>
                  </div>
               </template>
               <template v-slot:expanded-item="{ item }">
                  <td v-for="(colspan, i) in tableColspans" :key="i"
                     dense
                     class="py-2"
                     valign="top"
                     :colspan="colspan"
                  >
                     <ul v-if="item.details">
                        <li v-for="(detail, j) in item.details[i]" :key="`${i}_${j}`">
                           <span class="expanded-header">{{detail.key}}: </span>
                           <span v-if="detail.key==='events'"
                              class="expanded-content"
                           >
                                 <!-- :disabled="!detail.value.length" -->
                              <v-btn v-if="detail.value.length"
                                 text icon dense
                                 @click="eventsClicked(detail.value)"
                              >{{detail.value.length}}
                                 <v-icon>more_horiz</v-icon>
                              </v-btn>
                              <span v-else>None</span>
                           </span>
                           <span v-else
                              class="expanded-content"
                           >{{detail.value}}
                           </span>
                        </li>
                     </ul>
                  </td>
               </template>
            </v-data-table>
               <!-- :options="chart.options" -->
            <bt-calculated-table v-else-if="chart.type==='CalculatedTable'"
               :debug="debug"
               :calculated-columns="chart.calculatedColumns"
               :chart-data="myData"
               :chart-data2="myData2"
               :charts-settings="chartsSettings"
               :columns-sequence="chart.columnsSequence ? JSON.parse(chart.columnsSequence) : []"
               :filter="chart.filter"
               :filter2="chart.hasFilter2 ? chart.filter2 : null"
               :loading="loadingCalculatedData"
               :predefined-filter="predefinedFilter"
               :sort-column="chart.sortColumn"
               :sort-order="chart.sortOrder"
               @click="calculatedTableCellClicked"
            ></bt-calculated-table>
            <!-- :create-chart="drawGEOChart" -->
            <!-- drawGEOChart(el, google) {
               if(el === undefined)
                  return
               return new google.visualization.GeoChart(el)
            } -->
            <!-- TODO: change v-else to v-hide for the console error -->
            <GChart v-else
               ref="gChart"
               :data="chartData"
               :type="chart.type"
               :options="chart.options"
               :settings="gchartSettings"
               :events="chartEvents"
            />
         </v-card-text>
      </v-card>

      <v-dialog no-click-animation persistent scrollable
         max-width="840px"
         v-model="dialog"
      >
         <v-card>
            <v-card-title class="title grey--text darken-4 font-weight-bold py-2">Chart Configuration:</v-card-title>

            <v-card-text class="pb-0">
               <!-- <div class="error--text">{{errMsg}}</div> -->
               <v-form lazy-validation
                  ref="form"
                  v-model="isFormValid"
               >
                  <v-row class="pt-3">
                     <v-col xs="12" sm="12" :md="chartTypeColSize" :lg="chartTypeColSize" class="pt-0 pb-0">
                        <v-select hide-selected persistent-hint required
                           class="pt-0 mt-0 pb-2"
                           ref="chartType"
                           append-icon="insert_chart_outlined"
                           :dense="fieldDense"
                           :items="chartTypes"
                           :hint="`${chartConfig.tempType?'Type':''}`"
                           placeholder="Type"
                           :rules="[rules.required]"
                           v-model="chartConfig.tempType"
                           @change="chartTypeChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-if="isKpi"
                        xs="12" sm="12" md="5" lg="5" class="pt-0 pb-0"
                     >
                        <v-select dense hide-selected persistent-hint
                           append-icon="article"
                           hint="Template Name"
                           :items="kpiTemplateNameItems"
                           v-model="chartConfig.templateName"
                           @change="kpiTemplateNameChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-else-if="isDropdown"
                        xs="12" sm="12" md="3" lg="3"
                        class="pt-2"
                     >
                        <v-switch
                           class="mx-0 mt-0 mb-0 pb-0"
                           :label="` Is Import: ${chartConfig.isImport?'Yes':'No'}`"
                           v-model="chartConfig.isImport"
                           @change="isImportChanged"
                        ></v-switch>
                     </v-col>
                  </v-row>

                  <div v-if="chartConfig.type">
                     <v-row v-if="chartConfig.tempType && !isDatePicker" class="pt-2">
                        <v-col xs="12" sm="12" :md="isDropdown ? 12 : 7" :lg="isDropdown ? 12 : 7" class="pt-0 pb-0">
                           <v-text-field clearable dense persistent-hint
                              class="pt-0 pb-2"
                              ref="chartTitle"
                              autocomplete="off"
                              append-icon="title"
                              hint="Title"
                              v-model="chartConfig.title"
                           ></v-text-field>
                        </v-col>
                        <v-col v-if="!isDropdown && !isDatePicker"
                           xs="12" sm="12" md="5" lg="5" class="pt-0 pb-0"
                        >
                           <v-text-field clearable dense persistent-hint
                              class="pt-0"
                              ref="chartIcon"
                              autocomplete="off"
                              hint="Icon"
                              v-model="chartConfig.icon"
                              :append-icon="chartConfig.icon"
                           ></v-text-field>
                        </v-col>
                     </v-row>

                     <v-row v-if="isKpi" class="pt-2 pb-0 mb-0">
                        <v-col cols="12" v-if="chartConfig.templateName === kpiTemplateNames.ratio">
                           <v-slider dense hide-details ticks
                              v-model="chartConfig.gaugeMaxValue"
                              class="pt-3"
                              label="Gauge Max Value:"
                              max="100"
                              min="0"
                              step="1"
                              thumb-label="always"
                              thumb-size="24"
                           ></v-slider>
                        </v-col>
                        <v-col cols="7" class="py-0 my-0" v-if="chartConfig.templateName === kpiTemplateNames.aggregate">
                           <v-select hide-selected persistent-hint required
                              ref="kpiType"
                              class="pt-0"
                              hint="Type of KPI"
                              :dense="fieldDense"
                              :items="kpiTypeItems"
                              :rules="[rules.required]"
                              v-model="chartConfig.kpiType"
                           ></v-select>
                        </v-col>
                        <v-col cols="5" class="pt-2 pb-0 my-0" v-if="chartConfig.templateName === kpiTemplateNames.aggregate">
                           <v-switch
                              class="mx-0 mt-0 mb-0 pb-0"
                              :label="`  Is Distinct: ${chartConfig.isKpiDistinct ? 'Yes' : 'No'}`"
                              v-model="chartConfig.isKpiDistinct"
                           ></v-switch>
                        </v-col>
                     </v-row>

                     <v-row v-if="isChart || (isKpi && chartConfig.templateName === kpiTemplateNames.ratio)">
                        <v-col cols="12">
                           <!-- <v-select v-if="chartConfig.type && chartConfig.type != 'PaginaTable'" -->
                           <v-select hide-selected persistent-hint required
                              ref="chartColors"
                              class="pt-0"
                              hint="Colors"
                              :dense="fieldDense"
                              :items="chartColorsItems"
                              :rules="[rules.required]"
                              v-model="chartColors"
                           ></v-select>
                        </v-col>
                     </v-row>

                     <!-- <v-row v-if="isKpi" class="pt-2 pb-0 mb-0">
                        <v-col v-if="chartConfig.templateName === kpiTemplateNames.ratio"
                           cols="12"
                        >
                           <v-slider dense hide-details ticks
                              v-model="chartConfig.gaugeMaxValue"
                              class="pt-3"
                              label="Gauge Max Value:"
                              max="100"
                              min="0"
                              step="1"
                              thumb-label="always"
                              thumb-size="24"
                           ></v-slider>
                        </v-col>
                     </v-row> -->

                     <bt-chart-options v-if="isChart"
                        class="pt-2 pb-0"
                        :chart-type="chartConfig.tempType"
                        :debug="debug"
                        :dense="false"
                        label="Chart Options in JSON Format"
                        :rows="3"
                        :rules="[rules.validJson]"
                        v-model="chartOptions"
                     ></bt-chart-options>

                     <v-row v-if="isDropdown">
                        <!-- V230727.1 -->
                        <!-- <v-col>
                           <v-switch
                              class="py-0"
                              hide-details
                              :disabled="autoSelectFirstItemDisabled"
                              :label="`Auto Select 1st Item: ${chartConfig.autoSelectFirstItem?'Yes':'No'}`"
                              v-model="chartConfig.autoSelectFirstItem"
                              @change="chartConfig.multiSelect = false"
                           ></v-switch>
                        </v-col> -->
                        <v-col>
                           <v-switch v-if="!chartConfig.isImport"
                              class="py-0"
                              hide-details
                              :disabled="chartConfig.autoSelectFirstItem"
                              :label="`Multiple Choice: ${chartConfig.multiSelect?'Yes':'No'}`"
                              v-model="chartConfig.multiSelect"
                           >
                              <!-- <template v-slot:label classs="py-0">
                                 <div class="px-1 py-0 my-0 body-2 font-weight-bold font-italic text-no-wrap">{{`Multiple Choice: ${chartConfig.multiSelect?'Yes':'No'}`}}</div>
                              </template> -->
                           </v-switch>
                        </v-col>
                     </v-row>

                     <v-card v-if="chartConfig.tempType && !isDatePicker && !chartConfig.isImport"
                        flat
                        class="px-0 pt-0"
                     >
                        <v-card-title v-if="canEditFilter"
                           class="mx-0 my-0 px-0 pt-0 pb-0">
                           <div class="flex-grow-1"></div>
                           <bt-filter-wrapper-with-btn
                              :active-tab="activeTab"
                              :bt-helpers="btHelpers"
                              :debug="debug"
                              :fields="fields"
                              :filter-result="myData"
                              :groupby-id2-required="isCalculatedTable"
                              :groupby-operators="groupbyOperators"
                              :included-tabs="includedTabs"
                              :is-admin="isAdmin"
                              :max="max"
                              :preselected-fields="preselectedFields"
                              :groupby-field-has-event-data="groupbyFieldHasEventData"
                              :should-init="shouldInit"
                              :show-groupby-id2="showGroupbyId2"
                              :show-test-cases="showTestCases"
                              :std-field-values="stdFieldValues"
                              v-model="chartConfig.filter"
                              @filter-change="filterChanged"
                           ></bt-filter-wrapper-with-btn>
                        </v-card-title>
                        <v-card-text class="py-0 px-0">
                           <v-textarea hide-details outlined
                              ref="chartFilter"
                              class="pt-0"
                              :label="`Filter${isKpi || isCalculatedTable ? ' 1' : ''}`"
                              rows="4"
                              :readonly="!isAdmin"
                              :rules="[rules.validJson]"
                              v-model="chartFilter"
                           ></v-textarea>
                        </v-card-text>
                     </v-card>

                     <!-- 2nd filter for CalculatedTable -->
                     <v-switch v-if="isCalculatedTable"
                        dense hide-details
                        label=" Use Filter 2"
                        v-model="chartConfig.hasFilter2"
                        @change="hasFilter2Changed"
                     ></v-switch>
                     <v-card v-if="isCalculatedTable && chartConfig.hasFilter2"
                        flat
                        class="px-0"
                     >
                        <v-card-title v-if="canEditFilter"
                           class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <!-- :preselected-fields="preselectedFields" -->
                           <!-- :initial-filter="initialFilter" -->
                           <bt-filter-wrapper-with-btn
                              :activeTab="activeTab"
                              :bt-helpers="btHelpers"
                              :debug="debug"
                              :fields="fields"
                              :filter-result="myData"
                              :groupby-operators="groupbyOperators"
                              :included-tabs="includedTabs2"
                              :is-admin="isAdmin"
                              :max="max"
                              :should-init="shouldInit"
                              :show-groupby-id2="isCalculatedTable"
                              :show-test-cases="showTestCases"
                              :std-field-values="stdFieldValues"
                              v-model="chartConfig.filter2"
                              @filter-change="filter2Changed"
                           ></bt-filter-wrapper-with-btn>
                        </v-card-title>
                        <v-card-text class="px-0 pb-0">
                           <v-textarea hide-details outlined
                              ref="chartFilter2"
                              class="pt-0"
                              label="Filter 2"
                              rows="4"
                              :readonly="!isAdmin"
                              :rules="[rules.validJson]"
                              v-model="chartFilter2"
                           ></v-textarea>
                        </v-card-text>
                     </v-card>

                     <v-card v-if="isKpi && chartConfig.templateName === kpiTemplateNames.ratio"
                        flat
                        class="px-0"
                     >
                        <v-card-title v-if="canEditFilter"
                           class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <bt-filter-wrapper-with-btn
                              :activeTab="activeTab"
                              :bt-helpers="btHelpers"
                              :debug="debug"
                              :fields="fields"
                              :filter-result="myData"
                              :groupby-operators="groupbyOperators"
                              :included-tabs="includedTabs"
                              :is-admin="isAdmin"
                              :max="max"
                              :preselected-fields="preselectedFields"
                              :should-init="shouldInit"
                              :show-groupby-id2="showGroupbyId2"
                              :show-test-cases="showTestCases"
                              :std-field-values="stdFieldValues"
                              v-model="chartConfig.filter2"
                              @filter-change="filter2Changed"
                           ></bt-filter-wrapper-with-btn>
                        </v-card-title>
                        <v-card-text class="px-0 pb-0">
                           <v-textarea hide-details outlined readonly
                              ref="chartFilter2"
                              class="pt-0"
                              label="Filter 2"
                              rows="3"
                              :rules="[rules.validJson]"
                              v-model="chartFilter2"
                           ></v-textarea>
                        </v-card-text>
                     </v-card>

                     <!-- for DatePicker -->
                     <div v-if="isDatePicker">
                        <v-select hide-selected persistent-hint required
                           class="pt-2"
                           ref="chartDatePickerType"
                           hint="Type of Date Picker"
                           :items="datePickerTypes"
                           :rules="[rules.required]"
                           v-model="chartConfig.datePickerType"
                           @change="datePickerTypeChanged"
                        ></v-select>
                        <v-select v-if="chartConfig.datePickerType"
                           hide-selected persistent-hint required
                           class="pt-2"
                           :hint="datePickerSelectLabel"
                           :items="datePickerSelectItems"
                           :rules="[rules.required]"
                           v-model="chartConfig.defaultId"
                        ></v-select>
                        <!-- <v-select v-if="chartConfig.datePickerType==='month'"
                           hide-selected persistent-hint required
                           class="pt-2"
                           hint="Maximum Range"
                           :items="datePickerMaxRangeItems"
                           :rules="[rules.required]"
                           v-model="chartConfig.maxRange"
                        ></v-select> -->
                     </div>

                        <!-- :rules="[rules.requiredArray]" -->
                     <bt-calculated-columns v-if="isCalculatedTable"
                        class="mt-5"
                        :debug="debug"
                        v-model="chartCalculatedColumns"
                     ></bt-calculated-columns>

                     <v-row v-if="isCalculatedTable" class="pt-5">
                        <v-col xs="12" sm="12" md="6" lg="6">
                           <v-text-field dense persistent-hint
                              ref="columnsSequence"
                              autocomplete="off"
                              class="pt-0"
                              hint="Columns Sequence: Array of field names and/or event codes"
                              :rules="[rules.requiredArray]"
                              v-model="chartConfig.columnsSequence"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="3" lg="3">
                              <!-- :rules="[rules.numberOrString]" -->
                           <v-text-field dense persistent-hint
                              ref="sortColumn"
                              autocomplete="off"
                              class="pt-0"
                              hint="Column to Sort on"
                              v-model="chartConfig.sortColumn"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="3" lg="3">
                           <v-select dense persistent-hint hide-selected required
                              ref="sortOrder"
                              class="pt-0"
                              hint="Sort Order"
                              :disabled="!chartConfig.sortColumn"
                              :items="sortOrderItems"
                              :rules="[rules.requiredSortOrder]"
                              v-model="chartConfig.sortOrder"
                           ></v-select>
                        </v-col>
                     </v-row>

                     <v-row v-if="isPaginaTable" class="pt-5">
                        <v-col cols="12">
                           <v-textarea dense outlined
                              class="pt-0"
                              label="Settings"
                              rows="4"
                              :rules="[rules.paginaTableSettings]"
                              v-model="paginaTableSettings"
                           ></v-textarea>
                        </v-col>
                     </v-row>
                  </div>
               </v-form>
            </v-card-text>

            <v-card-actions class="pt-0 pb-0 pr-3">
               <div class="error--text pl-4">{{errMsg}}</div>
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="cancelChartConfig"
               >Cancel</v-btn>
               <v-btn text
                  class="px-0 ml-0"
                  color="blue darken-1"
                  @click="saveChartConfig"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <v-dialog no-click-animation persistent scrollable
         max-width="1280px"
         v-model="dialogEvents"
      >
         <v-card flat tile>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-4">Events:</v-card-title>
            <v-card-text class="px-4 py-2">
               <v-data-table dense show-expand single-expand
                  class="elevation-1 mb-2"
                  :expanded.sync="expanded"
                  :footer-props="{
                     itemsPerPageOptions:[5, 10, 20],
                     showFirstLastPage: true
                  }"
                  :headers="eventHeaders"
                  :hide-default-footer="events.length < 11"
                  item-key="__btChartExtras.id"
                  :items="events"
                  :items-per-page="10"
                  :loading="loadingEvents"
                  :loading-text="$t('loading-text')"
                  :no-data-text="$t('no-data-text', { value: 'events' })"
                  :no-results-text="$t('no-results-text', { value: 'events' })"
                  @update:expanded="eventsExpanded"
               >
                  <template v-slot:expanded-item="{ item }">
                     <!-- <td dense class="py-2" valign="top">&nbsp;</td> -->
                     <td dense
                        class="py-2" valign="top"
                        :colspan="eventHeaders.length"
                     >
                        <ul v-if="item.__btChartExtras.details && item.__btChartExtras.details.length">
                           <li v-for="(detail, j) in item.__btChartExtras.details" :key="j">
                              <span class="expanded-header">{{detail.key}}: </span>
                              <span class="expanded-content">{{detail.value}}</span>
                           </li>
                        </ul>
                        <ul v-else>
                           <li>
                              <span class="expanded-header"></span>
                              <span class="expanded-content">No details are available</span>
                           </li>
                        </ul>
                     </td>
                  </template>
               </v-data-table>
            </v-card-text>
            <v-card-actions class="py-0 pr-3">
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="closeDialogEvents"
               >Close</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <v-dialog no-click-animation persistent scrollable
         max-width="480px"
         v-model="dialogExport"
      >
         <v-card :loading="loadingDialogExport">
            <v-card-title class="title grey--text darken-4 font-weight-bold py-2">Export Configuration:</v-card-title>

            <v-card-text>
               <!-- <div class="error--text">{{errMsg}}</div> -->
               <v-form lazy-validation
                  ref="form"
                  v-model="isFormExportValid"
               >
                  <v-row>
                     <v-col cols="12">
                        <v-text-field autofocus dense persistent-hint required
                           ref="exportName"
                           append-icon="title"
                           autocomplete="off"
                           hint="* Export Name"
                           :counter="100"
                           :maxlength="100"
                           placeholder="specify a name for your export"
                           :rules="[rules.required, rules.minLength]"
                           v-model="exportName"
                        ></v-text-field>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card-text>

            <v-card-actions class="pt-0 pb-0 pr-3">
               <div class="error--text pl-4">{{errMsg}}</div>
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="cancelExport"
               >Cancel</v-btn>
               <v-btn text
                  class="px-0 ml-0"
                  color="blue darken-1"
                  :disabled="!isFormExportValid"
                  @click="saveExport"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import { BtHelpers } from '../services/bt-helpers.js';
import { isIncluded, getAllBehavioralFields, hasOwn, getEventDataFields } from '../mixins/bt-mixin.js';
import BtFilterWrapperWithBtn from './BtFilterWrapperWithBtn.vue';
import BtKpi from './BtKpi.vue';
import BtAutocomplete from './BtAutocomplete.vue';
import BtDatePicker from "../components/BtDatePicker.vue";
import BtCalculatedColumns from "./BtCalculatedColumns.vue";
import BtCalculatedTable from "./BtCalculatedTableForCs.vue";
import BtChartOptions from './BtChartOptions.vue';
import { APIService } from '../services/cs-api-service.js';
import { ExportFormData } from '../models/cs-export.js';
import BtContactProfileForCs from "./BtContactProfileForCs.vue";

// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "BtChart";
const DAYS = ['', 'Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
const MONTHS = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const EMPTY_FILTER = {
   "standard": [{ "$match":{} }],
   "columns": []
};
const FILTER_TABS = ['standard', 'behavioral', 'bylocation', 'groupby', 'columns', 'predefined', 'fulltext'];
const FILTER_GROUPBY_OPERATORS = ['sum', 'max', 'min', 'avg', 'count', 'distinct'];
const KPI_TEMPLATE_NAMES = {
   // internal: 'internal-date-range',
   master: 'master-date-range',
   ratio: 'ratio-with-master-date-range',
   aggregate: 'with-groupby'
}
const NOT_EXISTED = 'Not Existed';

const WEEKLY_CHART_FILTER = {
   standard: [
      {"$match":{"_id":"##idHolder##"}},
      {"$unwind":"$events"},
      {
         "$group":{
            "_id":{
               "dayOfWeek":{"$dayOfWeek":"$events.event_date"},
               "Event Code":"$events.event_code"
            },
            "Count":{"$sum":1}
         }
      },
      {"$sort":{"_id":1}}
   ],
   columns: []
}

const MONTHLY_CHART_FILTER = {
   standard: [
      {"$match":{"_id":"##idHolder##"}},
      {"$unwind":"$events"},
      {
         "$group":{
            "_id":{
               "year":{"$year":"$events.event_date"},
               "month":{"$month":"$events.event_date"},
               "Event Code":"$events.event_code"
            },
            "Count":{"$sum":1}
         }
      },
      {"$sort":{"_id":1}}
   ],
   columns: []
}

const KPI_FILTER = {
   standard: [
      {
         "$match": {
            "_id": "##idHolder##",
            "events": {
               "$elemMatch": {
                  "event_code": {"$in": ["##eventCodeHolder##"]}
               }
            }
         }
      },
      {
         "$project": {
            "events": {
               "$filter": {
                  "input": "$events",
                  "as": "event",
                  "cond": {
                     "$and": [
                        {"$in": ["$$event.event_code",["##eventCodeHolder##"]]}
                     ]
                  }
               }
            }
         }
      },
      {"$unwind": "$events"},
      {
         "$group": {
            "_id": {"Event Code": "$events.event_code"}
         }
      }
   ],
   columns: []
}

class Chart {
   constructor(chart) {
      this.type = chart.type || ''; //ColumnChart
      this.tempType = this.type;
      this.title = chart.title || '';
      this.icon = chart.icon || '';
      this.filter = chart.filter && Object.keys(chart.filter).length
         ? chart.filter : JSON.parse(JSON.stringify(EMPTY_FILTER));

      //for charts
      this.options = chart.options && Object.keys(chart.options).length
         ? chart.options : {};
         // {
         //    "height":"250",
         //    // "width":"100%",
         //    "legend":{"position":"top"},
         //    "chartArea":{"left":"70", "right":"20"},
         //    "colors": ["#0f1f38","#8e7970","#f55449","#1b4b5a"]
         // };


      //for CalculatedTable
      this.hasFilter2 = chart.hasFilter2 ? true : false;
      this.calculatedColumns = chart.calculatedColumns || [];
      this.columnsSequence = chart.columnsSequence || ''
      this.sortColumn = chart.sortColumn || ''
      this.sortOrder = chart.sortOrder || ''

      //for KPI
      this.templateName = chart.templateName || KPI_TEMPLATE_NAMES.ratio;
      this.gaugeMaxValue = chart.gaugeMaxValue || 100;
      // this.gaugeColors = chart.gaugeColors || ["#0f1f38","#8e7970","#f55449"];
      this.gaugeColors = chart.gaugeColors || [];
      this.kpiType = chart.kpiType || '';
      this.isKpiDistinct = chart.isKpiDistinct || false;

      //for CalculatedTable and KPI
      // if (chart.type === 'CalculatedTable')
      //    this.filter2 = this.hasFilter2 ? chart.filter2 : '';
      // else
         this.filter2 = chart.filter2 && Object.keys(chart.filter2).length
            ? chart.filter2 : JSON.parse(JSON.stringify(EMPTY_FILTER));

      //for Dropdown
      this.multiSelect = chart.multiSelect || false;
      this.autoSelectFirstItem = chart.autoSelectFirstItem || false;
      this.isImport = chart.isImport || false;
      this.lastDropdownSelections = chart.lastDropdownSelections || [];

      //for DatePicker
      this.datePickerType = chart.datePickerType || '';
      this.defaultId = chart.defaultId || '';
      // this.maxRange = chart.maxRange || 0;
      this.maxRange = 365;

      //for PaginaTable
      this.paginaTableSettings = chart.paginaTableSettings || {};
   }
}

function _jsonParse (strVal) {
   if (strVal) return JSON.parse(strVal);
   else return {};
}

// function _getSuggestedIcon(chartType) {
//    switch (chartType) {
//       case 'PaginaTable':
//          return 'table_view'; //table_chart\table_rows
//       case 'KPI':
//          return 'compare';
//       case 'Dropdown':
//          return '';
//       case 'AreaChart':
//          return 'area_chart';
//       case 'BarChart':
//          return 'bar_chart';
//       case 'ColumnChart':
//          return '';
//       case 'ComboChart':
//          return 'multiline_chart';
//       case 'DonutChart':
//          return 'donut_chart';
//       case 'GeoChart':
//          return '';
//       case 'LineChart':
//          return 'show_chart';
//       case 'PieChart':
//          return 'pie_chart';
//       default:
//          return '';
//    }
// }

function _getIndex(key, val) {
   let index;
   if (key === 'month')
      index = MONTHS.findIndex(d => d === val);
   else if (key === 'dayOfWeek')
      index = DAYS.findIndex(d => d === val);
   else
      index = val;

   return isNaN(index) ? index : parseInt(index);
}

function validJson(value) {
   try {
      if (value) {
         if (value.trim().indexOf('"') === 0)
            return 'SyntaxError: Unexpected token in JSON at position 0';
         JSON.parse(value);
      }
      return true;
   } catch (error) {
      return error.toString();
   }
}

function validJsonArray(value) {
   try {
      let json;
      if (value) {
         json = JSON.parse(value);
         return Array.isArray(json) || 'Should be an array';
      } else return true;
   } catch (error) {
      return error.toString();
   }
}

function paginaTableSettings(strVal) {
   // {
   //    "events": ["30501", "60521", "20103"],
   //    "kpis": [
   //       { "icon":"icon1", "title": "title1", "groupBy": "30569", "operator": "avg|max|min|sum", "event_data": "timeSpent"},
   //       { "icon":"icon2", "title": "title2", "groupBy": "30503", "operator": "count"}
   //    ] 
   // }
   let result = validJson(strVal);
   //alert('result1=' + result + '\n' + typeof result)
   let jsonVal;
   if (typeof result === 'boolean') {
      jsonVal = JSON.parse(strVal);
      const keys = Object.keys(jsonVal);
      for (var i=0; i < keys.length; i++) {
         if (keys[i] != 'events' && keys[i] != 'kpis')
            return 'Unknown key: ' + keys[i];
      }

      if (jsonVal.events && !Array.isArray(jsonVal.events))
         return 'events should be an array!';

      if (jsonVal.kpis)
         result = validJsonArray(JSON.stringify(jsonVal.kpis));

      //alert('result2=' + result + '\n' + typeof result)

      if (typeof result === 'boolean') {
         for (i=0; i < jsonVal.kpis.length; i++) {
            const kpi = jsonVal.kpis[i];
            if (!kpi.groupBy)
               return 'GroupBy is required!';
            if (['avg','max','min','sum'].includes(kpi.operator)) {
               if (!kpi.event_data)
                  return 'event_data is required!';
            } else if (kpi.operator != 'count')
               return 'Invalid operator: ' + kpi.operator;
         }
      }

      return result;
   }
}

export default {
   name: NAME,

   components: {
      GChart,
      BtFilterWrapperWithBtn,
      BtKpi,
      BtAutocomplete,
      BtDatePicker,
      BtCalculatedColumns,
      BtCalculatedTable,
      BtChartOptions,
      BtContactProfileForCs
   },

   props: {
      /* chart's v-model */
      value: {
         type: Object,
         required: true,
         default: () => new Chart({})
      },
      activeTab: {
         type: String
      },
      btnTooltip: {
         type: String,
         default: 'click to edit the chart features'
      },
      canEditChart: {
         type: Boolean,
         default: false
      },
      canEditFilter: {
         type: Boolean,
         default: false
      },
      data: {
         type: Array,
         // required: !this.hasPaginaTable,
         default: () => []
      },
      debug: {
         type: Boolean,
         default: false
      },
      distinct: {
         type: Boolean
      },
      fieldDense: {
         type: Boolean,
         default: true
      },
      hasPaginaTable: {
         type: Boolean,
         default: true
      },
      chartsSettings: {
         type: Object
      },

      //for table
      tableItemsPerPage: {
         type: Number,
         default: 5
      },

      /* For BtFilterWrapperWithBtn */
      behFieldValues: {
         type: Object
      },
      fields: {
         type: Array
      },
      flat: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      },
      isAdmin: {
         type: Boolean
      },
      max: {
         type: Number
      },
      preselectedFields: {
         type: Array
      },
      shouldInit: {
         type: Boolean
      },

      //for my testing purposes only
      showTestCases: {
         type: Boolean
      },
      stdFieldValues: {
         type: Object
      }
   },

   data() {
      return {
         gchartSettings: {
            packages: ['corechart', 'table', 'map', 'geochart'],
            mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY
         },
         rules: {
            required: value => !!value || "Value is required!",
            // requiredArray: value => alert(JSON.parse(value)) || "Value is required!",
            validJson: value => {
               return validJson(value);
            },
            validJsonArray: value => {
               return validJsonArray(value);
            },
            requiredArray: value => {
               try {
                  let arr;
                  if (value) {
                     arr = JSON.parse(value);
                     if (Array.isArray(arr)) {
                        if (!arr.length)
                           return 'Array should have one or more items!';
                        else {
                           let errMsg;
                           arr.forEach(element => {
                              if (typeof element != 'string' && (typeof element != 'number' || !/^[0-9]+$/.test(element))) {
                                 errMsg = 'Items should be strings or numbers only!';
                                 return;
                              }
                           });
                           return errMsg || true;
                        }
                     } else
                        return 'Should be an array!';
                  } else return true;
               } catch (error) {
                  // alert(error);
                  return error.toString();
               }
            },
            requiredSortOrder: value => {
               if (this.chartConfig.sortColumn)
                  return !!value || 'Value is required!';
               else return true;
            },
            minLength: value => value.length >= 5 || "Name should have more than 5 charecters!",
            paginaTableSettings: value => {
               return paginaTableSettings(value);
            }
         },
         chartTypes:[
            { text: 'Area Chart', value: 'AreaChart' },
            { text: 'Bar Chart', value: 'BarChart' },
            { text: 'Column Chart', value: 'ColumnChart' },
            { text: 'Combo Chart', value: 'ComboChart' },
            { text: 'Donut Chart', value: 'DonutChart' },
            { text: 'Geo Chart', value: 'GeoChart' },
            { text: 'Line Chart', value: 'LineChart' },
            { text: 'Pie Chart', value: 'PieChart' }
            // { text: 'Table', value: 'Table' }
         ],
         chartColorsItems: [
            { text: "Watery Blue-Greens", value: "#003b46,#07575b,#66a5ad,#c4dfe6"},
            { text: "Exotic & High-Impact", value: "#0f1f38,#8e7970,#f55449,#1b4b5a"},
            { text: "Fresh Greens", value: "#265c00,#68a225,#b3de81,#b3de99"},
            { text: "Crisp & Dramatic", value: "#505160,#68829e,#aebd38,#598234"},
            { text: "Day & Night", value: "#011a27,#063852,#f0810f,#e6df44"},
            { text: "Spicy Neutrals", value: "#af4425,#662e1c,#ebdcb2,#c9a66b"},
            { text: "Golden Afternoon", value: "#882426,#cdbea7,#323030,#c29545"}
         ],
         firstLoad: true,
         loadingChartData: false,
         loadingTableData: false,
         loadingCalculatedData: false,
         dialog: false,
         isFormValid: false,
         chart: {},      //the copy of value
         chartConfig: {},  //the copy of chart
         chartData: [[], []],    //the copy of data
         chartOptions: '',
         chartFilter: '',
         chartFilter2: '',
         chartColors: [],
         lastChartType: '',
         isFilterChanged: true,
         isFilter2Changed: true,
         behFields: null,
         behFieldsForGroupby: null,
         errMsg: '',
         btHelpers: null,
         tableHeaders: [],
         myData: [],
         myData2: [],
         tableItemsCount: 0,
         tableOptions: {},
         tableColspans: [],
         expanded: [],
         dialogEvents: false,
         loadingEvents: false,
         eventHeaders: [],
         events: [],
         isChart: false,
         isCalculatedTable: false,
         isPaginaTable: false,
         isKpi: false,
         kpi1: 0,
         kpi2: 0,
         isDropdown: false,
         dropdownData: [],
         includedTabs: [],
         groupbyOperators: [],
         showGroupbyId2: true,

         isDatePicker: false,
         datePickerTypes: [
            { text: "Monthly", value: "month" },
            { text: "Daily", value: "day" }
         ],
         // datePickerMaxRangeItems: [
         //    { text: "1 Month", value: 31 },
         //    { text: "2 Months", value: 62 },
         //    { text: "3 Months", value: 93 },
         // ],
         datePickerSelectLabel: '',
         datePickerSelectItems: [],
         datePickerRange: [],
         datePickerDate: '',
         chartEvents: {
            // if click event doesn't exist, select event is fired.
            // click: (event) => {
            //    this.log('in chartEvents.click: event=' + JSON.stringify(event));
            //    const clickData = {};
            //    const id = this.chart.filter.standard.find(f => f.$group).$group._id;
            //    const idKeys = Object.keys(id);
            //    const targetIds = event.targetID.split('#');
            //    const row = parseInt(targetIds[targetIds.length - 1]);
            //    let idVal = id[idKeys[0]].replace('$', '');
            //    clickData[idVal] = this.chartData[row + 1][0];
            //    if (targetIds.length === 3) {
            //       const col = parseInt(targetIds[1]);
            //       idVal = id[idKeys[1]].replace('$', '');
            //       clickData[idVal] = this.chartData[0][col + 1];
            //    }
            //    this.log('in chartEvents: clickData=' + JSON.stringify(clickData));
            //    this.$emit('chart-click', clickData);
            // },
            select: () => {
               const selection = this.$refs.gChart.chartObject.getSelection();
               // _alert('in chartEvents.select: selection: ' + JSON.stringify(selection));
               const selectData = {};
               if (selection.length) {
                  let $expr;
                  const $addFields = {};
                  const row = selection[0].row + 1;
                  const col = selection[0].column;

                  const id = JSON.parse(JSON.stringify(this.chart.filter.standard.find(f => f.$group).$group._id));
                  if (Object.keys(id).length === 3)
                     delete id['month'];

                  const idKeys = Object.keys(id);
                  // _alert('id=' + JSON.stringify(id) + '\nidKeys=' + JSON.stringify(idKeys) + '\nval0=' + JSON.stringify(id[idKeys[0]]) + ', val1=' + id[idKeys[1]]);
                  if (typeof id[idKeys[0]] === 'string')
                     selectData[id[idKeys[0]].replace('$events.', '').replace('$', '')] = this.chartData[row][0];
                  else {
                     const fieldName = id[idKeys[0]]['$' + idKeys[0]];
                     if (fieldName === '$events.event_date') {
                        const exprItem = {};
                        // exprItem['$' + idKeys[0]] = id[idKeys[0]].replace('$events.', '');
                        exprItem['$' + idKeys[0]] = '$$event.event_date';
                        $expr = {
                           $eq: [
                              exprItem,
                              _getIndex(idKeys[0], this.chartData[row][0])
                           ]
                        };
                     } else {
                        const key0 = 'addedFieldByMfi_' + idKeys[0];
                        selectData[key0] = _getIndex(idKeys[0], this.chartData[row][0]);
                        $addFields[key0] = id[idKeys[0]];
                     }
                  }

                  //2nd condition was addedd in B0.22.
                  if (typeof col === 'number' && idKeys.length === 2) {
                     if (typeof id[idKeys[1]] === 'string')
                        selectData[id[idKeys[1]].replace('$events.', '').replace('$', '')] = this.chartData[0][col];
                     else {
                        const fieldName = id[idKeys[1]]['$' + idKeys[1]];
                        if (fieldName === '$events.event_date') {
                           const exprItem = {};
                           // exprItem['$' + idKeys[0]] = id[idKeys[0]].replace('$events.', '');
                           exprItem['$' + idKeys[1]] = '$$event.event_date';
                           $expr = {
                              $eq: [
                                 exprItem,
                                 _getIndex(idKeys[1], this.chartData[0][col])
                              ]
                           };
                        } else {
                           const key1 = 'addedFieldByMfi_' + idKeys[1];
                           selectData[key1] = _getIndex(idKeys[1], this.chartData[0][col]);
                           $addFields[key1] = id[idKeys[1]];
                        }
                     }
                  }

                  if (Object.keys($addFields).length)
                     selectData.$addFields = $addFields;
                  else if ($expr)
                     selectData.$expr = $expr;

                  //B0.25: Replacing import name with its id
                  Object.keys(selectData).forEach(key => {
                     // alert('in select(): imports=' + JSON.stringify(this.imports));
                     // alert('in select(): key=' + key + ', val=' + selectData[key]);
                     if (key === 'importId') {
                        // for (let i = 0; i < this.imports.length; i++) {
                        //    if (this.imports[i].name === selectData[key]) {
                        //       selectData[key] = this.imports[i].id;
                        //       break;
                        //    }
                        // }

                        //B0.41 & B0.42
                        const val = Array.isArray(selectData[key]) ? selectData[key][0] : selectData[key];
                        selectData[key] = [this.imports.find(imprt => imprt.name === val).id];
                        // alert('in select(): selectData=' + JSON.stringify(selectData[key]));
                     } else if (this.eventDataFields.find(f => f.value === key.replace('event_data.', ''))) {
                           // alert('in select(1): selectData=' + JSON.stringify(selectData));
                           const val = Array.isArray(selectData[key]) ? selectData[key][0] : selectData[key];
                           // selectData[key] = [this.eventDataNames.find(ed => ed.text === val).value];
                           const ed = this.eventDataNames.find(ed => ed.text === val);
                           if (ed)
                              selectData[key] = Number(ed.value);
                           // alert('in select(2): selectData=' + JSON.stringify(selectData));
                     } else {
                         const label = this.chartsSettings.labels.find(l => l.label === selectData[key]);
                         if (label)
                           selectData[key] = label.id.toString();
                        // alert('in else: selectData=' + JSON.stringify(selectData));
                     }
                     //V230531.1
                     // else if (!isNaN(selectData[key])) {
                     //    selectData[key] = Number(selectData[key]);
                     // }
                  });

                  // alert('selectData1=' + JSON.stringify(selectData));
                  Object.keys(selectData).forEach(sdKey => {
                     if (selectData[sdKey] === NOT_EXISTED) {
                        delete selectData[sdKey];
                        return;
                     }
                  });

                  // alert('selectData2=' + JSON.stringify(selectData));
                  this.$emit('chart-click', selectData);
               } else {
                  this.$emit('chart-reclick');
               }

               console.log('in chartEvents.select: selectData=' + JSON.stringify(selectData));

               // from Aref:
               // let selections = []
               // Object.entries(this.$refs).forEach(entry => {
               //    let key = entry[0];
               //    let value = entry[1];
               //    _alert('key=' + key + ', value=' + JSON.stringify(value));
               //    if(value.length>0 && value[0].chartObject.getSelection().length > 0){
               //       selections.push({key: key, selection: value[0].chartObject.getSelection()})
               //    }
               // });
            }
         },
         dropdownSwitch: true,
         dropdownSelections: [],
         imports: [],
         chartCalculatedColumns: '',
         autoSelectFirstItemDisabled: false,
         predefinedFilter: null,
         kpiTemplateNames: KPI_TEMPLATE_NAMES,
         kpiTemplateNameItems: [
            // { text: "Internal Date Range", value: KPI_TEMPLATE_NAMES.internal },
            { text: "Master Date Range", value: KPI_TEMPLATE_NAMES.master },
            { text: "Ratio with Master Date Range", value: KPI_TEMPLATE_NAMES.ratio },
            { text: "Aggregated", value: KPI_TEMPLATE_NAMES.aggregate }
         ],
         eventDataFields: getEventDataFields(),
         apiService: null,
         eventDataNames: [],
         lastEventData: '',
         lastIdList: [],
         // datepickerMonthlyItems: [  //V240510 then V240628
         //    { text: "Current Month", value: "TM" },
         //    { text: "Last Month", value: "LM" }
         // ],
         // for the CalculatedTable's 2nd filter
         includedTabs2: [],

         // for the CalculatedTable
         sortOrderItems: [
            { text: "Ascending", value: "asc" },
            { text: "Descending", value: "desc" }
         ],
         // // initialFilter: {}

         // for export
         apiServiceCs: null,
         dialogExport: false,
         isFormExportValid: false,
         loadingDialogExport: false,
         exportName: '',
         lastExport: null,
         importValidations: null,
         exportFields: null,
         groupbyFieldHasEventData: false,
         kpiTypeItems: [
            { text: "Scalar", value: "scalar" },
            { text: "Table", value: "table" }
         ],
         monthlyChartData: [],
         weeklyChartData: [],
         paginaTableSettings: '',
         cpKpisData: []
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      vcardTitleClass() {
         if (this.isDropdown || this.isKpi)
            return 'py-0 px-0';
         else
            return 'title grey--text darken-4 font-weight-bold pl-3 pr-1 pt-2 pb-2'
      },

      vcardTextClass() {
         let result = 'py-2 pl-3 ';
         return result + (this.canEditChart ? 'pr-0' : 'pr-3');
      },

      isTable() {
         return this.chart.type === 'Table';
      },

      showChartIcon() {
         return this.chart.type && this.chart.type.indexOf('Chart') > 0;
      },

      datePickerPlaceholder() {
         let placeholder = 'Date Picker';

         if (this.datePickerSelectItems.length > 0 && this.chartConfig.defaultId) {
            const myItem = this.datePickerSelectItems.find(item => item.value === this.chartConfig.defaultId)
            if (myItem != undefined)
               placeholder = myItem.text;
         }

         return placeholder;
      },

      chartTypeColSize() {
         if (this.isKpi) return 7;
         else if (this.isDropdown) return 9;
         else return 12;
      },

      canCreateExport() {
         return this.$store.getters.user.policies.includes(`contact-export-create`);
      }
   },

   watch: {
      token() {
         this.init();
      },

      value: {
         immediate: true,
         deep: true,
         handler() {
            // this.log('in v-model watch');
            this.init();
         }
      },

      // stdFieldValues: {
      //    immediate: true,
      //    deep: true,
      //    handler() {
      //       this.init();
      //    }
      // },

      data: {
         immediate: true,
         deep: true,
         handler() {
            // this.log('in data watch');
            if (!this.hasPaginaTable)
               this.init();
         }
      },

      preselectedFields: {
         immediate: false,
         deep: true,
         handler() {
            // _alert('chart=' + JSON.stringify(this.chart))
            if (!this.chart.filter.columns.length)
               this.chart.filter.columns = [...this.preselectedFields];

            if (this.value.type && this.value.filter && Object.keys(this.value.filter).length)
               this.init();
         }
      },

      tableOptions: {
         immediate: false,
         handler (val) {
            // _alert('in watch: tableOptions=' + JSON.stringify(val));
            if (val.sortBy.length) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.chart.filter.sort = sort;
            }

            if (!this.firstLoad)
               this.getTableItems(val.page, val.itemsPerPage);
         }
      },

      datePickerRange: {
         immediate: false,
         deep: true,
         handler (arrDates) {
            this.$emit('date-picker-change', arrDates)
         }
      },

      datePickerDate: {
         immediate: false,
         handler (date) {
            // _alert('in watch: datePickerDate=' + date);
            this.$emit('date-picker-change', date)
         }
      },

      distinct: {
         immediate: false,
         handler() {
            // this.log('in distinct watch');
            if (this.isChart || this.isDropdown || this.isCalculatedTable)
               this.init();
         }
      },
   },

   methods: {
      log(msg, _alert =false) {
         if (this.debug) {
            console.log(`-----${NAME} V240905.3 says => ${msg}`);
            if (_alert) {
               alert(`${NAME} says:\n${msg}`);
            }
         }
      },

      // async init(setImportId =false) {
      async init() {
         this.log('in init/start: chart=' + JSON.stringify(this.chart) +
            '\n\ndata=' + JSON.stringify(this.data));

         if (this.token && this.value && Object.keys(this.stdFieldValues).length) {
            this.firstLoad = false;
            this.myData = [];
            this.myData2 = [];
            this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
            this.apiService = new APIService(null, this.token, this.debug, this.isActualEndpoint, '/api/bi/v1');
            this.apiServiceCs = new APIService(null, this.token, this.debug, this.isActualEndpoint);  //this.jwt

            this.chart = new Chart(this.value);

            if (this.chart.type) {
               this.processChartTypeChange(this.chart.type);
               // this.filterChanged(this.chart.filter);
            }

            /* B0.33-start */
            //TODO: Use find to get $match + Move the logic in a function.
            // if (setImportId && this.stdFieldValues && Object.keys(this.stdFieldValues).length) {
            //    let $match = this.chart.filter.standard[0].$match;
            //    if (!Object.keys($match).includes('importId')) {
            //       const $groupObj = this.chart.filter.standard.find(kv => kv.$group);
            //       if (!$groupObj || !Object.keys($groupObj.$group._id).includes('Import'))
            //          this.chart.filter.standard[0].$match = {
            //             importId: this.stdFieldValues.importId[0].value,
            //             ...$match
            //          };
            //    }
            //    // _alert('chart.filter:\n' + JSON.stringify(this.chart.filter));
            //    if (this.isKpi) {
            //       $match = this.chart.filter2.standard[0].$match;
            //       if (!Object.keys($match).includes('importId')) {
            //          const $groupObj = this.chart.filter2.standard.find(kv => kv.$group);
            //          if (!$groupObj || !Object.keys($groupObj.$group._id).includes('Import'))
            //             this.chart.filter2.standard[0].$match = {
            //                importId: this.stdFieldValues.importId[0].value,
            //                ...$match
            //             };
            //       }
            //       // _alert('chart.filter2:\n' + JSON.stringify(this.chart.filter2));
            //    }
            // }
            /* B0.33-end */

            if (this.isPaginaTable) {
               this.loadingTableData = true;
               // await sleep(10000);
               if (this.isActualEndpoint) {
                  this.tableItemsCount = await this.getDataCount(this.chart.filter);
                  if (this.tableItemsCount)
                     this.myData = await this.btHelpers.getData(
                        this.stdFieldValues['importId'][0].value,
                        this.chart.filter,
                        this.tableOptions.page,
                        this.tableOptions.itemsPerPage
                     );
               } else {
                  this.tableItemsCount = 5;//6470;
                  this.myData = this.getSampleDataForPaginaTable(this.tableOptions.page, this.tableOptions.itemsPerPage);
               }
               //V240103.1: this.tableItemsCount = await this.btHelpers.getDataCount(this.stdFieldValues['importId'][0].value, this.chart.filter);

               if (this.chart.filter.columns) {
                  const importHeaders = await this.btHelpers.getImportHeaders(this.chart.filter, true);
                  this.tableHeaders = [];
                  this.chart.filter.columns.forEach(col => {
                     const header = importHeaders.find(h => h.value === col);
                     this.tableHeaders.push({
                        text: header ? header.text : col,
                        value: col,
                        sortable: header ? header.isIndexed : false
                     });
                  });
                  this.addActionsHeader();
                  // _alert('in init(): tableHeaders2=' + JSON.stringify(this.tableHeaders));
                  const len = this.chart.filter.columns.length + 1;
                  this.tableColspans = [];
                  this.tableColspans.push(Math.ceil(len / 3));
                  this.tableColspans.push(Math.ceil((len - this.tableColspans[0]) / 2));
                  this.tableColspans.push(len - this.tableColspans[0] - this.tableColspans[1]);
                  // _alert('len='+this.tableColspans.join(','));
               }
               this.loadingTableData = false;
            } else if (this.isCalculatedTable) {
               let data;
               this.loadingCalculatedData = true;
               // await sleep(5000);

               if (this.isActualEndpoint)
                  data = await this.btHelpers.getData(
                     this.stdFieldValues['importId'][0].value,
                     this.chart.filter,
                     this.tableOptions.page,
                     this.tableOptions.itemsPerPage,
                     this.distinct
                  );
               else
                  data = this.getSampleDataForCalculatedTable();

               const $group = this.chart.filter.standard.find(f => f.$group);
               if ($group) {
                  if ($group.$group._id['Import'] === '$importId') {
                     this.imports = [];
                     let importData;
                     for (let i = 0; i < data.length; i++) {
                        // _alert('in init(): ' + i + '\n' + JSON.stringify(data[i]));
                        const importId = data[i]._id.Import;
                        importData = this.imports.find(imprt => [imprt.id, imprt.name].includes(importId));
                        if (importData) {
                           data[i]._id.Import = importData.name;
                        } else {
                           //B0.41
                           // alert('importId=' + importId + '\nimports=' + JSON.stringify(this.imports)
                           //    + '\nfilter=' + JSON.stringify(this.imports.filter(imprt => imprt.id === importId)));
                           if (!this.imports.find(imprt => imprt.id === importId)) {
                              importData = await this.btHelpers.getImport(importId);
                              // alert('importData=' + JSON.stringify(importData));
                              const importName = `${importData.name} (${importData.processedRecords})`;
                              this.imports.push({ id: importData._id, name: importName });
                              data[i]._id.Import = importName;
                           }
                        }
                     }
                  }

                  //V230602
                  /* data will be changed in the function */
                  this.inject2ndDimension(data);

                  /* data will be updated in the function */
                  await this.setEventDataNames($group.$group._id, data);
               }
               
               this.myData = data;
               this.log('myData=' + JSON.stringify(this.myData));

               if (this.chart.filter.predefined) {
                  this.predefinedFilter = await this.btHelpers.getPredefinedFilter(this.chart.filter.predefined);
                  // _alert('predefinedFilter=' + JSON.stringify(this.predefinedFilter));
               }

               if (this.chart.hasFilter2) {  //V240627.1: filter2
                  const filter2$group = this.chart.filter2.standard.find(f => f.$group);
                  if (filter2$group) {
                     if (this.isActualEndpoint)
                        this.myData2 = await this.btHelpers.getData(
                           this.stdFieldValues['importId'][0].value,
                           this.chart.filter2,
                           this.tableOptions.page,
                           this.tableOptions.itemsPerPage,
                           this.distinct
                        );
                     else
                        this.myData2 = this.getSampleData2ForCalculatedTable(filter2$group.$group);

                     this.log('myData2=' + JSON.stringify(this.myData2));
                  }
               }

               this.loadingCalculatedData = false;
            } else if (this.isKpi) {
               if (this.chart.templateName === KPI_TEMPLATE_NAMES.aggregate) {
                  if (this.isActualEndpoint)
                     this.myData = await this.btHelpers.getData(
                        this.stdFieldValues['importId'][0].value,
                        this.chart.filter,
                        undefined,
                        undefined,
                        this.chart.isKpiDistinct || false
                     );
                  else
                     this.myData = this.getSampleDataForKpi();
               } else {
                  //V240103.1: this.kpi1 = await this.btHelpers.getDataCount(this.stdFieldValues['importId'][0].value, this.chart.filter);
                  this.kpi1 = await this.getDataCount(this.chart.filter);
                  if (this.chart.templateName === KPI_TEMPLATE_NAMES.ratio) {
                     //V240103.1: this.kpi2 = await this.btHelpers.getDataCount(this.stdFieldValues['importId'][0].value, this.chart.filter2);
                     this.kpi2 = await this.getDataCount(this.chart.filter2);
                  } else {
                     this.kpi2 = 0;
                  }
               }
            } else if (this.isDropdown) {
               this.dropdownData = [];
               if (this.chart.isImport) {
                  // const data = await this.btHelpers.getImports(this.chart.filter, 20);
                  // if (data && data.length)
                  //    this.dropdownData = data.map(d => { return { text: d.name, value: d._id } });
                  this.dropdownData = await this.btHelpers.getImportNames(100);  //V240311
                  // alert('data (dropdown with isImport)=' + JSON.stringify(this.dropdownData));
               } else {
                  const data = await this.btHelpers.getDropdownData(
                     this.stdFieldValues['importId'][0].value,
                     this.chart.filter,
                     this.distinct
                  );
                  // alert('data (dropdown w/out isImport)=' + JSON.stringify(data));

                  if (data.length) {
                     const keys = Object.keys(data[0]._id);
                     // _alert('keys=' + JSON.stringify(keys));
                     if (keys[0] === 'Import') {
                        const importData = await this.btHelpers.getImportNames();
                        // _alert('importData=' + JSON.stringify(importData));
                        let imprt;
                        data.forEach(d => {
                           imprt = importData.find(i => i.value == d._id.Import);
                           this.dropdownData.push({
                              text: imprt ? imprt.text : d._id.Import,
                              value: d._id.Import
                           });
                        });
                     } else {
                        data.forEach(d => {
                           this.dropdownData.push({
                              text: d._id[keys[0]],
                              value: d._id[keys[0]]
                           });
                        });
                     }
                  }
               }
               // _alert('dropdownData=' + JSON.stringify(this.dropdownData));
            } else if (this.isDatePicker) {
               this.datePickerTypeChanged(this.chart.datePickerType);
            } else if (this.isChart) {
               this.loadingChartData = true;
               // await sleep(5000);
               if (this.hasPaginaTable) {
                  const data = await this.btHelpers.getData(
                     this.stdFieldValues['importId'][0].value,
                     this.chart.filter,
                     undefined,
                     undefined,
                     this.distinct
                  );

                  /* V240711 - start */
                  if (data?.length) {
                     const idKeys = Object.keys(data[0]._id);
                     const otherKey = Object.keys(data[0])[1];
                     if (idKeys.length === 3) {
                        const yearIndex = idKeys.findIndex(k => k === 'year');
                        if (yearIndex === 0) {
                           data.forEach(d => {
                              const id = { "year-month": d._id.year + '-' + d._id.month };
                              id[idKeys[2]] = d._id[idKeys[2]];
                              const row = { _id: id };
                              row[otherKey] = d[otherKey];
                              this.myData.push(row);
                           });
                        } else if (yearIndex === 1) {
                           data.forEach(d => {
                              const id = {};
                              id[idKeys[0]] = d._id[idKeys[0]];
                              id["year-month"] = d._id.year + '-' + d._id.month;
                              const row = { _id: id };
                              row[otherKey] = d[otherKey];
                              this.myData.push(row);
                           });
                        } else {
                           const monthIndex = idKeys.findIndex(k => k === 'month');
                           if (monthIndex === 0) {
                              data.forEach(d => {
                                 const id = { "month-dayOfMonth": d._id.month + '-' + d._id.dayOfMonth };
                                 id[idKeys[2]] = d._id[idKeys[2]];
                                 const row = { _id: id };
                                 row[otherKey] = d[otherKey];
                                 this.myData.push(row);
                              });
                           } else if (monthIndex === 1) {
                              data.forEach(d => {
                                 const id = {};
                                 id[idKeys[0]] = d._id[idKeys[0]];
                                 id["month-dayOfMonth"] = d._id.month + '-' + d._id.dayOfMonth; 
                                 const row = { _id: id };
                                 row[otherKey] = d[otherKey];
                                 this.myData.push(row);
                              });
                           }
                        }
                     } else {
                        this.myData = data;
                     }
                  }
                  /* V240711 - end */
               }
               else
                  this.myData = [...this.data];

               // alert('in BtChart.init(): myData=' + JSON.stringify(this.myData));

               if (this.fields.length
                  && this.chart.filter.standard
                  && this.chart.filter.standard.length > 1
                  && this.myData.length
                  && typeof this.myData[0]._id === 'object'
               ) {
                  this.prepareChartData();
               } else
                  this.chartData = [];

               this.loadingChartData = false;
            }

            this.isFilterChanged = false;
            this.isFilter2Changed = false;
         }
      },

      //V240103.1
      async getDataCount(filter) {
         let count;
         const firstImport = this.stdFieldValues['importId'][0];
         const $match = filter.standard.find(f => f.$match).$match;
         const matchKeysLen = Object.keys($match).length;

         if (matchKeysLen === 0)
            count = firstImport.availableRecords;
         else if (matchKeysLen === 1 && $match.importId) {
               count = 0;
               $match.importId.$in.forEach(id => {
                  count += this.stdFieldValues.importId.find(imprt => imprt.value === id).availableRecords;
               });
         } else
            count = await this.btHelpers.getDataCount(firstImport.value, filter);

         return count;
      },

      async getTableItems(page, itemsPerPage) {
         this.log(`in getTableItems(): page=${page}, itemsPerPage=${itemsPerPage}`);
         this.loadingTableData = true;
         // await sleep(3000);
         if (this.isActualEndpoint) {
            this.myData = await this.btHelpers.getData(
               this.stdFieldValues['importId'][0].value,
               this.chart.filter,
               page,
               itemsPerPage
            );
         } else {
            this.myData = this.getSampleDataForPaginaTable(page, itemsPerPage);
         }

         if (this.myData.length) {
            this.myData.forEach(item => {
               for (const key in item) {
                  const val = item[key].toString().toLowerCase();
                  if (val === 'true' || val === 'false')
                     item[key] = item[key].toString();
               }
            });

            if (this.isTableHeadersNeeded) {
               this.tableHeaders = this.fields.filter(header => isIncluded(this.selectedHeaders, header.value, true));
               this.addActionsHeader();
               this.isTableHeadersNeeded = false;
               // _alert('in getTableItems(): tableHeaders=' + JSON.stringify(this.tableHeaders));
            }

            if (this.chart.filter.standard.length > 1) {
               this.itemKey = 'index';
               this.myData.forEach((item, i) => {
                  item.index = i;
               });
            } else {
               this.itemKey = '_id';
            }
         }
         this.loadingTableData = false;
         this.log(`in getTableItems(): itemKey=${this.itemKey}, myData=${JSON.stringify(this.myData)}`);
      },

      async prepareChartData() {
         const DEBUG = false;
         // this.log(`prepareChartData() started. Clear console!`, DEBUG);

         // // if (!this.isActualEndpoint) {
         // //    // if (this.chart.title === "line-chart") {
         // //       // this.getSampleFilter();
         // //       this.getSampleData();
         // //    // }
         // // }

         const $group = this.chart.filter.standard.find(f => f.$group);

         // V230518 - Started
         // const keyLengths = new Set();
         // this.myData.forEach(data => {
         //    keyLengths.add(Object.keys(data._id).length);
         // });

         // if (keyLengths.size === 2) {
         //    const ind1 = this.myData.findIndex(d => Object.keys(d._id).length === 1);
         //    const key1 = Object.keys(this.myData[ind1]._id)[0];
         //    const val1 = this.myData[ind1]._id[key1];
         //    const ind2 = this.myData.findIndex(d => Object.keys(d._id).length === 2);
         //    const keys2 = Object.keys(this.myData[ind2]._id);
         //    // this.log(`myData[${ind1}]=${JSON.stringify(this.myData[ind1])}\nmyData[${ind2}]=${JSON.stringify(this.myData[ind2])}\nkeys1=${JSON.stringify(key1)}\nkeys2=${JSON.stringify(keys2)}`, DEBUG);
         //    if (key1 === keys2[0]) {
         //       this.myData[ind1]._id[keys2[1]] = "Not Existed";
         //    } else {
         //       this.myData[ind1]._id = {};
         //       this.myData[ind1]._id[keys2[0]] = "Not Existed";
         //       this.myData[ind1]._id[key1] = val1;
         //    }
         // }
         // V230518 - Ended

         //V230602
         /* myData will be changed in the function */
         this.inject2ndDimension(this.myData);

         // this.log(`modified myData=${JSON.stringify(this.myData)}`, DEBUG);
         // alert(`modified myData 1=${JSON.stringify(this.myData)}`, DEBUG);

         if ($group) {
            // let edField;
            // const groupbyIdKeys = Object.keys($group.$group._id);
            // // alert('groupbyIdKeys='+JSON.stringify(groupbyIdKeys));
            // // alert('eventDataFields='+JSON.stringify(this.eventDataFields));
            // for (let index = 0; index < groupbyIdKeys.length; index++) {
            //    edField = this.eventDataFields.find(f => f.text === groupbyIdKeys[index]);
            //    // alert('edField=' + JSON.stringify(edField))
            //    if (edField) {
            //       const idList = [];
            //       this.myData.forEach(data => {
            //          const val = data._id[edField.text];
            //          // alert('val='+val)
            //          if (val != "Not Existed" && !idList.includes(val)) {
            //             idList.push(val);
            //          }
            //       });

            //       await this.getEventDataNames(edField.value, idList);
            //       if (this.eventDataNames.length) {
            //          this.myData.forEach(data => {
            //             const val = data._id[edField.text];
            //             if (val != "Not Existed") {
            //                const edName = this.eventDataNames.find(ed => ed.value == val);
            //                if (edName)
            //                   data._id[edField.text] = edName.text;
            //             }
            //          });
            //          // alert(`modified myData 2=${JSON.stringify(this.myData)}`, DEBUG);
            //       }
            //    }
            // }

            /* myData will be updated in the function */
            // alert('myData BEFORE setEventDataNames:' + JSON.stringify(this.myData))
            await this.setEventDataNames($group.$group._id, this.myData);
            // alert('myData AFTER setEventDataNames:' + JSON.stringify(this.myData))
         }

         const chartData = [];
         if (!this.lastChartType)
            this.lastChartType = this.chart.type;

         const idKeys = [];
         Object.keys(this.myData[0]._id).forEach(key => {
            if (key != 'isRootInsert' && key != 'elm')
               idKeys.push(key);
         });

         const dataKey = Object.keys(this.myData[0])[1];
         // B0.4:
         // let newIdKey = this.chart.filter.standard[1].$group._id[idKeys[0]]['$' + idKeys[0]];
         // B0.8-start:
         // let newIdKey = this.chart.filter.standard[1].$group._id[idKeys[0]];

         let newIdKey = this.chart.filter.standard.find(f => f.$group).$group._id[idKeys[0]];

         if (typeof newIdKey === 'object')
            newIdKey = newIdKey['$' + idKeys[0]];

         newIdKey = newIdKey ? newIdKey.replace('$events.', '').replace('$', '') : '';

         // this.log(`newIdKey=${newIdKey}\n${JSON.stringify(this.behFieldsForGroupby)}`, DEBUG);

         let newIdField = this.fields.find(f => f.value === newIdKey);
         if (!newIdField)
            newIdField = this.behFieldsForGroupby.find(f => f.value === newIdKey);
         // this.log(`idKeys[0]=${idKeys[0]}\nnewIdField.text=${newIdField.text}`, DEBUG);
         // this.log(`newIdKey=${newIdKey}, newIdField=${JSON.stringify(newIdField)}`, DEBUG);
         // B0.8-end

         let idFieldPostfix = ' / ';
         let valueNames;
         let convertToString = false;
         switch (idKeys[0]) {
            case 'year':
               idFieldPostfix += 'Yearly';
               convertToString = true;
               break;
            case 'month':
               idFieldPostfix += 'Monthly';
               valueNames = MONTHS;
               break;
            case 'dayOfMonth':
               idFieldPostfix += 'Daily';
               convertToString = true;
               break;
            case 'hour':
               idFieldPostfix += 'Hourly';
               convertToString = true;
               break;
            case 'dayOfWeek':
               idFieldPostfix += 'Day of the Week';
               valueNames = DAYS;
               break;
            default:
               idFieldPostfix = '';
               break;
         }

         if (idKeys.length === 1) {
            if (idFieldPostfix)
               chartData.push([newIdField.text + idFieldPostfix].concat(dataKey));
            else
               chartData.push(idKeys.concat(dataKey));

            this.myData.forEach(d => {
               let chartDataItem;
               if (valueNames)
                  chartDataItem = valueNames[[d._id[idKeys[0]]]];
               else
                  chartDataItem = d._id[idKeys[0]];

               chartData.push([convertToString ? chartDataItem + '' : chartDataItem, d[dataKey]]);
            });
         } else if (idKeys.length === 2) {
            const uniqueItems1 = [...new Set( this.myData.map(obj => obj._id[idKeys[0]])) ];
            const uniqueItems2 = [...new Set( this.myData.map(obj => obj._id[idKeys[1]])) ];
            chartData.push([idFieldPostfix ? newIdField.text + idFieldPostfix : idKeys[0], ...uniqueItems2]);
            uniqueItems1.forEach(item1 => {
               const values = [];
               uniqueItems2.forEach(item2 => {
                  const match = this.myData.find(d => d._id[idKeys[0]]===item1 && d._id[idKeys[1]]===item2);
                  values.push(match ? match[dataKey] : 0);
               });

               const chartDataItem = valueNames ? valueNames[item1] : item1;
               chartData.push([convertToString ? chartDataItem + '' : chartDataItem, ...values]);
            });
         }

         //B0.24: replacing import IDs with their names
         // alert('chartData with import ids: ' + JSON.stringify(chartData));
         if ($group) {
            if ($group.$group._id['Import'] === '$importId') {
               this.imports = [];
               let importData;
               if (chartData[0][0] === 'Import') {
                  for (let i = 1; i < chartData.length; i++) {
                     importData = await this.btHelpers.getImport(chartData[i][0]);
                     if (importData) {
                        this.imports.push({ id: chartData[i][0], name: importData.name });
                        chartData[i][0] = importData.name;
                     }
                  }
               } else {
                  for (let i = 1; i < chartData[0].length; i++) {
                     importData = await this.btHelpers.getImport(chartData[0][i]);
                     if (importData) {
                        this.imports.push({ id: chartData[0][i], name: importData.name });
                        chartData[0][i] = importData.name;
                     }
                  }
               }
            }
         }

         // _alert('chartData with import names: ' + JSON.stringify(chartData));

         //making the 1st row and the 1st column of other rows, string.
         for (let index = 0; index < chartData.length; index++) {
            if(index === 0) {
               for (let index_i = 0; index_i < chartData[index].length; index_i++) {
                  chartData[0][index_i] = chartData[0][index_i].toString();
               }
            } else {
               chartData[index][0] = chartData[index][0] === null ? '' : chartData[index][0].toString();
            }
         }

         //V240425
         if (this.chartsSettings && this.chartsSettings.labels) {
            //TODO: Use the following if statement: if (chartData[0][0] === 'Event Code'). Then you need to bring the 2nd for up.
            let labelsChanged = false;
            for (let i = 1; i < chartData[0].length; i++) {
               const lbl = this.chartsSettings.labels.find(l => l.id == chartData[0][i]);
               if (lbl) {
                  chartData[0][i] = lbl.label;
                  labelsChanged = true;
               }
            }

            if (!labelsChanged) {
               for (let i = 1; i < chartData.length; i++) {
                  const lbl = this.chartsSettings.labels.find(l => l.id == chartData[i][0]);
                  if (lbl)
                     chartData[i][0] = lbl.label;
               }
            }
         }

         this.chartData = [...chartData];
         this.log('in init/end: chart=' + JSON.stringify(this.chart) +
            '\n\nchartData=' + JSON.stringify(this.chartData), DEBUG);
      },

      //V230601
      /* data will be updated in the function */
      async setEventDataNames($groupId, data) {
         let edField;
         const groupbyIdKeys = Object.keys($groupId);
         // alert('groupbyIdKeys='+JSON.stringify(groupbyIdKeys));
         // alert('eventDataFields='+JSON.stringify(this.eventDataFields));
         for (let index = 0; index < groupbyIdKeys.length; index++) {
            edField = this.eventDataFields.find(f => f.text === groupbyIdKeys[index]);
            // alert('edField=' + JSON.stringify(edField))
            if (edField) {
               const idList = [];
               data.forEach(data => {
                  const val = data._id[edField.text];
                  // alert('val='+val)
                  if (val != NOT_EXISTED && !idList.includes(val)) {
                     idList.push(val);
                  }
               });

               await this.getEventDataNames(edField.value, idList);
               if (this.eventDataNames.length) {
                  data.forEach(d => {
                     const val = d._id[edField.text];
                     if (val != NOT_EXISTED) {
                        const edName = this.eventDataNames.find(ed => ed.value == val);
                        if (edName)
                           d._id[edField.text] = edName.text;
                     }
                  });
                  // alert(`modified myData 2=${JSON.stringify(this.myData)}`, DEBUG);
               }
            }
         }
      },

      async getEventDataNames(eventData, idList) {
         let refreshNames = eventData != this.lastEventData;
         if (!refreshNames) {
            idList.forEach(id => {
               if (!this.lastIdList.includes(id)) {
                  refreshNames = true;
                  return;
               }
            });
         }

         if (refreshNames) {
            let result = await this.apiService.getEventDataNames(
               eventData === '__inboundChild' ? 'inboundDetail' : eventData.replace('__', ''),
               idList.join(',')
            );

            if (result.logout)
               this.logout();
            else if (result.data && result.data.length)
               this.eventDataNames = [...result.data];
            else
               this.eventDataNames = [];

            this.lastEventData = eventData;
            this.lastIdList = [...idList]
         }
      },

      switchBetweenChartAndTable() {
         if (this.isTable)
            this.chart.type = this.lastChartType;
         else {
            this.lastChartType = this.chart.type;
            this.chart.type = 'Table';
         }
      },

      async tableItemExpanded(items) {
         // _alert('in tableItemExpanded(): items=' + JSON.stringify(items));
         // _alert('in tableItemExpanded(): expanded=' + JSON.stringify(this.expanded));
         if (items.length === 0 || items[0].details) return;

         this.loadingTableData = true;
         // await sleep(2000);
         const item = items[0];
         // let itemDetails = await this.btHelpers.getItem(item._id);
         item.contactData = await this.btHelpers.getItem(item._id);
         if (item.contactData) {
            item.details = [[], [], []];
            let seq= 0;
            for (const key in item.contactData) {
               item.details[seq++].push({ key: key, value: item.contactData[key] });
               if (seq === 3) seq = 0;
            }
         }
         this.loadingTableData = false;
         // this.log('in tableItemExpanded(): item=' + JSON.stringify(item?.details));
      },

      configureChart() {
         this.dialog = true;
         this.chartConfig = new Chart(this.chart);
         // this.tempChartType = this.chartConfig.type;
         // if (this.chartConfig.type === 'PieChart' && this.chartConfig.options.hasOwnProperty('pieHole')) {
         if (this.chartConfig.type === 'PieChart' && hasOwn(this.chartConfig.options, 'pieHole')) {
            this.resetChartConfigTypes('DonutChart', true, true);
         } else
            this.resetChartConfigTypes(this.chartConfig.type, true, false);

         if (this.isKpi && this.chartConfig.gaugeColors.length) {
            const color1 = this.chartConfig.gaugeColors[0];
            this.chartColors = this.chartColorsItems.find(item => item.value.indexOf(color1) === 0).value;
         } else if ('colors' in this.chartConfig.options)
            this.chartColors = this.chartConfig.options.colors.join(',');
         else if ('colorAxis' in this.chartConfig.options && 'colors' in this.chartConfig.options.colorAxis)
            this.chartColors = this.chartConfig.options.colorAxis.colors.join(',');
         else
            this.chartColors = this.chartColorsItems[1].value;

         this.chartCalculatedColumns = this.chartConfig.calculatedColumns ? JSON.stringify(this.chartConfig.calculatedColumns) : '';

         this.chartOptions = JSON.stringify(this.chart.options);
         this.chartFilter = JSON.stringify(this.chart.filter);

         if (this.chartConfig.type === 'CalculatedTable')
            this.chartFilter2 = this.chartConfig.hasFilter2 ? JSON.stringify(this.chart.filter2) : '';

         this.paginaTableSettings = this.chartConfig.paginaTableSettings ? JSON.stringify(this.chartConfig.paginaTableSettings) : '';

         this.configDropdown(this.chart.filter);

         // if (this.$refs['formDatePicker']) {
         //    const self = this;
         //    setTimeout(function() {
         //       self.$refs.formDatePicker.resetValidation();
         //    }, 1);
         // }
      },

      async chartTypeChanged(newChartType) {
         // _alert('in chartTypeChanged(): newChartType=' + newChartType);
         if (!this.chartConfig.type) {
            this.processChartTypeChange(newChartType);
            // if (this.isPaginaTable)
            //    this.chartConfig.filter.columns = [...this.preselectedFields];
            this.filterChanged(this.chartConfig.filter);
         } else if (this.chartConfig.type.indexOf('Chart') === -1 || newChartType.indexOf('Chart') === -1) {
            if (confirm(`This change will reset your filter. Would you like to continue?`)) {
               this.processChartTypeChange(newChartType);

               // _alert(this.chartFilter);
               this.chartConfig.filter = JSON.parse(JSON.stringify(EMPTY_FILTER));
               // if (this.isPaginaTable)
               //    this.chartConfig.filter.columns = [...this.preselectedFields];
               this.filterChanged(this.chartConfig.filter);
               // _alert(this.chartFilter);

               /* V240627.1: start */
               this.chartConfig.filter2 = JSON.parse(JSON.stringify(EMPTY_FILTER));
               this.filter2Changed(this.chartConfig.filter2);
               /* V240627.1: end */

               // await sleep (100);
            } else
               this.resetChartConfigTypes(this.chartConfig.type, true, false);
         } else
            this.resetChartConfigTypes(newChartType, false, true);

         // _alert(JSON.stringify(this.chartConfig));
         if (this.isChart && this.chartConfig.type != this.chartConfig.tempType) {
            const options = {
               height: "250",
               legend: {
                  position: "top"
               }
            };
            if (this.chartConfig.tempType != "GeoChart") {
               options.chartArea = {
                  left: "70",
                  right: "20"
               };
               if (this.chartConfig.tempType === "DonutChart")
                  options.pieHole = "0.3";
            }
            this.chartOptions = JSON.stringify(options);
         }

         const self = this;
         setTimeout(() => {
            if (self.isDatePicker)
               self.$refs.chartDatePickerType.focus();
            else
               self.$refs.chartTitle.focus();
         }, 100);
      },

      isImportChanged(val) {
         if (val && !confirm(`This change will reset your filter. Would you like to continue?`)) {
            this.$nextTick(() => {
               this.chartConfig.isImport = false;
            });
         }
      },

      processChartTypeChange(newChartType) {
         this.resetChartConfigTypes(newChartType, false, true);
         // this.errMsg = '';
         this.isCalculatedTable = false;
         this.isPaginaTable = false;
         this.isKpi = false;
         this.isDropdown = false;
         this.isDatePicker = false;
         this.isChart = false;
         this.groupbyFieldHasEventData = false;
         switch (newChartType) {
            case '':
               alert('Error in processChartTypeChange(): Empty newChartType!!!');
               break;
            case 'CalculatedTable':
               this.isCalculatedTable = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'columns');
               // this.groupbyOperators = ['distinct'];
               this.groupbyOperators = FILTER_GROUPBY_OPERATORS.filter(o => o != 'distinct');
               this.showGroupbyId2 = true;
               // for the 2nd filter
               this.includedTabs2 = FILTER_TABS.filter(t => t != 'columns' && t != 'predefined');
               break;
            case 'PaginaTable':
               this.isPaginaTable = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'groupby' && t != 'predefined');
               // if (this.isPaginaTable)
               //    this.chartConfig.filter.columns = [...this.preselectedFields];
               break;
            case 'KPI':
               this.isKpi = true;
               // this.includedTabs = FILTER_TABS.filter(t => t != 'groupby' && t != 'columns' && t != 'predefined');
               this.includedTabs = this.getKpiIncludedTabs(this.chart.templateName);
               this.groupbyOperators = FILTER_GROUPBY_OPERATORS.filter(o => o != 'distinct');
               this.showGroupbyId2 = false;
               break;
            case 'Dropdown':
               this.isDropdown = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'columns');
               this.groupbyOperators = ['distinct'];
               this.showGroupbyId2 = false;
               break;
            case 'DatePicker':
               this.isDatePicker = true;
               break;
            default:
               this.isChart = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'columns');
               this.groupbyOperators = FILTER_GROUPBY_OPERATORS.filter(o => o != 'distinct');
               this.showGroupbyId2 = true;
               break;
         }
      },

      resetChartConfigTypes(newChartType, resetTempType, resetType) {
         this.$nextTick(() => {
            if (resetTempType)
               this.chartConfig.tempType = newChartType;
            if (resetType)
               this.chartConfig.type = newChartType;
         });
      },

      filterChanged(val) {
         this.log('in filterChanged(): val=' + JSON.stringify(val));
         this.isFilterChanged = true;
         this.errMsg = '';

         if (this.isPaginaTable) {
            if (val.columns.length === 0)
               val.columns = [...this.preselectedFields];
         } else
            val.columns = [];

         this.configDropdown(val);

         this.chartFilter = JSON.stringify(val);

         // // this.setInitialFilter();

         // _alert('in filterChanged(): isPaginaTable=' + this.isPaginaTable +
         //    ', isKpi=' + this.isKpi +
         //    ', isDropdown=' + this.isDropdown +
         //    ', isChart=' + this.isChart +
         //    '\nchartFilter=' + this.chartFilter);
      },

      configDropdown(filter) {
         if (this.isDropdown) {
            const $groupObj = filter.standard.find(f => f.$group);
            if ($groupObj && $groupObj.$group._id.Import === '$importId') {
               this.chartConfig.autoSelectFirstItem = true;
               this.chartConfig.multiSelect = false;
               this.autoSelectFirstItemDisabled = true;
            } else
               this.autoSelectFirstItemDisabled = false;
         }
      },

      filter2Changed(val) {
         this.log('in filter2Changed(): val=' + JSON.stringify(val));
         this.isFilter2Changed = true;
         this.chartFilter2 = JSON.stringify(val);
         this.errMsg = '';
      },

      datePickerTypeChanged(val) {
         if (val === 'month') {
            //V240701: Added Last 90/180/365 Days, Current/Last Quarter/Year
            this.datePickerSelectItems = [
               { text: "Last 7 Days", value: "6" },
               { text: "Last 14 Days", value: "13" },
               { text: "Last 30 Days", value: "29" },
               { text: "Last 90 Days", value: "89" },
               { text: "Last 180 Days", value: "179" },
               { text: "Last 365 Days", value: "364" },
               { text: "Current Month", value: "TM" },
               // { text: "Current Quarter", value: "TQ" },
               // { text: "Current Year", value: "TY" },
               { text: "Last Month", value: "LM" },
               // { text: "Last Quarter", value: "LQ" },
               // { text: "Last Year", value: "LY" }
            ];
            //V240628: this.datePickerSelectItems = this.datepickerMonthlyItems;
            this.datePickerDate = '';
         } else {
            this.datePickerSelectItems = [
               { text: "Today", value: "T" },
               { text: "Yesterday", value: "Y" }
            ];
            this.datePickerRange = [];
            this.chartConfig.maxRange = 0;
         }

         this.datePickerSelectLabel = `Preselected ${val.substr(0, 1).toUpperCase()}${val.substr(1)}`;
         this.chartConfig.defaultId = '';
         // if (this.$refs['formDatePicker'])
         //    this.$refs.formDatePicker.resetValidation();
      },

      hasFilter2Changed(val) {
         // alert('in hasFilter2Changed(): ' + this.chartFilter2);

         if (val) {
            if (!this.chartFilter2) {
               if (this.chartConfig.filter2)
                  this.chartFilter2 = JSON.stringify(this.chartConfig.filter2);
               else
                  this.chartConfig.filter2 = JSON.parse(JSON.stringify(EMPTY_FILTER));
            }

            // // this.setInitialFilter();
         }
      },

      saveChartConfig() {
         if (this.$refs.form.validate()) {
            // _alert('in saveChartConfig(): current chartFilter:\n' + this.chartFilter+'\n\nnew filter:\n'+JSON.stringify(this.chartConfig.filter));
            // if (this.chartFilter != JSON.stringify(this.chartConfig.filter)) {
            //    _alert('filter in chart changed');
            //    this.chartConfig.filter = _jsonParse(this.chartFilter);
            //    this.$emit('filter-change', this.chartConfig.filter);
            // }

            let filter;
            if (!this.isDatePicker && !this.chartConfig.isImport) {
               filter = _jsonParse(this.chartFilter);
               this.errMsg = this.validateFilter(filter, 'Filter');
            }

            let filter2, calculatedColumns;
            if (this.isKpi && this.chartConfig.templateName === KPI_TEMPLATE_NAMES.ratio) {
               filter2 = _jsonParse(this.chartFilter2);
               this.errMsg += this.validateFilter(filter2, 'Filter 2');
            } else if (this.isCalculatedTable) {
               if (this.chartConfig.hasFilter2) {
                  filter2 = _jsonParse(this.chartFilter2);
                  this.errMsg += this.validateFilter(filter2, 'Filter 2');
                  if (!this.errMsg) {
                     const id = Object.keys(filter.standard.find(f => f.$group).$group._id)[0];
                     const id2 = Object.keys(filter2.standard.find(f => f.$group).$group._id)[0];
                     if (id != id2)
                        this.errMsg = `Groupby ID of Filter 2 should be '${id}' not '${id2}'!`
                  }
               } else {
                  filter2 = '';
               }

               calculatedColumns = _jsonParse(this.chartCalculatedColumns);
               if (calculatedColumns.length === 0)
                  this.errMsg += "'Calculated Columns' cannot be empty! ";
            }

            if (this.errMsg)
               return;

            this.chartConfig.filter = filter;
            this.chartConfig.filter2 = filter2;
            this.chartConfig.options = _jsonParse(this.chartOptions);
            this.chart = new Chart(this.chartConfig);
            if (this.isDropdown) {
               if (this.chart.isImport) {
                  this.chart.multipleSelect = false;
                  this.chart.filter = this.btHelpers.getInitialFilter(['name', '_id']);
               } else {
                  // V2301xx.1
                  // this.chart.multipleSelect = this.chart.multipleSelect;
                  // this.chart.filter = this.chart.filter;
               }
            } else if (this.isKpi && this.chartConfig.templateName === KPI_TEMPLATE_NAMES.ratio) {
               const colors = this.chartColors.split(',');
               this.chart.gaugeColors = [colors[0], colors[1], colors[2]];
            } else if (this.chart.type === 'GeoChart') {
               // if (!this.chart.options.hasOwnProperty('colorAxis'))
               if (!hasOwn(this.chart.options, 'colorAxis'))
                  this.chart.options.colorAxis = {}
               this.chart.options.colorAxis.colors = this.chartColors.split(',');
            } else if (this.chart.type === 'CalculatedTable') {
               this.chart.calculatedColumns = calculatedColumns;
            } else if (this.chart.type != 'PaginaTable'){
               this.chart.options.colors = this.chartColors.split(',');
               const hasPieHole = hasOwn(this.chart.options, 'pieHole');
               if (this.chart.type === 'DonutChart') {
                  this.chart.type = 'PieChart';
                  if (!hasPieHole)
                     this.chart.options.pieHole = 0.3;
               } else if (hasPieHole)
                  delete this.chart.options.pieHole;
            }

            if (this.chart.type === 'PaginaTable')
               this.chart.paginaTableSettings = _jsonParse(this.paginaTableSettings);

            this.log('in saveChartConfig(): chart=' + JSON.stringify(this.chart));

            //TODO: instead of passing this.chart, create a new object with relevant properties
            // const outObj = this.buildOutputChart();   //B0.21
            this.$emit('input', this.chart);
            this.cancelChartConfig();
            if (!this.isDatePicker && this.isFilterChanged)
               this.$emit('filter-change', this.chartConfig.filter);

            // if (this.isFilterChanged || this.isFilter2Changed) {
               // this.isFilterChanged = false;
               // this.isFilter2Changed = false;
               // // this.init();
            // }
            // this.log('in saveChartConfig(): chart-new=' + JSON.stringify(outObj));
         }
      },

      // validateFilter(filter, fldName) {
      //    let errMsg = '';
      //    let hasGroup = false;
      //    let keyLen = 0;
      //    if (filter.standard) {
      //       filter.standard.forEach(f => {
      //          if (f.$group) {
      //             hasGroup = true;
      //             keyLen = Object.keys(f.$group).length;
      //             return;
      //          }
      //       });
      //    }

      //    if (this.isChart || this.isDropdown) {
      //       if (!hasGroup)
      //          errMsg = `'${fldName}' should have GroupBy! `;
      //       else if (this.isChart) {
      //          if (keyLen === 1)
      //             errMsg = `'${fldName}' cannot use distinct operator in the GroupBy! `;
      //       } else if (keyLen === 2)
      //          errMsg = `'${fldName}' can only use distinct operator in the GroupBy! `;
      //    } else if (hasGroup)
      //          errMsg = `'${fldName}' cannot have GroupBy!`;

      //    return errMsg;
      // },

      validateFilter(filter, fldName) {
         // alert(JSON.stringify(filter))
         let errMsg = '';

         if (this.isChart || this.isDropdown || this.isCalculatedTable) {
            let hasGroup = false;
            if (filter.standard) {
               filter.standard.forEach(f => {
                  if (f.$group) {
                     hasGroup = true;
                     return;
                  }
               });
            }
            if (!hasGroup) {
               errMsg = `'${fldName}' should have GroupBy! `;
            }
         } else if (this.isPaginaTable && filter.columns.length === 0)
            errMsg = `'${fldName}' should have some columns!`;

         return errMsg;
      },

      //TODO: Not in use
      buildOutputChart() {
         const outChart = {
            type: this.chart.type,
            // title: this.chart.title,
            // icon: this.chart.icon
         }

         if (this.isChart) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.options = this.chart.options;
            outChart.filter = this.chart.filter;
         } else if (this.isPaginaTable) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.filter = this.chart.filter;
         } else if (this.isCalculatedTable) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.filter = this.chart.filter;
            outChart.calculatedColumns = this.chart.calculatedColumns;
         } else if (this.isKpi) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.gaugeColors = this.chart.gaugeColors;
            outChart.gaugeMaxValue = this.chart.gaugeMaxValue
            // outChart.formatNumbers = this.chart.formatNumbers;
            outChart.filter = this.chart.filter;
            outChart.filter2 = this.chart.filter2;
         } else if (this.isDropdown) {
            outChart.isImport = this.chart.isImport;
            outChart.title = this.chart.title;
            outChart.autoSelectFirstItem = this.chart.autoSelectFirstItem;
            if (this.chart.isImport) {
               outChart.multipleSelect = false;
               outChart.filter = this.btHelpers.getInitialFilter(['name', '_id']);
            } else {
               outChart.multipleSelect = this.chart.multipleSelect;
               outChart.filter = this.chart.filter;
            }
         } else if (this.isDatePicker) {
            outChart.datePickerType = this.chart.datePickerType;
            outChart.defaultId = this.chart.defaultId;
            outChart.maxRange = this.chart.maxRange;
         }

         this.log('in buildOutputChart(): outChart=' + JSON.stringify(outChart));
         return outChart;
      },

      cancelChartConfig() {
         // _alert('in cancelChartConfig(): chartConfig=' + JSON.stringify(this.chartConfig));
         this.chartConfig = {};  //B0.21
         this.errMsg = '';
         this.dialog = false;
      },

      async eventsClicked(events) {
         // _alert('in eventsClicked(): events=' + JSON.stringify(events));
         this.dialogEvents = true;
         this.loadingEvents = true;
         // await sleep (5000);
         const headers = [];
         const mainHeaders = new Set();
         const subHeaders = new Set();

         events.forEach((event, i) => {
            Object.keys(event).forEach(key => {
               if (this.behFields.find(f => f.value != 'event_data' && f.value === key.toString()))
                  mainHeaders.add(key);
               else if (key.toString() != '__btChartExtras')
                  subHeaders.add(key);
            });
            event.__btChartExtras = { id: `${event.event_code}_${event.event_date}_${i}` };
         });

         // _alert('mainHeaders.size=' + mainHeaders.size + ', subHeaders.size=' + subHeaders.size);

         mainHeaders.forEach(h => {
            const behField = this.behFields.find(f => f.value === h);
            if (behField)
               headers.push({ text: behField.text, value: h, align: 'left', sortable: true });
         });

         this.events = [...events];
         this.eventHeaders = [...headers];
         this.eventDetailHeaders = Array.from(subHeaders);
         this.loadingEvents = false;
      },

      eventsExpanded(event) {
         // _alert('in eventsExpanded(): event=' + JSON.stringify(event));
         if (event.length === 0) return;

         const e = event[0];
         // e.details = [];
         e.__btChartExtras.details = [];
         this.eventDetailHeaders.forEach(h => {
            if (e[h])
               e.__btChartExtras.details.push({ key: h, value: e[h] });
         });
         // _alert('in eventsExpanded(): e=' + JSON.stringify(e));
      },

      closeDialogEvents() {
         this.expanded = [];
         this.dialogEvents = false;
      },

      dropdownSwitchChanged(val) {
         // this.$emit('dropdown-switch-change', val);
         this.dropdownSwitch = val;
         if (this.dropdownSelections.length)
            this.fireDropdownChange();
      },

      dropdownSelectionsChanged(arrVal) {
         // _alert('in dropdownSelectionsChanged(): arrVal=' + JSON.stringify(arrVal));
         this.dropdownSelections = [...arrVal];
         this.fireDropdownChange();
         this.chart.lastDropdownSelections = this.dropdownSelections
         this.$emit('input', this.chart);
      },

      fireDropdownChange() {
         // _alert('in fireDropdownChange(): dropdownSelections=' + JSON.stringify(this.dropdownSelections));
         let key, ddSwitch;
         let jsonVal = {};
         // const id = this.chart.filter.standard.find(f => f.$group).$group._id;
         // jsonVal[id[Object.keys(id)[0]].replace('$', '')] =
         //    this.dropdownSelections.length === 1 && this.dropdownSelections[0] == null ? [] : this.dropdownSelections;
         // jsonVal['$dropdownSwitch'] = this.dropdownSwitch;
         if (this.chart.isImport) {
            key = 'importId';
            ddSwitch = false;
         } else {
            const id = this.chart.filter.standard.find(f => f.$group).$group._id;
            key = id[Object.keys(id)[0]].replace('$', '');
            ddSwitch = this.dropdownSwitch;
         }

         jsonVal[key] = this.dropdownSelections.length === 1 && this.dropdownSelections[0] == null ? [] : this.dropdownSelections;
         jsonVal['$dropdownSwitch'] = ddSwitch;
         this.$emit('dropdown-change', jsonVal);
      },

      datePickerChanged(val) {
         this.$emit('datepicker-change', val);
      },

      calculatedTableCellClicked(clickData) {
         // alert('in calculatedTableCellClicked(): clickData in=' + JSON.stringify(clickData));
         if (clickData) {
            // if (val.importId)
            //    val.importId = [this.imports.find(imprt => imprt.name === val.importId).id];

            //V230601
            Object.keys(clickData).forEach(key => {
               // alert('in select(): key=' + key + ', val=' + selectData[key]);
               if (key === 'importId')
                  clickData[key] = [this.imports.find(imprt => imprt.name === clickData[key]).id];
               else if (this.eventDataFields.find(f => f.value === key.replace('event_data.', ''))) {
                  const val = Array.isArray(clickData[key]) ? clickData[key][0] : clickData[key];
                  const ed = this.eventDataNames.find(ed => ed.text === val);
                  if (ed)
                     clickData[key] = Number(ed.value);
               }
            });

            this.$emit('chart-click', clickData);
         } else
            this.$emit('chart-reclick', clickData);

         console.log('in calculatedTableCellClicked(): clickData in=' + JSON.stringify(clickData));
      },

      getSampleFilter() {
         //working: event_code
         this.chart.filter = {"standard":[{"$match":{"events":{"$elemMatch":{"app_code":{"$in":["305"]},"event_date":{"$gte":"2024-04-17T00:00:00.000Z","$lt":"2024-04-30T23:59:59.999Z"}}}}},{"$project":{"importId":1,"event_code":1,"events":{"$filter":{"input":"$events","as":"event","cond":{"$and":[{"$in":["$$event.app_code",["305"]]},{"$gte":["$$event.event_date","2024-04-17T00:00:00.000Z"]},{"$lt":["$$event.event_date","2024-04-30T23:59:59.999Z"]}]}}}}},{"$unwind":"$events"},{"$group":{"_id":{"Import":"$importId","Event Code":"$events.event_code"},"Count":{"$sum":1}}},{"$sort":{"Count":-1}},{"$limit":50}],"columns":[]};

         //not working: event_data
         // this.chart.filter = {
         //    "standard":[{"$match":{"events":{"$elemMatch":{"app_code":{"$in":["305"]},"event_date":{"$gte":"2024-04-17T00:00:00.000Z","$lte":"2024-04-30T23:59:59.999Z"}}}}},{"$project":{"importId":1,"event_data":1,"events":{"$filter":{"input":"$events","as":"event","cond":{"$and":[{"$in":["$$event.app_code",["305"]]},{"$gte":["$$event.event_date","2024-04-17T00:00:00.000Z"]},{"$lt":["$$event.event_date","2024-04-30T23:59:59.999Z"]}]}}}}},{"$unwind":"$events"},{"$group":{"_id":{"Import":"$importId","z_leadtype":"$events.event_data.z_leadtype"},"Count":{"$sum":1}}},{"$sort":{"Count":-1}},{"$limit":50}],
         //    "columns":[]
         // };
      },

      getSampleData() {
         // alert(this.chart.title)
         if (this.chart.title === "line-chart-EventData") {
         this.myData = [
            {
               "_id": {
                  "Import": "65dce9f231f3000a1cd3abb1"
               },
               "Count": 27984
            },
            {
               "_id": {
                  "Import": "65dce7d7cbb48af9c47442a8",
                  "z_leadtype": "InfoRequest"
               },
               "Count": 3853
            },
            {
               "_id": {
                  "Import": "65dcdc56d8b93d9ce067905d"
               },
               "Count": 3797
            },
            {
               "_id": {
                  "Import": "65dbc5b23183a3b67a9f1e09",
                  "z_leadtype": "Activation"
               },
               "Count": 1345
            },
            {
               "_id": {
                  "Import": "65dbc50a3183a3b67a9f1ddf"
               },
               "Count": 912
            }
         ];
         } else {
            //working: event_code
            this.myData = [
               {
                  "_id": {
                        "Import": "65dce9f231f3000a1cd3abb1",
                        "Event Code": "30501"
                  },
                  "Count": 23272
               },
               {
                  "_id": {
                        "Import": "65dce7d7cbb48af9c47442a8",
                        "Event Code": "30500"
                  },
                  "Count": 12941
               },
               {
                  "_id": {
                        "Import": "65dcdc56d8b93d9ce067905d",
                        "Event Code": "30502"
                  },
                  "Count": 6656
               },
               {
                  "_id": {
                        "Import": "65dbc5b23183a3b67a9f1e09",
                        "Event Code": "30501"
                  },
                  "Count": 3032
               },
               {
                  "_id": {
                        "Import": "65dbc50a3183a3b67a9f1ddf",
                        "Event Code": "30500"
                  },
                  "Count": 2243
               }
            ];
         }

         // if (this.chart.title === "Products by payments" || this.chart.title === "Piechart for click")
         //    this.myData = this.chart.chartData;

         // this.myData = [
         //    { _id: { Product: 'Product1', Import: 1 }, Minimum: 105800 },
         //    { _id: { Product: 'Product1', Import: 2 }, Minimum: 97200 },
         //    { _id: { Product: 'Product1', Import: 3 }, Minimum: 280550 },
         //    { _id: { Product: 'Product1', Import: 4 }, Minimum: 544850 },
         //    { _id: { Product: 'Product2', Import: 5 }, Minimum: 75600 },
         //    { _id: { Product: 'Product2', Import: 6 }, Minimum: 21600 },
         //    { _id: { Product: 'Product3', Import: 1 }, Minimum: 7500 },
         //    { _id: { Product: 'Product3 ', Import: 1 }, Minimum: 7500 }

         //    // { _id: { Import: 1, Product: 'Product1' }, Minimum: 105800 },
         //    // { _id: { Import: 2, Product: 'Product2' }, Minimum: 97200 },
         //    // { _id: { Import: 3, Product: 'Product1' }, Minimum: 280550 },
         // ];
         // this.chart.filter = {
         //    "standard":[
         //       {"$match":{}},
         //       {"$group":
         //          {"_id":{
         //             "Product":"$product",
         //             "Import":"$importId"

         //             // "Import":"$importId",
         //             // "Product":"$product"
         //             },
         //             "Minimum":{"$sum":1}
         //          }
         //       },
         //       {"$sort":{"_id":1}},
         //       {"$limit":50}
         //    ],
         //    "columns":["transaction_date","product","price","payment_type","name","city","state"]
         // };

         //event_data without null record
         // this.myData = [
         //    {"_id":{"Import":"1","Event Code":"sent"},"Count":21},
         //    {"_id":{"Import":"1","Event Code":"30501"},"Count":7},
         //    {"_id":{"Import":"1","Event Code":"30504"},"Count":4},
         //    {"_id":{"Import":"1","Event Code":"30502"},"Count":4},
         //    {"_id":{"Import":"1","Event Code":"30500"},"Count":3},
         //    {"_id":{"Import":"1","Event Code":"30506"},"Count":2}
         // ];
         // this.chart.filter = {
         //    "standard":[
         //       {"$match":{}},
         //       {"$project":{"importId":1,"events":1}},
         //       {"$unwind":"$events"},
         //       {
         //          "$group":{
         //             "_id":{
         //                "Import":"$importId",
         //                "Event Code":"$events.event_code"
         //             },
         //             "Count":{"$sum":1}
         //          }
         //       },
         //       {"$sort":{"Count":-1}},
         //       {"$limit":50}
         //    ],
         //    "columns":[]
         // };

         //event_data with null record
         // this.myData = [
            // {"_id":{"Event Code":"sent"},"Count":22},
            // {"_id":{"Event Code":"30501","Program":9},"Count":7},
            // {"_id":{"Event Code":"30502","Program":9},"Count":4},
            // {"_id":{"Event Code":"30504","Program":9},"Count":4},
            // {"_id":{"Event Code":"30500","Program":9},"Count":3},
            // {"_id":{"Event Code":"30506","Program":9},"Count":2},
            // {"_id":{"Event Code":"30506","Program":10},"Count":12}
         //    // // {"_id":{"Event Code":"sent"},"Count":21},
         //    // // {"_id":{"Program":9,"Event Code":"30501"},"Count":7},
         //    // // {"_id":{"Program":9,"Event Code":"30502"},"Count":4},
         //    // // {"_id":{"Program":9,"Event Code":"30504"},"Count":4},
         //    // // {"_id":{"Program":10,"Event Code":"30500"},"Count":3},
         //    // // {"_id":{"Program":11,"Event Code":"30506"},"Count":2}
         //    // {"_id":{"Program":9,"Event Code":"30501"},"Count":7},
         //    // {"_id":{"Program":9,"Event Code":"30502"},"Count":4},
         //    // {"_id":{"Program":9,"Event Code":"30504"},"Count":4},
         //    // {"_id":{"Program":10,"Event Code":"30505"},"Count":3},
         //    // {"_id":{"Program":11,"Event Code":"30506"},"Count":2},
         //    // {"_id":{"Event Code":"sent"},"Count":21}
            // {"_id":{"Event Code":"sent"},"Count":22},
            // {"_id":{"Event Code":"30501","creditscore":9},"Count":7},
            // {"_id":{"Event Code":"30502","creditscore":9},"Count":4},
            // {"_id":{"Event Code":"30506","creditscore":10},"Count":12}
         // ];
         // this.chart.filter = {
         //    "standard":[
         //       // // {"$match":{}},
         //       // // {"$project":{"events":1}},
         //       // // {"$unwind":"$events"},
         //       // // {
         //       // //    "$group":{
         //       // //       "_id":{"Event Code":"$events.event_code","Program":"$events.event_data.__program"},
         //       // //       // "_id":{"Program":"$events.event_data.__program","Event Code":"$events.event_code"},
         //       // //       // "_id":{
         //       // //       //    "Event Code":"$events.event_code",
         //       // //       //    "creditscore":"$events.event_data.creditscore"
         //       // //       // },
         //       // //       "Count":{"$sum":1}
         //       // //    }
         //       // // },
         //       // // {"$sort":{"Count":-1}},
         //       // // {"$limit":50}
         //       {
         //          "$match": { }
         //       },
         //       {
         //          "$project": {    
         //             "range": {
         //                "$concat": [
         //                   { "$cond": [{"$lte": ["$mths_rem_ recent_auto_trade",0]}, "Unknown", ""]}, 
         //                   { "$cond": [{"$and":[ {"$gt":["$mths_rem recent_auto_trade", 0 ]},   {"$lt": ["$mths_rem recent_auto_trade", 42]}]}, "< 42", ""]},
         //                   { "$cond": [{"$and":[ {"$gte":["$mths_rem recent_auto_trade",42]}, {"$lt":["$mths_rem recent_auto_trade", 48]}]}, "42 - 48", ""]},
         //                   { "$cond": [{"$and":[ {"$gte":["$mths_rem recent_auto_trade",48]}, {"$lt":["$mths_rem recent_auto_trade", 54]}]}, "48 - 54", ""]},
         //                   { "$cond": [{"$and":[ {"$gte":["$mths_rem recent_auto_trade",54]}, {"$lt":["$mths_rem recent_auto_trade", 60]}]}, "54 - 60", ""]},
         //                   { "$cond": [{"$and":[ {"$gte":["$mths_rem recent_auto_trade",60]}, {"$lt":["$mths_rem recent_auto_trade", 66]}]}, "60 - 66", ""]},
         //                   { "$cond": [{"$and":[ {"$gte":["$mths_rem recent_auto_trade",66]}, {"$lt":["$mths_rem recent_auto_trade", 72]}]}, "66 - 72", ""]},
         //                   { "$cond": [{"$and":[ {"$gte":["$mths_rem recent_auto_trade",72]}, {"$lt":["$mths_rem recent_auto_trade", 78]}]}, "72 - 78", ""]},
         //                   { "$cond": [{"$and":[ {"$gte":["$mths_rem recent_auto_trade",78]}, {"$lt":["$mths_rem recent_auto_trade", 84]}]}, "78 - 84", ""]},
         //                   { "$cond": [{"$gte":["$mths_rem_ recent_auto_trade",84]}, "over 84", ""]}
         //                ]
         //             },
         //             "events": {
         //                "$filter": {
         //                   "input": "$events",
         //                   "as": "event",
         //                   "cond": {
         //                   "$and": [
         //                      {
         //                         "$in": [
         //                         "$$event.event_code",
         //                         [
         //                            "sent", "30501","30502"
         //                         ]
         //                         ]
         //                      }
         //                   ]
         //                   }
         //                }
         //             } 
         //          }    
         //       },
         //       {
         //          "$unwind": "$events"
         //       },
         //       {
         //          "$group": { 
         //             "_id": {
         //                "Range": "$range",
         //                "EventCode": "$events.event_code"
         //             }, 
         //             "count": {"$sum": 1} 
         //          }
         //       },
         //       {
         //          "$sort": {"count": -1}
         //       }   
         //    ],
         //    "columns":[],
         //    // // "predefined":"6464fe29f4696fa32f2880b6"

         //    //with behavioral
         //    // "standard":[
         //    //    {
         //    //       "$match":{
         //    //          "events":{
         //    //             "$elemMatch":{
         //    //                "app_code":{"$in":["2030"]},
         //    //                "event_data.creditscore":"9"
         //    //             }
         //    //          }
         //    //       }
         //    //    },
         //    //    {
         //    //       "$project":{
         //    //          "event_code":1,
         //    //          "event_data":1,
         //    //          "events":{
         //    //             "$filter":{
         //    //                "input":"$events",
         //    //                "as":"event",
         //    //                "cond":{
         //    //                   "$and":[
         //    //                      {"$in":["$$event.app_code",["2030"]]},
         //    //                      {"$eq":["$$event.event_data.creditscore","9"]}
         //    //                   ]
         //    //                }
         //    //             }
         //    //          }
         //    //       }
         //    //    },
         //    //    {"$unwind":"$events"},
         //    //    {
         //    //       "$group":{
         //    //          "_id":{
         //    //             "Event Code":"$events.event_code",
         //    //             "creditscore":"$events.event_data.creditscore"
         //    //          },
         //    //          "Count":{"$sum":1}
         //    //       }
         //    //    },
         //    //    {"$sort":{"Count":-1}},
         //    //    {"$limit":50}
         //    // ],
         //    // "columns":[]
         // };
      },

      getSampleDataForCalculatedTable() {
         // alert('in getSampleDataForCalculatedTable()');
         // this.chart.title = "calculated-table with listCount";
         // this.chart.filter.standard = [
         // {"$match":{"importId":{"$in":["6462ab11e7933eb62a92d0be","6435da1979680205ef24cdf8","641e1ba081424a948ddd8f3f","641dcc93a26324328903fe81","641dcb0a81424a948ddd8ebf"]}}},{"$project":{"importId":1,"events":1}},{"$unwind":"$events"},{"$group":{"_id":{"Import":"$importId","Event Code":"$events.event_code"},"Count":{"$sum":1}}},{"$sort":{"Count":-1}},{"$limit":50}            
         //    {"$match":{
         //       "events":{
         //          "$elemMatch":{
         //             "event_data":{
         //                "$exists":true
         //             }
         //          }
         //       }
         //    }},
         //    {"$project":{"events":1}},
         //    {"$unwind":"$events"},
         //    {"$group":{
         //       "_id":{
         //          "Program":"$events.event_data.__program",
         //          "Event Code":"$events.event_code"
         //       },
         //       "Count":{"$sum":1}
         //    }},
         //    {"$sort":{"Count":-1}},
         //    {"$limit":50}
         // ];
         // alert('1=' + JSON.stringify(this.chart.calculatedColumns));
         // this.chart.calculatedColumns = [
         //    {
         //       "name": "Total-30501",
         //       "expression": "{{30501}}",
         //       "fix": "none::",
         //       "position": "e::"
         //    }
         // ];
         // this.chart.calculatedColumns = [
         //    {
         //       "name":"Total",
         //       "expression":"{{listCount}}+{{sent}}",
         //       "fix":"none::",
         //       "position":"e::"
         //    }
         // ];

         // alert('2=' + JSON.stringify(this.chart.calculatedColumns));
         return [
            // {"_id":{"Program":1,"Event Code":"30501"},"Count":84},
            // {"_id":{"Program":9,"Event Code":"30501"},"Count":80},
            // {"_id":{"Event Code":"sent"},"Count":71},
            // // {"_id":{"Program":4,"Event Code":"30501"},"Count":64},
            // // {"_id":{"Program":1,"Event Code":"30502"},"Count":51},
            // // {"_id":{"Program":1,"Event Code":"20103"},"Count":48},
            // // {"_id":{"Program":9,"Event Code":"30502"},"Count":40},
            // // {"_id":{"Program":4,"Event Code":"30500"},"Count":39},
            // // {"_id":{"Program":9,"Event Code":"30504"},"Count":39},
            // // {"_id":{"Program":9,"Event Code":"30500"},"Count":38},
            // // {"_id":{"Program":12,"Event Code":"30501"},"Count":37},
            // // {"_id":{"Program":1,"Event Code":"30500"},"Count":35},
            // // {"_id":{"Program":9,"Event Code":"30506"},"Count":26},
            // // {"_id":{"Program":4,"Event Code":"30502"},"Count":26},
            // // {"_id":{"Program":12,"Event Code":"20103"},"Count":25},
            // // {"_id":{"Program":1,"Event Code":"30504"},"Count":25},
            // // {"_id":{"Program":12,"Event Code":"20105"},"Count":21},
            // // {"_id":{"Program":12,"Event Code":"30502"},"Count":19},
            // {"_id":{"Program":4,"Event Code":20103},"Count":18},
            // {"_id":{"Program":12,"Event Code":"30500"},"Count":17},
            // {"_id":{"Program":4,"Event Code":20105},"Count":14},
            // // {"_id":{"Program":4,"Event Code":"20103"},"Count":12},
            // // {"_id":{"Program":4,"Event Code":"30504"},"Count":12},
            // // {"_id":{"Program":12,"Event Code":"30504"},"Count":8},
            // // {"_id":{"Program":4,"Event Code":"20105"},"Count":8},
            // {"_id":{"Program":12,"Event Code":"\"20103\""},"Count":8},
            // {"_id":{"Program":12,"Event Code":20103},"Count":7},
            // {"_id":{"Program":4,"Event Code":"30506"},"Count":4},
            // {"_id":{"Program":4,"Event Code":20104},"Count":4},
            // // {"_id":{"Program":4,"Event Code":"20104"},"Count":4},
            // // {"_id":{"Program":1,"Event Code":"20105"},"Count":3},
            // // {"_id":{"Program":1,"Event Code":"20104"},"Count":3},
            // // {"_id":{"Program":12,"Event Code":"30508"},"Count":3},
            // // {"_id":{"Program":1,"Event Code":"30508"},"Count":3},
            // {"_id":{"Program":12,"Event Code":20105},"Count":3},
            // // {"_id":{"Program":12,"Event Code":"30506"},"Count":3},
            // // {"_id":{"Program":12,"Event Code":"203014"},"Count":2},
            // // {"_id":{"Program":12,"Event Code":"203013"},"Count":2},
            // {"_id":{"Program":12,"Event Code":"\"20105\""},"Count":2},
            // // {"_id":{"Program":4,"Event Code":"30508"},"Count":1},
            // {"_id":{"Program":1,"Event Code":20103},"Count":1}
            //for listCount
            // {
            //    "_id": {
            //          "Import": "1",
            //          "Event Code": "sent"
            //    },
            //    "Count": 100
            // },
            // {
            //    "_id": {
            //          "Import": "2",
            //          "Event Code": "sent"
            //    },
            //    "Count": 200
            // },
            // {
            //    "_id": {
            //          "Import": "3",
            //          "Event Code": "sent"
            //    },
            //    "Count": 300
            // }
            // // {
            // //    "_id": {
            // //       "Import": "6435da1979680205ef24cdf8",
            // //       "Event Code": "sent"
            // //    },
            // //    "Count": 993
            // // },
            // // {
            // //    "_id": {
            // //       "Import": "6462ab11e7933eb62a92d0be",
            // //       "Event Code": "sent"
            // //    },
            // //    "Count": 993
            // // }
   // // //  {
   // // //      "_id": {
   // // //          "Import": "65dce9f231f3000a1cd3abb1",
   // // //          "Event Code": "sent"
   // // //      },
   // // //      "Count": 998
   // // //  },
   // // //  {
   // // //      "_id": {
   // // //          "Import": "65dce7d7cbb48af9c47442a8",
   // // //          "Event Code": "sent"
   // // //      },
   // // //      "Count": 993
   // // //  },
   // // //  {
   // // //      "_id": {
   // // //          "Import": "65dcdc56d8b93d9ce067905d",
   // // //          "Event Code": "sent"
   // // //      },
   // // //      "Count": 989
   // // //  },
   // // //  {
   // // //      "_id": {
   // // //          "Import": "65dbc5b23183a3b67a9f1e09",
   // // //          "Event Code": "sent"
   // // //      },
   // // //      "Count": 8
   // // //  },
   // // //  {
   // // //      "_id": {
   // // //          "Import": "65dbc50a3183a3b67a9f1ddf",
   // // //          "Event Code": "sent"
   // // //      },
   // // //      "Count": 6
   // // //  },
   //  {
   //      "_id": {
   //          "Import": "65d7df92f9f4bc2248493c97",
   //          "Event Code": "70110"
   //      },
   //      "Count": 4
   //  },
   //  {
   //      "_id": {
   //          "Import": "65d7df92f9f4bc2248493c97",
   //          "Event Code": "70111"
   //      },
   //      "Count": 2
   //  },
   //  {
   //      "_id": {
   //          "Import": "65569fb4425adb805836c08b",
   //          "Event Code": "meeting"
   //      },
   //      "Count": 1
   //  },
   //  {
   //      "_id": {
   //          "Import": "6564e1b9953b615e79645afd",
   //          "Event Code": "call"
   //      },
   //      "Count": 1
   //  }


   //  {
   //      "_id": {
   //          "Import": "641e1ba081424a948ddd8f3f",
   //          "Event Code": "sent"
   //      },
   //      "Count": 998
   //  },
   //  {
   //      "_id": {
   //          "Import": "6435da1979680205ef24cdf8",
   //          "Event Code": "sent"
   //      },
   //      "Count": 998
   //  }
   {
      "_id": {
            "Kit": "2290-P",
            "z_leadtype": "InfoRequest"
      },
      "Count": 410
   },
   {
      "_id": {
            "Kit": "2290-Pct",
            "z_leadtype": "InfoRequest"
      },
      "Count": 381
   },
   {
      "_id": {
            "Kit": "2290-Pc",
            "z_leadtype": "InfoRequest"
      },
      "Count": 357
   },
   // // {
   // //    "_id": {
   // //          "Kit": "2269-Pct",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 351
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2259-P",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 280
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2305-Pct",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 216
   // // },
   // {
   //    "_id": {
   //          "Kit": "2344-Pc",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 209
   // },
   // {
   //    "_id": {
   //          "Kit": "2346-Pc",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 195
   // },
   // {
   //    "_id": {
   //          "Kit": "2333-P",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 194
   // },
   // {
   //    "_id": {
   //          "Kit": "2269-P",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 182
   // },
   // {
   //    "_id": {
   //          "Kit": "2190-Pc",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 173
   // },
   // {
   //    "_id": {
   //          "Kit": "2190-P",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 159
   // },
   // {
   //    "_id": {
   //          "Kit": "2305-P",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 153
   // },
   // {
   //    "_id": {
   //          "Kit": "2344-Pc",
   //          "z_leadtype": "Activation"
   //    },
   //    "Count": 152
   // },
   // {
   //    "_id": {
   //          "Kit": "2269-Pct",
   //          "z_leadtype": "Activation"
   //    },
   //    "Count": 143
   // },
   // {
   //    "_id": {
   //          "Kit": "2269-Pc",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 139
   // },
   {
      "_id": {
            "Kit": "2290-Pct",
            "z_leadtype": "Activation"
      },
      "Count": 133
   },
   // {
   //    "_id": {
   //          "Kit": "2346-Pc",
   //          "z_leadtype": "Activation"
   //    },
   //    "Count": 130
   // },
   // {
   //    "_id": {
   //          "Kit": "2190-H",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 112
   // },
   {
      "_id": {
            "Kit": "2290-P",
            "z_leadtype": "Activation"
      },
      "Count": 112
   },
   // {
   //    "_id": {
   //          "Kit": "2259-P",
   //          "z_leadtype": "Activation"
   //    },
   //    "Count": 105
   // },
   // {
   //    "_id": {
   //          "Kit": "2333-P",
   //          "z_leadtype": "Activation"
   //    },
   //    "Count": 101
   // },
   // {
   //    "_id": {
   //          "Kit": "2190-Hc",
   //          "z_leadtype": "InfoRequest"
   //    },
   //    "Count": 99
   // },
   {
      "_id": {
            "Kit": "2290-Pc",
            "z_leadtype": "Activation"
      },
      "Count": 97
   },
   // {
   //    "_id": {
   //          "Kit": "2305-Pct",
   //          "z_leadtype": "Activation"
   //    },
   //    "Count": 94
   // },
   {
      "_id": {
            "z_leadtype": "InfoRequest"
      },
      "Count": 93
   },
   // // {
   // //    "_id": {
   // //          "Kit": "2339-Pc",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 91
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2334-P",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 83
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2259-H",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 80
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2338-Pc",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 74
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2190-Pc",
   // //          "z_leadtype": "Activation"
   // //    },
   // //    "Count": 72
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2269-P",
   // //          "z_leadtype": "Activation"
   // //    },
   // //    "Count": 69
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2269-H",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 66
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2190-P",
   // //          "z_leadtype": "Activation"
   // //    },
   // //    "Count": 63
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2346-P",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 58
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2338-Pc",
   // //          "z_leadtype": "Activation"
   // //    },
   // //    "Count": 56
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2337-Pt",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 55
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2305-P",
   // //          "z_leadtype": "Activation"
   // //    },
   // //    "Count": 54
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2337-Pc",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 51
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2337-P",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 49
   // // },
   // // {
   // //    "_id": {
   // //          "Kit": "2305-Pc",
   // //          "z_leadtype": "InfoRequest"
   // //    },
   // //    "Count": 46
   // // },
   {
      "_id": {
            "Kit": "2190-Hc",
            "z_leadtype": "Activation"
      },
      "Count": 45
   },
   {
      "_id": {
            "Kit": "2269-Hc",
            "z_leadtype": "InfoRequest"
      },
      "Count": 42
   },
   {
      "_id": {
            "Kit": "2190-H",
            "z_leadtype": "Activation"
      },
      "Count": 39
   },
   {
      "_id": {
            "z_leadtype": "Activation"
      },
      "Count": 37
   },
   {
      "_id": {
            "Kit": "2337-Pc",
            "z_leadtype": "Activation"
      },
      "Count": 37
   },
   {
      "_id": {
            "Kit": "2345-Hc",
            "z_leadtype": "InfoRequest"
      },
      "Count": 37
   },
   {
      "_id": {
            "Kit": "2269-Pc",
            "z_leadtype": "Activation"
      },
      "Count": 34
   },
   {
      "_id": {
            "Kit": "2328-P",
            "z_leadtype": "InfoRequest"
      },
      "Count": 34
   },
   {
      "_id": {
            "Kit": "2259-H",
            "z_leadtype": "Activation"
      },
      "Count": 33
   }
         ];
      },
      getSampleData2ForCalculatedTable($group) {
         const idLen = Object.keys($group._id).length;
         if (idLen === 1)
            return [
               {
                  "_id": {"Kit": "2290-Pct"},
                  "Count": 215468
               },
               {
                  "_id": {"Kit": "2290-P"},
                  "Count": 16917
               },
               {
                  "_id": {"Kit": "2253-P"},
                  "Count": 7428
               },
               {
                  "_id": {"Kit": "2279-H"},
                  "Count": 5672
               },
               {
                  "_id": {"Kit": "2190-P"},
                  "Count": 1925
               },
               {
                  "_id": {"Kit": "2322-P"},
                  "Count": 1269
               },
               {
                  "_id": {"Kit": "2290-H"},
                  "Count": 931
               },
               {
                  "_id": {"Kit": "2253-H"},
                  "Count": 390
               }
            ];
         else
            return [
    {
        "_id": {
            "Kit": "2300-P",
            "Event Code": "30500"
        },
        "Count": 5330
    },
    {
        "_id": {
            "Kit": "2300-P",
            "Event Code": "30501"
        },
        "Count": 5299
    },
    {
        "_id": {
            "Kit": "2290-Pct",
            "Event Code": "30501"
        },
        "Count": 2367
    },
    {
        "_id": {
            "Kit": "2290-P",
            "Event Code": "30501"
        },
        "Count": 2284
    },
    {
        "_id": {
            "Kit": "2290-Pc",
            "Event Code": "30501"
        },
        "Count": 2086
    },
    {
        "_id": {
            "Kit": "2290-Pct",
            "Event Code": "30500"
        },
        "Count": 1774
    },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1774
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2344-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1527
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pct",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1446
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pct",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1294
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1250
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1116
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1100
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2333-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1015
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pct",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 993
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 990
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 969
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 965
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pct",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 896
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 856
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 841
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 801
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2344-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 778
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 776
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 772
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 639
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2339-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 603
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pct",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 591
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 578
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2333-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 553
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Hc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 541
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pct",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 541
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 534
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2338-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 523
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 517
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 508
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 489
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2344-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 470
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 451
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 447
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 431
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2334-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 425
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 424
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 405
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2345-Hc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 396
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 391
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 389
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 389
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pct",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 344
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 334
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 326
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 322
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2333-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 320
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2339-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 301
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Hc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 295
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2334-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 288
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 287
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 285
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 273
   // //  },
   // //  {
   // //      "_id": {
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 262
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 262
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2338-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 261
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 258
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 258
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 251
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 237
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 233
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 233
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2348-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 218
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2345-Hc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 210
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 209
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Hc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 191
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 183
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 180
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 175
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 169
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 165
   // //  },
   // //  {
   // //      "_id": {
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 163
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2339-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 161
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2338-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 161
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 159
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Hc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 157
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Hc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 154
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2278-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 150
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 150
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Hct",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 136
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 135
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2352-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 135
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 121
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2334-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 120
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2345-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 111
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 110
   // //  },
   // //  {
   // //      "_id": {
   // //          "Event Code": "30504"
   // //      },
   // //      "Count": 109
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2347-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 108
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 106
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 106
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 105
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2340-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 102
   // //  },
   // //  {
   // //      "_id": {
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 98
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Hc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 96
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2345-Hc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 96
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 95
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Hc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 92
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2348-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 87
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 82
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2348-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 81
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2352-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 78
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Hct",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 76
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 76
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2278-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 74
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 69
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Hc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 65
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2345-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 60
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 59
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2347-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 55
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2348-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 52
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 50
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 47
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2340-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 47
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Hc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 47
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2331-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 44
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Hct",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 41
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2278-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 40
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2347-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 38
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 37
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 36
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 36
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 36
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2352-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 31
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 31
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2307-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 30
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2347-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 29
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 29
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 28
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2281-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 26
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 26
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2349-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 24
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2340-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 24
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2349-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 22
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2345-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 22
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 22
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2348-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 21
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2347-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 21
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2228-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 21
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2326-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 20
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 20
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2228-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 20
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2331-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 20
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2341-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 19
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Hc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 18
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Hc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 17
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2307-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 17
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 17
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 17
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2311-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 16
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2341-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 16
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2348-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 15
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2327-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 15
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2281-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 15
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 14
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pb",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 14
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2253-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 14
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2331-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 14
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 14
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2278-Ht",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 13
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2349-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 13
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2253-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 13
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2326-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 13
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2311-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 13
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 12
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 12
   // //  },
   // //  {
   // //      "_id": {
   // //          "Event Code": "30506"
   // //      },
   // //      "Count": 12
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2349-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 12
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pct",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 10
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2307-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 10
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2353-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 10
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2344-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 10
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 10
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2161-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2327-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2268-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2161-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pct",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 9
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2347-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 8
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 8
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 8
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 8
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2329-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 7
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2208-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 7
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2327-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 7
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2279-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 7
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2304-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 7
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 7
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 7
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Ht",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2350-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2355-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2327-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2353-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2281-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2342-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2278-Ht",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pb",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 6
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2325-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2279-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2329-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2228-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-H",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2349-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2304-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2228-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 5
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2268-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2333-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-Hc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2333-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2208-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Ht",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2325-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2342-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2329-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pct",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Pb",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2282-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2349-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 4
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Pt1",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2329-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2315-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2350-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2347-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2315-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2306-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-Hc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-Pt",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2350-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pb",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2319-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2326-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2339-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pb",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2321-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2293-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2282-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2327-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2293-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2330-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Pt1",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2326-Pct",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2323-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-H",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2268-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2319-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 3
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Pb",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2323-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2219-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2304-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2355-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pb",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2208-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2338-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2329-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2355-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2326-Pct",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2330-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2348-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Hc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2268-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2329-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2342-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2342-Pc",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2341-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2333-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Pb",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pb",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2306-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2304-Pt",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-Pb",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-Pc",
   // //          "Event Code": "20104"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-Pt",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2354-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 2
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2208-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2327-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2345-Hc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2353-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2333-Pc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2321-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2301-H",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-H",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Pb",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2278-Ht",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2349-Pc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-Hc",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2317-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-H",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2317-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2301-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2325-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2330-Pc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2238-H",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2219-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2305-Pt",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2321-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-Hc",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2278-Pt",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2277-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2314-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2259-Hc",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2314-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2279-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2311-P",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2290-H",
   // //          "Event Code": "30502"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2328-H",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2334-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2269-H",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2346-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2268-H",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2190-H",
   // //          "Event Code": "record-update"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2296-P",
   // //          "Event Code": "30500"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2337-P",
   // //          "Event Code": "30503"
   // //      },
   // //      "Count": 1
   // //  },
   // //  {
   // //      "_id": {
   // //          "Kit": "2296-P",
   // //          "Event Code": "30501"
   // //      },
   // //      "Count": 1
   // //  }
];
         // data = [
         //    {"_id":{"Range":"700 - 719","EventCode":"sent"},"count":20504},
         //    {"_id":{"Range":"680 - 699","EventCode":"sent"},"count":19782},
         //    {"_id":{"Range":"720 - 739","EventCode":"sent"},"count":18451},
         //    {"_id":{"Range":"740 - 759","EventCode":"sent"},"count":16590},
         //    {"_id":{"Range":"660 - 679","EventCode":"sent"},"count":15509},
         //    {"_id":{"Range":"< 660","EventCode":"sent"},"count":13884},
         //    {"_id":{"Range":"760 - 779","EventCode":"sent"},"count":12692},
         //    {"_id":{"Range":"780 - 799","EvenCode":"sent"},"count":10829},
         //    {"_id":{"Range":"Over 800","EventCode":"sent"},"count":647},
         //    {"_id":{"Range":"740 - 759","EventCode":"30502"},"count":3},
         //    {"_id":{"Range":"760 - 779","EventCode":"30502"},"count":3},
         //    {"_id":{"Range":"780 - 799","EventCode":"30502"},"count":2},
         //    {"_id":{"Range":"700 - 719","EventCode":"30502"},"count":1},
         //    {"_id":{"Range":"720 - 739","EventCode":"30502"},"count":1}
         // ];

         // data = [{"_id":{"Mail_Week":2,"Event Code":"sent"},"Count":25098},{"_id":{"Mail_Week":4,"Event Code":"sent"},"Count":25074},{"_id":{"Mail_Week":1,"Event Code":"sent"},"Count":25071},{"_id":{"Mail_Week":3,"Event Code":"sent"},"Count":25039},{"_id":{"Mail_Week":1,"Event Code":"30501"},"Count":331},{"_id":{"Mail_Week":2,"Event Code":"30501"},"Count":280},{"_id":{"Mail_Week":1,"Event Code":"30502"},"Count":113},{"_id":{"Mail_Week":2,"Event Code":"30502"},"Count":90},{"_id":{"Mail_Week":3,"Event Code":"30501"},"Count":40},{"_id":{"Mail_Week":3,"Event Code":"30502"},"Count":15}];
      },
      getSampleDataForPaginaTable(page, itemsPerPage) {
         const data = [
            {
               "_id": "66186e7a0705fa3789d63123",
               "importId": "66186984d76c0e3338825542",
               "purl": "barrymaloney10",
               "basePurl": "barrymaloney",
               "purlNumber": 11,
               "dedupHash": "1b9f8a2ae49ea090c163ead8c4d9ca11",
               "events": [
                  {
                     "app_code": "305",
                     "event_code": "30500",
                     "event_path": "bhgloans.com",
                     "event_date": "2024-06-20T01:52:56.000Z",
                     "ip": "2603:3003:3d03:7600:f43e:d01d:a435:da9f, 18.68.18.70",
                     "os": "Windows 10.0",
                     "platform": "Microsoft Windows",
                     "device": "desktop",
                     "browser": "Chrome",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1
                     },
                     "_id": "66738bc5081af73fea7125d1"
                  },
                  {
                     "app_code": "305",
                     "event_code": "30501",
                     "event_path": "bhgloans.com",
                     "event_element": "WelcomeA.html",
                     "event_date": "2024-06-20T01:53:44.000Z",
                     "ip": "2603:3003:3d03:7600:f43e:d01d:a435:da9f, 18.68.18.70",
                     "os": "Windows 10.0",
                     "platform": "Microsoft Windows",
                     "device": "desktop",
                     "browser": "Chrome",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1,
                        "__inboundChild": 2
                     },
                     "_id": "66738bc5b90dff9d5c38c952"
                  },
                  {
                     "app_code": "305",
                     "event_code": "30502",
                     "event_path": "bhgloans.com",
                     "event_element": "WelcomeA.html",
                     "event_date": "2024-06-20T01:57:23.000Z",
                     "ip": "2603:3003:3d03:7600:f43e:d01d:a435:da9f, 18.68.18.70",
                     "os": "Windows 10.0",
                     "platform": "Microsoft Windows",
                     "device": "desktop",
                     "browser": "Chrome",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1,
                        "__inboundChild": 2,
                        "smsoptin": "Y",
                        "estcredit": "720 - 850",
                        "estincome": "$250,000 - $999,999",
                        "amountrequested": "40,000",
                        "phone": "(202) 906-9442",
                        "email": "bmaloney@maloneyknox.com",
                        "mail_kit": "2279-P - 6x11 Brown Kraft Arrow Paymt Calc QR Code Suppl1 - SDM 10/2023",
                        "calcpaymt": "540",
                        "z_leadtype": "InfoRequest"
                     },
                     "_id": "66738ccf081af73fea7125eb"
                  }
               ],
               "rowNumber": 693316,
               "trans_id": 697113292,
               "kit": "2279-P",
               "drop_date": "10/12/2023",
               "firstname": "Barry",
               "lastname": "Maloney",
               "groupsegment": "Cold-Pro",
               "purldomain": "BHGLoans.com",
               "list_id": "35696_202310021733449247",
               "profession": "Pro",
               "pid": 1004834000,
               "mail_kit": "2279-P - 6x11 Brown Kraft Arrow Paymt Calc QR Code Suppl1 - SDM 10/2023",
               "salutation": "Barry",
               "address1": "5405 Spangler Ave",
               "city": "Bethesda",
               "state": "MD",
               "zip": "20816-1847",
               "isseed": "0",
               "specialtydescr": "Attorney",
               "specialtyphrase": "an attorney",
               "specialtyplural": "attorneys",
               "title_code": "ATY",
               "tollfreephone": "866.272.8672",
               "respondby": "11/26/2023",
               "recordtype": "Home",
               "email": "bmaloney@maloneyknox.com",
               "maxloanterm": 12,
               "paymtcalc": "Y",
               "specialty": "BHGPro",
               "offer_amt": 500000,
               "firmoff": "0",
               "targetproduct": "Core",
               "vendor": "SDM",
               "filetype": "Suppl1",
               "send": "S1",
               "lettermatte": "ArrowPCQR",
               "letterversion": "ArrowPCQR-Pro-O-1023-NF-Gen",
               "campaigncode": "DM1023",
               "image_code": 9,
               "purl_version": "A",
               "qr_id1": "1023QR",
               "qr_id2": "1023QRA",
               "qr_id3": "1023QRB",
               "qr_id4": "1023QRC",
               "qr_id5": "1023QRD",
               "headline_code": "A",
               "headline": "Welcome",
               "subhead_code": "G",
               "subhead": "Financing can help you make your mark",
               "theme": "light",
               "image_align": "left",
               "image_width": "full",
               "lifecycle": "Cold",
               "optin_page": "Y",
               "__v": 0,
               "_mfi_studio_sync": true,
               "initialquerystring": "?cookieCheck=true&SessionGuid=b1b4bfc6-13b1-41d0-815f-b1d762603131",
               "qrcode": "",
               "z_leadtype": "InfoRequest",
               "z_engaged": "Yes",
               "amountrequested": "40,000",
               "calcpaymt": 540,
               "estcredit": "720 - 850",
               "estincome": "$250,000 - $999,999",
               "phone": "(202) 906-9442",
               "smsoptin": "Y"
            },
            {
               "_id": "661897260705fa3789e0f688",
               "importId": "66189070d76c0e333882579c",
               "purl": "durettetyler7",
               "basePurl": "durettetyler",
               "purlNumber": 8,
               "dedupHash": "23c41831b697a404904f62f895fa101e",
               "events": [
                     {
                        "app_code": "305",
                        "event_code": "30500",
                        "event_path": "bhgloans.com",
                        "event_date": "2024-06-02T21:43:59.000Z",
                        "ip": "24.104.201.88, 18.68.18.116",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1
                        },
                        "_id": "665ce7aef0e389b9445884ee"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgloans.com",
                        "event_element": "QR.html",
                        "event_date": "2024-06-02T21:44:00.000Z",
                        "ip": "24.104.201.88, 18.68.18.116",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 12
                        },
                        "_id": "665ce7af3a2ad5eb3f6f75bd"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgloans.com",
                        "event_element": "WelcomeA.html",
                        "event_date": "2024-06-02T21:44:01.000Z",
                        "ip": "24.104.201.88, 18.68.18.116",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 2
                        },
                        "_id": "665ce7b03a2ad5eb3f6f75c0"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30502",
                        "event_path": "bhgloans.com",
                        "event_element": "WelcomeA.html",
                        "event_date": "2024-06-02T21:44:41.000Z",
                        "ip": "24.104.201.88, 18.68.18.116",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 2,
                           "smsoptin": "Y",
                           "estcredit": "720 - 850",
                           "estincome": "$200,000 - $250,000",
                           "amountrequested": "500,000",
                           "phone": "(914) 224-6897",
                           "email": "dtyler@kw.com",
                           "mail_kit": "2321-P - 6x11 Brown Kraft Big Pict Paymt Calc QR Code S3 - TAY 11/2023",
                           "calcpaymt": "6744",
                           "z_leadtype": "InfoRequest"
                        },
                        "_id": "665ce7e73a2ad5eb3f6f75ce"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgloans.com",
                        "event_element": "ApplicationA.html",
                        "event_date": "2024-06-02T21:44:42.000Z",
                        "ip": "24.104.201.88, 18.68.18.116",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 5
                        },
                        "_id": "665ce7e8f0e389b944588502"
                     }
                  ],
                  "rowNumber": 650602,
                  "trans_id": 695418186,
                  "kit": "2321-P",
                  "drop_date": "11/16/2023",
                  "firstname": "Durette",
                  "lastname": "Tyler",
                  "groupsegment": "Cold-Pro",
                  "purldomain": "BHGLoans.com",
                  "list_id": "35696_202309291514166709",
                  "profession": "Pro",
                  "pid": 1054139160,
                  "mail_kit": "2321-P - 6x11 Brown Kraft Big Pict Paymt Calc QR Code S3 - TAY 11/2023",
                  "salutation": "Durette",
                  "address1": "134 Mayflower Ave",
                  "city": "New Rochelle",
                  "state": "NY",
                  "zip": 10801,
                  "isseed": "0",
                  "specialtydescr": "Skilled Professional",
                  "specialtyphrase": "a skilled professional",
                  "specialtyplural": "skilled professionals",
                  "title_code": "UNKN",
                  "tollfreephone": "866.272.8672",
                  "respondby": "12/31/2023",
                  "recordtype": "Home",
                  "email": "dtyler@kw.com",
                  "maxloanterm": 12,
                  "paymtcalc": "Y",
                  "specialty": "BHGPro",
                  "offer_amt": 500000,
                  "firmoff": "0",
                  "targetproduct": "Core",
                  "vendor": "TAY",
                  "filetype": "Main",
                  "send": "S3",
                  "lettermatte": "BigPictPCQR",
                  "letterversion": "BigPictPCQR-Pro-O-1123-NF-Gen",
                  "campaigncode": "DM1123",
                  "image_code": 17,
                  "purl_version": "A",
                  "qr_id1": "1123QR",
                  "qr_id2": "1123QRA",
                  "qr_id3": "1123QRB",
                  "qr_id4": "1123QRC",
                  "qr_id5": "1123QRD",
                  "headline_code": "A",
                  "headline": "Welcome",
                  "subhead_code": "I",
                  "subhead": "Experience a better way to borrow",
                  "subhead2_code": "C",
                  "subhead2": "Get the funds you want, when you want them",
                  "theme": "light",
                  "image_align": "left",
                  "image_width": "split",
                  "lifecycle": "Cold",
                  "__v": 0,
                  "_mfi_studio_sync": true,
                  "amountrequested": "500,000",
                  "calcpaymt": 6744,
                  "estcredit": "720 - 850",
                  "estincome": "$200,000 - $250,000",
                  "phone": "(914) 224-6897",
                  "smsoptin": "Y",
                  "z_leadtype": "InfoRequest"
            },
            {
               "_id": "661895aa0705fa3789debc76",
               "importId": "66189070d76c0e333882579c",
               "purl": "hectorquinonez7",
               "basePurl": "hectorquinonez",
               "purlNumber": 8,
               "dedupHash": "0a93c68e52ccc09f643eab76cb2d7536",
               "events": [
                     {
                        "app_code": "305",
                        "event_code": "30500",
                        "event_path": "bhgelite.com",
                        "event_date": "2024-06-03T20:13:20.000Z",
                        "ip": "216.49.131.162, 18.68.18.102",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1
                        },
                        "_id": "665e24008326e2b09f3293ec"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "QR.html",
                        "event_date": "2024-06-03T20:13:20.000Z",
                        "ip": "216.49.131.162, 18.68.18.102",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 12
                        },
                        "_id": "665e24008326e2b09f3293ef"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "WelcomeA.html",
                        "event_date": "2024-06-03T20:13:21.000Z",
                        "ip": "216.49.131.162, 18.68.18.102",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 2
                        },
                        "_id": "665e24010df0dfe39cbab3d7"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30500",
                        "event_path": "bhgelite.com",
                        "event_date": "2024-06-03T20:15:01.000Z",
                        "ip": "216.49.131.162, 18.68.18.117",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1
                        },
                        "_id": "665e24668326e2b09f329414"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "QR.html",
                        "event_date": "2024-06-03T20:15:02.000Z",
                        "ip": "216.49.131.162, 18.68.18.117",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 12
                        },
                        "_id": "665e24660df0dfe39cbab3f7"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "WelcomeA.html",
                        "event_date": "2024-06-03T20:15:03.000Z",
                        "ip": "216.49.131.162, 18.68.18.117",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 2
                        },
                        "_id": "665e24670df0dfe39cbab3fa"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30502",
                        "event_path": "bhgelite.com",
                        "event_element": "WelcomeA.html",
                        "event_date": "2024-06-03T20:16:43.000Z",
                        "ip": "216.49.131.162, 18.68.18.117",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 2,
                           "smsoptin": "Y",
                           "estcredit": "720 - 850",
                           "estincome": "$50,000 - $100,000",
                           "amountrequested": "25,000",
                           "phone": "(203) 988-3987",
                           "email": "hquinonez57@gmail.com",
                           "mail_kit": "2290-P - 9x12 Brown Kraft Mega Peel Paymt Calc QR Code - IWCO 11/2023",
                           "calcpaymt": "337",
                           "z_leadtype": "InfoRequest"
                        },
                        "_id": "665e24cb8326e2b09f32943f"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "ApplicationA.html",
                        "event_date": "2024-06-03T20:16:43.000Z",
                        "ip": "216.49.131.162, 18.68.18.117",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 5
                        },
                        "_id": "665e24cb0df0dfe39cbab424"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30502",
                        "event_path": "bhgelite.com",
                        "event_element": "ApplicationA.html",
                        "event_date": "2024-06-03T20:26:31.000Z",
                        "ip": "216.49.131.162, 18.68.18.117",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 5,
                           "ownpractice": "no",
                           "otherbusiness": "",
                           "terms": "1",
                           "phonetype": "1",
                           "state": "CT",
                           "title_code": "0000",
                           "primaryincomesource": "Outside Employment - Contracted",
                           "secondaryincomesource": "Outside Employment - Contracted",
                           "bus_state": "",
                           "businessstructure": "",
                           "bus_title": "",
                           "bus_ownership_pct": "",
                           "useoffunds": "Personal/Family/Household/Recreational",
                           "industry": "Construction",
                           "amountrequested": "25,000",
                           "firstname": "Hector",
                           "middlename": "",
                           "lastname": "Quinonez",
                           "email": "hquinonez57@gmail.com",
                           "phone": "(203) 988-3987",
                           "address1": "31 Sandyhill Rd",
                           "city": "Middlebury ",
                           "zip": "06762",
                           "annualpersincome": "71,800",
                           "othertitle": "Millwright ",
                           "dateofbirth": "01/01/1950",
                           "ssn": "666-66-6666",
                           "company": "",
                           "bus_address1": "",
                           "bus_city": "",
                           "bus_zip": "",
                           "bus_annual_sales": "",
                           "bus_year_established": "",
                           "trans_id": "691502216",
                           "website": "",
                           "calcpaymt": "306",
                           "mailkit": "2290-P - 9x12 Brown Kraft Mega Peel Paymt Calc QR Code - IWCO 11/2023",
                           "estcredit": "720 - 850",
                           "initialquerystring": "",
                           "z_leadtype": "Activation",
                           "smsoptin": "Y",
                           "ip_addr": "216.49.131.162, 18.68.18.117"
                        },
                        "_id": "665e27170df0dfe39cbab4e7"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "TKU.html",
                        "event_date": "2024-06-03T20:26:47.000Z",
                        "ip": "216.49.131.162, 18.68.18.117",
                        "os": "OS X",
                        "platform": "iPhone",
                        "device": "mobile",
                        "browser": "Safari",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 7
                        },
                        "_id": "665e27178326e2b09f329511"
                     }
                  ],
                  "rowNumber": 506138,
                  "trans_id": "691502216",
                  "kit": "2290-P",
                  "drop_date": "10/26/2023",
                  "firstname": "Hector",
                  "lastname": "Quinonez",
                  "groupsegment": "Pref-WD-Pro",
                  "purldomain": "BHGElite.com",
                  "list_id": "35696_202309291514166709",
                  "profession": "Pro",
                  "pid": 1063597884,
                  "mail_kit": "2290-P - 9x12 Brown Kraft Mega Peel Paymt Calc QR Code - IWCO 11/2023",
                  "salutation": "Hector",
                  "address1": "31 Sandyhill Rd",
                  "city": "Middlebury ",
                  "state": "CT",
                  "zip": "06762",
                  "isseed": "0",
                  "specialtydescr": "Skilled Professional",
                  "specialtyphrase": "a skilled professional",
                  "specialtyplural": "skilled professionals",
                  "title_code": "0000",
                  "tollfreephone": "866.272.8672",
                  "respondby": "12/10/2023",
                  "recordtype": "Home",
                  "maxloanterm": 12,
                  "paymtcalc": "Y",
                  "specialty": "BHGPro",
                  "offer_amt": 500000,
                  "firmoff": 1,
                  "targetproduct": "Core",
                  "vendor": "IWCO",
                  "filetype": "Main",
                  "send": "S1",
                  "lettermatte": "MegaPeelPCQR",
                  "letterversion": "MegaPeelPCQR-Pro-PREFWD-1123-F-Gen-F",
                  "campaigncode": "DM1123",
                  "image_code": 7,
                  "purl_version": "A",
                  "qr_id1": "1123QR",
                  "qr_id2": "1123QRA",
                  "qr_id3": "1123QRB",
                  "qr_id4": "1123QRC",
                  "qr_id5": "1123QRD",
                  "headline_code": "A",
                  "headline": "Welcome",
                  "subhead_code": "A",
                  "subhead": "Financing for busy professionals like you",
                  "theme": "light",
                  "image_align": "left",
                  "image_width": "full",
                  "lifecycle": "Pref",
                  "__v": 0,
                  "_mfi_studio_sync": true,
                  "amountrequested": "25,000",
                  "calcpaymt": 306,
                  "email": "hquinonez57@gmail.com",
                  "estcredit": "720 - 850",
                  "estincome": "$50,000 - $100,000",
                  "phone": "(203) 988-3987",
                  "smsoptin": "Y",
                  "z_leadtype": "Activation",
                  "annualpersincome": "71,800",
                  "bus_address1": "",
                  "bus_annual_sales": "",
                  "bus_city": "",
                  "bus_year_established": "",
                  "bus_zip": "",
                  "company": "",
                  "dateofbirth": "01/01/1950",
                  "industry": "Construction",
                  "initialquerystring": "",
                  "middlename": "",
                  "othertitle": "Millwright ",
                  "ownpractice": "no",
                  "phonetype": "1",
                  "primaryincomesource": "Outside Employment - Contracted",
                  "secondaryincomesource": "Outside Employment - Contracted",
                  "useoffunds": "Personal/Family/Household/Recreational",
                  "website": ""
            },
            {
               "_id": "661891f20705fa3789d929dc",
               "importId": "66189070d76c0e333882579c",
               "purl": "jacquelynpolitoske1",
               "basePurl": "jacquelynpolitoske",
               "purlNumber": 2,
               "dedupHash": "d21d2ab99470b8baa72bb3e5916648ea",
               "events": [
                  {
                     "app_code": "305",
                     "event_code": "30500",
                     "event_path": "bhgelite.com",
                     "event_date": "2024-06-06T16:22:32.000Z",
                     "ip": "2601:41:c201:eba0:51ea:d129:eda4:9189, 18.68.18.78",
                     "os": "OS X",
                     "platform": "iPhone",
                     "device": "mobile",
                     "browser": "Safari",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1
                     },
                     "_id": "6661e25a0df0dfe39cbb3368"
                  },
                  {
                     "app_code": "305",
                     "event_code": "30501",
                     "event_path": "bhgelite.com",
                     "event_element": "QR.html",
                     "event_date": "2024-06-06T16:22:10.000Z",
                     "ip": "2601:41:c201:eba0:51ea:d129:eda4:9189, 18.68.18.78",
                     "os": "OS X",
                     "platform": "iPhone",
                     "device": "mobile",
                     "browser": "Safari",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1,
                        "__inboundChild": 12
                     },
                     "_id": "6661e25a8326e2b09f331397"
                  },
                  {
                     "app_code": "305",
                     "event_code": "30501",
                     "event_path": "bhgelite.com",
                     "event_element": "WelcomeA.html",
                     "event_date": "2024-06-06T16:22:11.000Z",
                     "ip": "2601:41:c201:eba0:51ea:d129:eda4:9189, 18.68.18.78",
                     "os": "OS X",
                     "platform": "iPhone",
                     "device": "mobile",
                     "browser": "Safari",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1,
                        "__inboundChild": 2
                     },
                     "_id": "6661e25b8326e2b09f33139a"
                  },
                  {
                     "app_code": "305",
                     "event_code": "30502",
                     "event_path": "bhgelite.com",
                     "event_element": "WelcomeA.html",
                     "event_date": "2024-06-06T16:23:16.000Z",
                     "ip": "2601:41:c201:eba0:51ea:d129:eda4:9189, 18.68.18.78",
                     "os": "OS X",
                     "platform": "iPhone",
                     "device": "mobile",
                     "browser": "Safari",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1,
                        "__inboundChild": 2,
                        "smsoptin": "Y",
                        "estcredit": "0 - 639",
                        "estincome": "$150,000 - $200,000",
                        "amountrequested": "150,000",
                        "phone": "(215) 936-9725",
                        "email": "jpolitoske@gmail.com",
                        "mail_kit": "2290-P - 9x12 Brown Kraft Mega Peel Paymt Calc QR Code - IWCO 11/2023",
                        "calcpaymt": "2023",
                        "z_leadtype": "InfoRequest"
                     },
                     "_id": "6661e2860df0dfe39cbb336d"
                  },
                  {
                     "app_code": "305",
                     "event_code": "30501",
                     "event_path": "bhgelite.com",
                     "event_element": "ApplicationA.html",
                     "event_date": "2024-06-06T16:22:55.000Z",
                     "ip": "2601:41:c201:eba0:51ea:d129:eda4:9189, 18.68.18.78",
                     "os": "OS X",
                     "platform": "iPhone",
                     "device": "mobile",
                     "browser": "Safari",
                     "event_data": {
                        "__program": 1,
                        "__campaign": 1,
                        "__inbound": 1,
                        "__inboundChild": 5
                     },
                     "_id": "6661e2878326e2b09f3313a3"
                  }
               ],
               "rowNumber": 144616,
               "trans_id": 691222514,
               "kit": "2290-P",
               "drop_date": "10/26/2023",
               "firstname": "Jacquelyn",
               "lastname": "Politoske",
               "groupsegment": "Pref-WD-Pro",
               "purldomain": "BHGElite.com",
               "list_id": "35696_202309291514166709",
               "profession": "Pro",
               "pid": 2018284383,
               "mail_kit": "2290-P - 9x12 Brown Kraft Mega Peel Paymt Calc QR Code - IWCO 11/2023",
               "salutation": "Jacquelyn",
               "address1": "4700 City Ave 9402",
               "city": "Philadelphia",
               "state": "PA",
               "zip": 19131,
               "isseed": "0",
               "specialtydescr": "Skilled Professional",
               "specialtyphrase": "a skilled professional",
               "specialtyplural": "skilled professionals",
               "title_code": "UNKN",
               "tollfreephone": "866.272.8672",
               "respondby": "12/10/2023",
               "recordtype": "Home",
               "email": "jpolitoske@gmail.com",
               "maxloanterm": 12,
               "paymtcalc": "Y",
               "specialty": "BHGPro",
               "offer_amt": 500000,
               "firmoff": "0",
               "targetproduct": "Core",
               "vendor": "IWCO",
               "filetype": "Main",
               "send": "S1",
               "lettermatte": "MegaPeelPCQR",
               "letterversion": "MegaPeelPCQR-Pro-PREFWD-1123-NF-Gen",
               "campaigncode": "DM1123",
               "image_code": 7,
               "purl_version": "A",
               "qr_id1": "1123QR",
               "qr_id2": "1123QRA",
               "qr_id3": "1123QRB",
               "qr_id4": "1123QRC",
               "qr_id5": "1123QRD",
               "headline_code": "A",
               "headline": "Welcome",
               "subhead_code": "A",
               "subhead": "Financing for busy professionals like you",
               "theme": "light",
               "image_align": "left",
               "image_width": "full",
               "lifecycle": "Pref",
               "__v": 0,
               "_mfi_studio_sync": true,
               "initialquerystring": "?l=1123QRA&cookieCheck=true&SessionGuid=825405de-74e6-472c-a0bb-2d4935991b83",
               "qrcode": "1123QRA",
               "z_leadtype": "InfoRequest",
               "z_engaged": "Yes",
               "amountrequested": "150,000",
               "calcpaymt": 2023,
               "estcredit": "0 - 639",
               "estincome": "$150,000 - $200,000",
               "phone": "(215) 936-9725",
               "smsoptin": "Y"
            },
            {
               "_id": "6618937b0705fa3789db68df",
               "importId": "66189070d76c0e333882579c",
               "purl": "davidfitch27",
               "basePurl": "davidfitch",
               "purlNumber": 28,
               "dedupHash": "3583e0509aade35c5ede1598c9cbcf6e",
               "events": [
                     {
                        "app_code": "305",
                        "event_code": "30500",
                        "event_path": "bhgelite.com",
                        "event_date": "2024-06-14T22:55:35.000Z",
                        "ip": "2601:207:80:b6e0:2cb1:11fd:2c96:2100, 15.158.5.179",
                        "os": "Windows 10.0",
                        "platform": "Microsoft Windows",
                        "device": "desktop",
                        "browser": "Firefox",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1
                        },
                        "_id": "666ccaa50df0dfe39cbc5d77"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "WelcomeA.html",
                        "event_date": "2024-06-14T22:55:35.000Z",
                        "ip": "2601:207:80:b6e0:2cb1:11fd:2c96:2100, 15.158.5.179",
                        "os": "Windows 10.0",
                        "platform": "Microsoft Windows",
                        "device": "desktop",
                        "browser": "Firefox",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 2
                        },
                        "_id": "666ccaa50df0dfe39cbc5d7a"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30502",
                        "event_path": "bhgelite.com",
                        "event_element": "WelcomeA.html",
                        "event_date": "2024-06-14T22:58:51.000Z",
                        "ip": "2601:207:80:b6e0:2cb1:11fd:2c96:2100, 15.158.5.179",
                        "os": "Windows 10.0",
                        "platform": "Microsoft Windows",
                        "device": "desktop",
                        "browser": "Firefox",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 2,
                           "smsoptin": "Y",
                           "estcredit": "720 - 850",
                           "estincome": "$100,000 - $150,000",
                           "amountrequested": "30,000",
                           "phone": "(916) 320-3415",
                           "email": "davidgfitch@yahoo.com",
                           "mail_kit": "2290-P - 9x12 Brown Kraft Mega Peel Paymt Calc QR Code - IWCO 11/2023",
                           "calcpaymt": "405",
                           "z_leadtype": "InfoRequest"
                        },
                        "_id": "666ccb440df0dfe39cbc5d93"
                     },
                     {
                        "app_code": "305",
                        "event_code": "30501",
                        "event_path": "bhgelite.com",
                        "event_element": "ApplicationA.html",
                        "event_date": "2024-06-14T22:58:51.000Z",
                        "ip": "2601:207:80:b6e0:2cb1:11fd:2c96:2100, 15.158.5.179",
                        "os": "Windows 10.0",
                        "platform": "Microsoft Windows",
                        "device": "desktop",
                        "browser": "Firefox",
                        "event_data": {
                           "__program": 1,
                           "__campaign": 1,
                           "__inbound": 1,
                           "__inboundChild": 5
                        },
                        "_id": "666ccb458326e2b09f344117"
                     }
                  ],
                  "rowNumber": 290332,
                  "trans_id": 692745437,
                  "kit": "2290-P",
                  "drop_date": "11/02/2023",
                  "firstname": "David",
                  "lastname": "Fitch",
                  "groupsegment": "Pref-WD-Pro",
                  "purldomain": "BHGElite.com",
                  "list_id": "35696_202309291514166709",
                  "profession": "Pro",
                  "pid": 2016227468,
                  "mail_kit": "2290-P - 9x12 Brown Kraft Mega Peel Paymt Calc QR Code - IWCO 11/2023",
                  "salutation": "David",
                  "address1": "40 Park City Ct",
                  "address2": "Apt 3107",
                  "city": "Sacramento",
                  "state": "CA",
                  "zip": 95831,
                  "isseed": "0",
                  "specialtydescr": "Accountant",
                  "specialtyphrase": "an accountant",
                  "specialtyplural": "accountants",
                  "title_code": "CPA",
                  "tollfreephone": "866.272.8672",
                  "respondby": "12/17/2023",
                  "recordtype": "Home",
                  "maxloanterm": 12,
                  "paymtcalc": "Y",
                  "specialty": "BHGPro",
                  "offer_amt": 500000,
                  "firmoff": "0",
                  "targetproduct": "Core",
                  "vendor": "IWCO",
                  "filetype": "Main",
                  "send": "S1",
                  "lettermatte": "MegaPeelPCQR",
                  "letterversion": "MegaPeelPCQR-Pro-PREFWD-1123-NF-Gen",
                  "campaigncode": "DM1123",
                  "image_code": 7,
                  "purl_version": "A",
                  "qr_id1": "1123QR",
                  "qr_id2": "1123QRA",
                  "qr_id3": "1123QRB",
                  "qr_id4": "1123QRC",
                  "qr_id5": "1123QRD",
                  "headline_code": "A",
                  "headline": "Welcome",
                  "subhead_code": "A",
                  "subhead": "Financing for busy professionals like you",
                  "theme": "light",
                  "image_align": "left",
                  "image_width": "full",
                  "lifecycle": "Pref",
                  "__v": 0,
                  "_mfi_studio_sync": true,
                  "initialquerystring": "?cookieCheck=true&SessionGuid=ff591999-8485-465d-8bd8-b73d4d55d953",
                  "qrcode": "",
                  "z_leadtype": "InfoRequest",
                  "z_engaged": "Yes",
                  "amountrequested": "30,000",
                  "calcpaymt": 405,
                  "email": "davidgfitch@yahoo.com",
                  "estcredit": "720 - 850",
                  "estincome": "$100,000 - $150,000",
                  "phone": "(916) 320-3415",
                  "smsoptin": "Y"
            }
         ];

         const sInd = (page - 1) * itemsPerPage;
         return data.slice(sInd, itemsPerPage);
      },
      getSampleDataForKpi() {
         return [
            {"_id":{"Event Code":"30569"},"Average":247.33333333333334},
            {"_id":{"Event Code":"30570"},"Average":123.45}
         ];
      },

      //V230602
      /* the function will change data */
      // inject2ndDimension(data) {
      //    alert('in inject2ndDimension() start: data=' + JSON.stringify(data));
      //    const keyLengths = new Set();
      //    data.forEach(d => {
      //       keyLengths.add(Object.keys(d._id).length);
      //    });

      //    if (keyLengths.size === 2) {
      //       const ind1 = data.findIndex(d => Object.keys(d._id).length === 1);
      //       const key1 = Object.keys(data[ind1]._id)[0];
      //       const val1 = data[ind1]._id[key1];
      //       const ind2 = data.findIndex(d => Object.keys(d._id).length === 2);
      //       const keys2 = Object.keys(data[ind2]._id);
      //       alert(`${ind1}\n${key1}\n${val1}\n${ind2}\n${JSON.stringify(keys2)}`)
      //       if (key1 === keys2[0]) {
      //          data[ind1]._id[keys2[1]] = NOT_EXISTED;
      //       } else {
      //          data[ind1]._id = {};
      //          data[ind1]._id[keys2[0]] = NOT_EXISTED;
      //          data[ind1]._id[key1] = val1;
      //       }
      //    }
      //    alert('in inject2ndDimension() end: data=' + JSON.stringify(data));
      // },

      //V240502
      /* the function will change data */
      inject2ndDimension(data) {
         // alert('in inject2ndDimension() start: data=' + JSON.stringify(data));
         const keyLengths = new Set();
         data.forEach(d => {
            keyLengths.add(Object.keys(d._id).length);
         });

         if (keyLengths.size === 2) {
            const ind2 = data.findIndex(d => Object.keys(d._id).length === 2);
            const keys2 = Object.keys(data[ind2]._id);
            data.filter(d => Object.keys(d._id).length === 1).forEach(d => {
               const key1 = Object.keys(d._id)[0];
               const val1 = d._id[key1];
               if (key1 === keys2[0]) {
                  d._id[keys2[1]] = NOT_EXISTED;
               } else {
                  d._id = {};
                  d._id[keys2[0]] = NOT_EXISTED;
                  d._id[key1] = val1;
               }
            });
         }
         //TODO:
         // // else if (keyLengths.size === 3) {
         // //    const ind2 = data.findIndex(d => Object.keys(d._id).length === 3);
         // //    const keys2 = Object.keys(data[ind2]._id);
         // //    data.filter(d => Object.keys(d._id).length === 1).forEach(d => {
         // //       const key1 = Object.keys(d._id)[0];
         // //       const val1 = d._id[key1];
         // //       if (key1 === keys2[0]) {
         // //          d._id[keys2[1]] = NOT_EXISTED;
         // //       } else {
         // //          d._id = {};
         // //          d._id[keys2[0]] = NOT_EXISTED;
         // //          d._id[key1] = val1;
         // //       }
         // //    });
         // // }

         // alert('in inject2ndDimension() end: data=' + JSON.stringify(data));
      },

      async createExportClicked() {
         this.dialogExport = true;
         const self = this;
         setTimeout(() => {
            self.$refs.exportName.focus();
         }, 100);

         if (!this.lastExport) {
            this.loadingDialogExport = true;
            const importData = await this.btHelpers.getImportData(
               { standard: [{ $match: {} }] },
               true
            );
            // console.warn(`importData=${JSON.stringify(importData)}`);
            this.importValidations = importData.fieldValidations.filter(v => v.type === 'date');
            this.exportFields = [...importData.headers, ...this.btHelpers.getExportFields()];

            const result = await this.btHelpers.getLastExport();
            this.lastExport = new ExportFormData(result || {});
            this.loadingDialogExport = false;
         }
      },

      cancelExport() {
         this.dialogExport = false;
      },

      async saveExport() {
         if (!this.$refs.form.validate())
            return;

         this.loadingDialogExport = true;

         // Removing $project from the standard filter as it is not needed for the PaginaTable.
         const newFilter = JSON.parse(JSON.stringify(this.chart.filter.standard));
         const $projectInd = newFilter.findIndex(f => f.$project);
         if ($projectInd !== -1)
            newFilter.splice($projectInd, 1);

         this.lastExport.name = this.exportName;
         this.lastExport.aggregateQuery = JSON.stringify(newFilter); //this.chart.filter.standard
         // console.log('lastExport.aggregateQuery=' + this.lastExport.aggregateQuery);

         this.lastExport.fieldDefinitions = [];
         this.tableHeaders.forEach(header => {
            this.lastExport.fieldDefinitions.push({
               lable: header.text,
               value: header.value,
               default: ''
            });
         });
         this.lastExport.actions = [];
         // this.log('in saveExport(): lastExport=' + JSON.stringify(this.lastExport));

         const exportData = this.btHelpers.getExportData(
            this.lastExport,
            this.importValidations,
            this.exportFields
         );
         // this.log('exportData=' + JSON.stringify(exportData));

         const result = await this.apiServiceCs.createExport(exportData);  //lastExport
         this.loadingDialogExport = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            // this.$emit('snackbar-event', `'${this.lastExport.name}' was created.`);
            this.$emit('chart-export', `'${this.lastExport.name}' was created.`);
            this.exportName = '';
            this.cancelExport();
         }
      },

      getKpiIncludedTabs(templateName) {
         if (templateName === KPI_TEMPLATE_NAMES.aggregate)
            return FILTER_TABS.filter(t => t != 'columns' && t != 'predefined');
         else
            return FILTER_TABS.filter(t => t != 'groupby' && t != 'columns' && t != 'predefined');
      },

      kpiTemplateNameChanged(val) {
         // alert(`in kpiTemplateNameChanged(): val=${val}`);
         this.chart.filter = JSON.parse(JSON.stringify(EMPTY_FILTER));
         this.includedTabs = this.getKpiIncludedTabs(val);
         this.groupbyFieldHasEventData = val === KPI_TEMPLATE_NAMES.aggregate;
         // alert(`in kpiTemplateNameChanged(): groupbyFieldHasEventData=${this.groupbyFieldHasEventData}`);
      },

      kpiClicked(clickData) {
         console.log(`in ${NAME}.kpiClicked(): clickData=${JSON.stringify(clickData)}`);
         if (clickData)
            this.$emit('chart-click', clickData);
         else
            this.$emit('chart-reclick', clickData);
      },

      addActionsHeader() {
         this.tableHeaders.push({
            text: '',
            value: 'actions',
            align: 'right',
            sortable: false
         });
      },

      async contactProfileClicked(item) {
         let weeklyData, monthlyData;

         await this.tableItemExpanded([item]);

         if (this.isActualEndpoint) {
            const weeklyChartFilter = JSON.parse(JSON.stringify(WEEKLY_CHART_FILTER).replace('##idHolder##', item._id));
            weeklyData = await this.btHelpers.getData(
               this.stdFieldValues['importId'][0].value,
               weeklyChartFilter,
               undefined,
               undefined,
               this.distinct
            );

            const monthlyChartFilter = JSON.parse(JSON.stringify(MONTHLY_CHART_FILTER).replace('##idHolder##', item._id));
            monthlyData = await this.btHelpers.getData(
               this.stdFieldValues['importId'][0].value,
               monthlyChartFilter,
               undefined,
               undefined,
               this.distinct
            );
         } else {
            weeklyData = [
               {
                  "_id": {
                        "dayOfWeek": 1,
                        "Event Code": "sent"
                  },
                  "Count": 998
               },
               {
                  "_id": {
                        "dayOfWeek": 2,
                        "Event Code": "tag7"
                  },
                  "Count": 998
               },
               {
                  "_id": {
                        "dayOfWeek": 3,
                        "Event Code": "tag8"
                  },
                  "Count": 998
               },
               {
                  "_id": {
                        "dayOfWeek": 4,
                        "Event Code": "Sales"
                  },
                  "Count": 998
               },
               {
                  "_id": {
                        "dayOfWeek": 4,
                        "Event Code": "sent"
                  },
                  "Count": 998
               },
               {
                  "_id": {
                        "dayOfWeek": 4,
                        "Event Code": "tag1"
                  },
                  "Count": 1996
               },
               {
                  "_id": {
                        "dayOfWeek": 4,
                        "Event Code": "tag11"
                  },
                  "Count": 1996
               },
               {
                  "_id": {
                        "dayOfWeek": 4,
                        "Event Code": "tag2"
                  },
                  "Count": 1996
               },
               {
                  "_id": {
                        "dayOfWeek": 4,
                        "Event Code": "tag8"
                  },
                  "Count": 998
               },
               {
                  "_id": {
                        "dayOfWeek": 4,
                        "Event Code": "tag9"
                  },
                  "Count": 1996
               },
               {
                  "_id": {
                        "dayOfWeek": 7,
                        "Event Code": "tag12"
                  },
                  "Count": 2000
               }
            ];

            monthlyData = [
               {
                  "_id": {
                     "year": 2023,
                     "month": 9,
                     "Event Code": "30560"
                  },
                  "Count": 2
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 9,
                     "Event Code": "30561"
                  },
                  "Count": 2
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 10,
                     "Event Code": "30560"
                  },
                  "Count": 74
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 10,
                     "Event Code": "30561"
                  },
                  "Count": 62
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 10,
                     "Event Code": "60501"
                  },
                  "Count": 5
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 11,
                     "Event Code": "30560"
                  },
                  "Count": 107
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 11,
                     "Event Code": "30561"
                  },
                  "Count": 95
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 11,
                     "Event Code": "60501"
                  },
                  "Count": 4
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 12,
                     "Event Code": "30560"
                  },
                  "Count": 118
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 12,
                     "Event Code": "30561"
                  },
                  "Count": 96
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 12,
                     "Event Code": "60501"
                  },
                  "Count": 8
               },
               {
                  "_id": {
                     "year": 2023,
                     "month": 12,
                     "Event Code": "60502"
                  },
                  "Count": 4
               },
               {
                  "_id": {
                     "year": 2024,
                     "month": 1,
                     "Event Code": "30560"
                  },
                  "Count": 94
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 1,
                        "Event Code": "30561"
                     },
                     "Count": 83
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 1,
                        "Event Code": "60501"
                     },
                     "Count": 8
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 2,
                        "Event Code": "30560"
                     },
                     "Count": 265
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 2,
                        "Event Code": "30561"
                     },
                     "Count": 235
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 2,
                        "Event Code": "60501"
                     },
                     "Count": 44
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 2,
                        "Event Code": "60502"
                     },
                     "Count": 4
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 3,
                        "Event Code": "30560"
                     },
                     "Count": 2
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 3,
                        "Event Code": "30561"
                     },
                     "Count": 1
               },
               {
                     "_id": {
                        "year": 2024,
                        "month": 3,
                        "Event Code": "60501"
                     },
                     "Count": 1
               }
            ];
         }

         this.weeklyChartData = this.prepareContactProfileChartData(weeklyData, WEEKLY_CHART_FILTER);

         const processedMonthlyData = this.processContactProfileChartData(monthlyData);
         this.monthlyChartData = this.prepareContactProfileChartData(processedMonthlyData, MONTHLY_CHART_FILTER);
         //console.warn('monthlyChartData=' + JSON.stringify(this.monthlyChartData));

         this.cpKpisData = [];
         for (var i=0; i<this.chart.paginaTableSettings?.kpis?.length; i++) {
            const kpi = this.chart.paginaTableSettings.kpis[i];
            let operatorDescHolder, groupIdValHolder;

            if (kpi.operator === 'count') {
               operatorDescHolder = "Count";
               groupIdValHolder = {$sum: 1};
            } else {
               const operand = '$events.event_data.' + kpi.event_data;
               switch (kpi.operator) {
                  case 'avg':
                     operatorDescHolder = "Average";
                     groupIdValHolder = {$avg: operand};
                     break;
                  case 'max':
                     operatorDescHolder = "Maximum";
                     groupIdValHolder = {$max: operand};
                     break;
                  case 'min':
                     operatorDescHolder = "Minimum";
                     groupIdValHolder = {$min: operand};
                     break;
                  case 'sum':
                     operatorDescHolder = "Total";
                     groupIdValHolder = {$sum: operand};
                     break;
                  default:
                     operatorDescHolder = "Unknown";
                     groupIdValHolder = {};
                     groupIdValHolder['$' + kpi.operator] = operand;
                     break;
               }
            }

            let kpiFilter = JSON.parse(JSON.stringify(KPI_FILTER)
               .replace ('##idHolder##', item._id)
               .replace (/##eventCodeHolder##/g, kpi.groupBy)
            );

            const $group = kpiFilter.standard.find(f => f.$group).$group;
            $group[operatorDescHolder] = groupIdValHolder;

            //alert(JSON.stringify(kpiFilter))

            let kpiData;
            if (this.isActualEndpoint)
               kpiData = await this.btHelpers.getData(
                  this.stdFieldValues['importId'][0].value,
                  kpiFilter,
                  undefined,
                  undefined,
                  false
               );
            else {
               if (i === 0)
                  kpiData = [
                     {
                        "_id":{"Event Code":kpi.groupBy},
                        "Average":2478.33333333333334
                     }
                  ];
               else
                  kpiData = [];
            }

            this.cpKpisData.push(kpiData.length ? kpiData[0] : {});
         }         
      },

      processContactProfileChartData(dataIn) {
         const dataOut = [];
         if (!dataIn?.length) return dataOut;
         
         const idKeys = Object.keys(dataIn[0]._id);
         const otherKey = Object.keys(dataIn[0])[1];
         if (idKeys.length === 3) {
            const yearIndex = idKeys.findIndex(k => k === 'year');
            if (yearIndex === 0) {
               dataIn.forEach(d => {
                  const id = { "year-month": d._id.year + '-' + d._id.month };
                  id[idKeys[2]] = d._id[idKeys[2]];
                  const row = { _id: id };
                  row[otherKey] = d[otherKey];
                  dataOut.push(row);
               });
            } else if (yearIndex === 1) {
               dataIn.forEach(d => {
                  const id = {};
                  id[idKeys[0]] = d._id[idKeys[0]];
                  id["year-month"] = d._id.year + '-' + d._id.month;
                  const row = { _id: id };
                  row[otherKey] = d[otherKey];
                  dataOut.push(row);
               });
            } else {
               const monthIndex = idKeys.findIndex(k => k === 'month');
               if (monthIndex === 0) {
                  dataIn.forEach(d => {
                     const id = { "month-dayOfMonth": d._id.month + '-' + d._id.dayOfMonth };
                     id[idKeys[2]] = d._id[idKeys[2]];
                     const row = { _id: id };
                     row[otherKey] = d[otherKey];
                     dataOut.push(row);
                  });
               } else if (monthIndex === 1) {
                  dataIn.forEach(d => {
                     const id = {};
                     id[idKeys[0]] = d._id[idKeys[0]];
                     id["month-dayOfMonth"] = d._id.month + '-' + d._id.dayOfMonth; 
                     const row = { _id: id };
                     row[otherKey] = d[otherKey];
                     dataOut.push(row);
                  });
               }
            }
         }

         return dataOut;
      },

      prepareContactProfileChartData(dataIn, filter) {
         let data;
         if (this.chart?.paginaTableSettings?.events?.length)
            data = dataIn.filter(d => this.chart.paginaTableSettings.events.includes(d._id['Event Code']));
         else
            data = dataIn;

         //alert(JSON.stringify(this.chart?.paginaTableSettings?.events))
         //alert(JSON.stringify(dataIn))
         //alert(JSON.stringify(data))

         this.inject2ndDimension(data);

         // const $group = filter.standard.find(f => f.$group);
         // if ($group) {
         //    await this.setEventDataNames($group.$group._id, data);
         // }

         const idKeys = [];
         Object.keys(data[0]._id).forEach(key => {
            if (key != 'isRootInsert' && key != 'elm')
               idKeys.push(key);
         });

         const dataKey = Object.keys(data[0])[1];
         let newIdKey = filter.standard.find(f => f.$group).$group._id[idKeys[0]];

         if (typeof newIdKey === 'object')
            newIdKey = newIdKey['$' + idKeys[0]];

         newIdKey = newIdKey ? newIdKey.replace('$events.', '').replace('$', '') : '';

         let newIdField = this.fields.find(f => f.value === newIdKey);
         if (!newIdField)
            newIdField = this.behFieldsForGroupby.find(f => f.value === newIdKey);

         let idFieldPostfix = ' / ';
         let valueNames;
         let convertToString = false;
         switch (idKeys[0]) {
            case 'year':
               idFieldPostfix += 'Yearly';
               convertToString = true;
               break;
            case 'month':
               idFieldPostfix += 'Monthly';
               valueNames = MONTHS;
               break;
            case 'dayOfMonth':
               idFieldPostfix += 'Daily';
               convertToString = true;
               break;
            case 'hour':
               idFieldPostfix += 'Hourly';
               convertToString = true;
               break;
            case 'dayOfWeek':
               idFieldPostfix += 'Day of the Week';
               valueNames = DAYS;
               break;
            default:
               idFieldPostfix = '';
               break;
         }

         const chartData = [];
         if (idKeys.length === 1) {
            if (idFieldPostfix)
               chartData.push([newIdField.text + idFieldPostfix].concat(dataKey));
            else
               chartData.push(idKeys.concat(dataKey));

            data.forEach(d => {
               let chartDataItem;
               if (valueNames)
                  chartDataItem = valueNames[[d._id[idKeys[0]]]];
               else
                  chartDataItem = d._id[idKeys[0]];

               chartData.push([convertToString ? chartDataItem + '' : chartDataItem, d[dataKey]]);
            });
         } else if (idKeys.length === 2) {
            const uniqueItems1 = [...new Set( data.map(obj => obj._id[idKeys[0]])) ];
            const uniqueItems2 = [...new Set( data.map(obj => obj._id[idKeys[1]])) ];
            chartData.push([idFieldPostfix ? newIdField.text + idFieldPostfix : idKeys[0], ...uniqueItems2]);
            uniqueItems1.forEach(item1 => {
               const values = [];
               uniqueItems2.forEach(item2 => {
                  const match = data.find(d => d._id[idKeys[0]]===item1 && d._id[idKeys[1]]===item2);
                  values.push(match ? match[dataKey] : 0);
               });

               const chartDataItem = valueNames ? valueNames[item1] : item1;
               chartData.push([convertToString ? chartDataItem + '' : chartDataItem, ...values]);
            });
         }

         //making the 1st row and the 1st column of other rows, string.
         for (let index = 0; index < chartData.length; index++) {
            if(index === 0) {
               for (let index_i = 0; index_i < chartData[index].length; index_i++) {
                  chartData[0][index_i] = chartData[0][index_i].toString();
               }
            } else {
               chartData[index][0] = chartData[index][0] === null ? '' : chartData[index][0].toString();
            }
         }

         if (this.chartsSettings && this.chartsSettings.labels) {
            //TODO: Use the following if statement: if (chartData[0][0] === 'Event Code'). Then you need to bring the 2nd for up.
            let labelsChanged = false;
            for (let i = 1; i < chartData[0].length; i++) {
               const lbl = this.chartsSettings.labels.find(l => l.id == chartData[0][i]);
               if (lbl) {
                  chartData[0][i] = lbl.label;
                  labelsChanged = true;
               }
            }

            if (!labelsChanged) {
               for (let i = 1; i < chartData.length; i++) {
                  const lbl = this.chartsSettings.labels.find(l => l.id == chartData[i][0]);
                  if (lbl)
                     chartData[i][0] = lbl.label;
               }
            }
         }

         this.log('in prepareContactProfileChartData(): chartData=' + JSON.stringify(this.chartData));
         return chartData;
      },

      //not in use
      // // setInitialFilter() {
      // //    this.initialFilter = {};
      // //    if (this.isCalculatedTable) {
      // //       const _id = this.chartConfig.filter.standard.find(item => item.$group)?.$group?._id;
      // //       if (_id)
      // //          this.initialFilter.groupby = Object.keys(_id)[0];
      // //    }

      // //    alert('in setInitialFilter(): this.initialFilter=' + JSON.stringify(this.initialFilter));
      // // }
   },

   created() {
      // _alert(`in created(): preselectedFields=${JSON.stringify(this.preselectedFields)}`);
      // this.eventDataFields = getEventDataFields();
      this.behFields = getAllBehavioralFields();
      this.behFieldsForGroupby = this.behFields.filter(f => f.value != 'ip' && f.value != 'pdid');
      if (this.hasPaginaTable) {
         this.chartTypes.push(
            { text: 'Table with Pagination', value: 'PaginaTable' },
            { text: 'Table with Calculated Columns', value: 'CalculatedTable' },
            { text: 'KPI', value: 'KPI' },
            { text: 'Dropdown', value: 'Dropdown' },
            { text: 'Date Picker', value: 'DatePicker' }
         );
      }
   },

   mounted() {
      // alert(JSON.stringify(this.value));
      // _alert('in BtChart.mounted(): stdFieldValues=' + JSON.stringify(this.stdFieldValues));
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
::v-deep .v-text-field__details {
   margin-bottom: 0px;
}
.v-input__slot {
   margin: 0;
}
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}
/* .v-list-item__content {
   padding: 0px;
} */
</style>
